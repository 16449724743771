<template>
  <div class="upload_wrapper">
    <!-- DESCRIPTION -->
    <div class="description">
      <h4>Download File</h4>
      <p>
        files in the format of .xslx .xlsm .xlsb, are permitted for submittion
        and upload. Other file format apart from these highlighted are not
        permitted
      </p>
      <p>file size: <span class="fw-bolder">1KB</span></p>
    </div>
    <!-- 3RD SECTION -->
    <div class="d-flex align-item-center flex-column justify-content-center">
      <img
        class="d-flex align-self-center mb-3"
        src="@/assets/img/downloadExcel.png"
        alt="download icon"
        width="68.2"
      />
      <div class="d-flex justify-content-center mb-3">
        <button
          class="btn btn-primary py-2 px-3 rounded-0"
          style="background-color: #2e5d5c"
          @click.prevent="handleDownload"
        >
          Download File
        </button>
      </div>
      <p class="text-center">Need help? First time users can</p>
      <a class="text-center" href="#" @click.prevent="handleDownload"
        >Download File Template</a
      >
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'DownloadComponent',
  methods: {
    handleDownload () {
      const url = '@/assets/docs/NGF_Data_Upload.xlsx'
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function (event) {
        const blob = xhr.response
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'NGF_Data_Upload.xlsx'
        link.click()
      }
      xhr.send()
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  text-decoration: none;
}
</style>
