<template>
  <div>
    <!--*** Users Profile ***-->
    <div class="container-fluid">
      <Profile :searchType="''" />
    </div>

    <pre>{{ indicatorsx }}</pre>

    <div class="container-fluid px-0">
      <div class="bg-info">
        <div class="row mx-md-5 mx-2 pt-1">
          <div class="col">
            <selectInput
              v-model="programArea"
              label="Program area"
              :options="programAreas"
            />
          </div>

          <div class="col">
            <selectInput
              v-model="indicator"
              label="indicator(s)"
              :options="indicators"
            />
          </div>

          <div class="col">
            <selectInput
              v-model="dataSource"
              disabled
              label="data Source"
              :options="dataSources"
            />
          </div>

          <div class="col">
            <selectInput v-model="year" label="year" :options="yearOptions" />
          </div>
          <!--           <div class="col" v-if="type === 'month'">
            <selectInput v-model="month" label="month" :options="availableMonths" />
          </div> -->
        </div>
      </div>
    </div>

    <div class="container-fluid px-4 mb-2">
      <GenericTooltip />
    </div>

    <div class="container-fluid mb-3">
      <div class="row mx-lg-5 mx-md-3 shadow">
        <!--===== Map Data Representation =====-->
        <div class="col-12 px-0">
          <div class="card-header bg-secondary text-white">
            <h5 class="h5">{{ indicatorName }}</h5>
          </div>
          <div class="card-body">
            <highcharts :options="optionsObj"></highcharts>
            <NoData />
          </div>
        </div>
        <Loader />
      </div>
    </div>

    <!--===== Bottom Navigation =====-->
    <BottomNav />
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import { uniq } from 'lodash'
import GenericTooltip from '@/components/generic/GenericTooltip.vue'
import selectInput from '@/components/SelectInput.vue'
import Profile from '@/components/Profile.vue'
import BottomNav from '@/components/BottomNav.vue'
import BarChartOption from '@/assets/js/bar'
import Loader from '@/modules/dashboard/components/Loader.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'NationalComparison',
  components: {
    GenericTooltip,
    selectInput,
    Profile,
    Loader,
    BottomNav,
    NoData
  },
  data () {
    return {
      optionsObj: BarChartOption,
      dataAvailable: true,
      dataObject: [],
      type: '',
      mapHeader: '',
      Loading: true,
      programAreas: [],
      indicators: [],
      dataSources: [],
      yearOptions: [],
      programArea: '',
      indicator: '',
      dataSource: '',
      year: '',
      selectedYear: new Date().getFullYear() - 1,
      loading: true
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getYearDropdown', 'getProgramArea', 'getDefault']),
    indicatorName () {
      return this.indicators.find(indicator => indicator.value === this.indicator)?.text
    },
    indicatorTarget () {
      return this.indicators.find(indicator => indicator.value === this.indicator)?.target
    }
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_YEAR_DROPDOWN', 'GET_INDICATOR_BY_ID', 'GET_DATASOURCE_BY_ID', 'SET_UP_NATIONAL_COMPARISON']),
    ...mapActions(['SET_NO_DATA']),
    // =============== Set the Indicator Dropdown by ProgramArea
    async filterIndicatorByProgramArea () {
      const indicatorOption = []
      // ==== STEP 0: Get the object by programArea from the config store
      // eslint-disable-next-line array-callback-return
      const indicatorData = this.getDefault.filter((el) => {
        if (el.programArea === this.programArea) {
          return el
        }
      })[0]
      // ==== STEP 1: Get the list of indicators from the object
      const indicatorsId = indicatorData.indicator.map((el) => {
        return el.split('-')[0]
      })
      // ==== STEP 2: Get the list of indicators name from the object
      await Promise.all(indicatorsId.map((el) => {
        return this.GET_INDICATOR_BY_ID(el)
      })).then((res) => {
        res.forEach((el) => {
          indicatorOption.push({
            value: el.id,
            text: el.full_name,
            target: el.national_target
          })
        })
      }).catch((err) => {
        console.log(err)
      })
      // ==== STEP 3: Populate the dropdown and set the default value
      this.indicators = indicatorOption
      this.indicator = indicatorOption[0].value
    },
    // =============== Set the Data Source Dropdown by ProgramArea
    async filterDsByProgramArea () {
      const dataSourceOption = []
      // ==== STEP 0: Get the object by programArea from the config store
      // eslint-disable-next-line array-callback-return
      const dataSourceData = this.getDefault.filter((el) => {
        if (el.programArea === this.programArea) {
          return el
        }
      })[0]
      // ==== STEP 1: Get the list of datasource from the object
      const dataSourcesId = dataSourceData.indicator.map((el) => {
        return el.split('-')[1]
      })
      const uniqDs = uniq(dataSourcesId)
      // ==== STEP 2: Get the list of datasource name from the object
      await Promise.all(uniqDs.map((el) => {
        return this.GET_DATASOURCE_BY_ID(el)
      })).then((res) => {
        res.forEach((el) => {
          dataSourceOption.push({
            value: el.id,
            text: el.datasource
          })
        })
      }).catch((err) => {
        console.log(err)
      })
      // ==== STEP 3: Populate the dropdown and set the default value
      this.dataSources = dataSourceOption
      this.dataSource = dataSourceOption[0].value
    },
    // ==== get data function
    async getDashboardData () {
      const nationalVal = await this.SET_UP_NATIONAL_COMPARISON({
        indicator: this.indicator,
        dataSource: this.dataSource,
        period: this.year
      })
      this.optionsObj.series = {
        name: 'National',
        data: nationalVal
      }
      this.optionsObj.yAxis = {
        title: {
          text: 'Value'
        },
        plotLines: [{
          color: '#FF0000',
          width: 2,
          value: this.indicatorTarget,
          label: {
            text: 'Target line',
            align: 'right',
            x: -10,
            y: -5,
            style: {
              color: '#333333',
              fontWeight: 'normal'
            }
          }
        }]
      }

      nationalVal?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
    }
  },
  watch: {
    async programArea (newValue) {
      this.filterIndicatorByProgramArea()
      this.filterDsByProgramArea()
      if (this.indicator !== '' && this.dataSource !== '') {
        this.loading = true
        await this.getDashboardData()
        this.loading = false
      }
    },
    async indicator () {
      this.loading = true
      await this.getDashboardData()
      this.loading = false
    },
    async year () {
      if (this.indicator !== '' && this.dataSource !== '') {
        this.loading = true
        await this.getDashboardData()
        this.loading = false
      }
    }
  },
  async mounted () {
    // ====== Set Year Dropdown ======
    await this.SET_YEAR_DROPDOWN()
    this.yearOptions = await this.getYearDropdown
    // ====== Set ProgramArea Dropdown ======
    this.programAreas = await this.getProgramArea
    this.programArea = await this.getProgramArea[0]
    // ======================
    await this.filterIndicatorByProgramArea()
    await this.filterDsByProgramArea()
    // ======================
    if (this.indicator !== '' && this.dataSource !== '') {
      await this.getDashboardData()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
div.no_data {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
div.loading_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 500px;
  p {
    text-align: center;
  }
}
div.card-body {
  position: relative;
}
</style>
