<template>
  <div class="card">
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="New"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedProducts || !selectedProducts.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

        <!-- :filters="filters" -->
      <DataTable
        ref="dt"
        :value="NgfData"
        :selection.sync="selectedProducts"
        dataKey="id"
        :paginator="true"
        :rows="10"
        paginatorTemplate="FirstPageLink PrevPageLink
        PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        responsiveLayout="scroll"
      >
        <template #header>
          <div
            class="
              table-header
              flex flex-column
              md:flex-row md:justify-content-between
            "
          >
            <h5 class="mb-2 md:m-0 md:align-self-center">Manage Data</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Search..."
              />
            </span>
          </div>
        </template>

        <!-- <Column
          selectionMode="multiple"
          :styless="{ width: '3rem' }"
          :exportable="false"
        ></Column> -->
        <Column
          field="period"
          header="Year"
          :sortable="true"
          :styles="{ 'min-width': '2rem' }"
        ></Column>
        <Column
          field="organization"
          header="Organization"
          :sortable="true"
          :styles="{ 'min-width': '6rem' }"
        ></Column>
        <!-- <Column header="Image">
          <template #body="slotProps">
            <img
              :src="'demo/images/product/' + slotProps.data.image"
              :alt="slotProps.data.image"
              class="product-image"
            />
          </template>
        </Column> -->
        <!-- <Column
          field="price"
          header="Price"
          :sortable="true"
          :styles="{ 'min-width': '8rem' }"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.price) }}
          </template>
        </Column> -->
        <Column
          field="indicator"
          header="Indicators"
          :sortable="true"
          :styles="{ 'min-width': '10rem' }"
        ></Column>
            <Column
          field="value"
          header="Value"
          :sortable="true"
          :styles="{ 'min-width': '10rem' }"
        ></Column>
            <Column
          field="program_area"
          header="Program Area"
          :sortable="true"
          :styles="{ 'min-width': '10rem' }"
        ></Column>
        <!-- <Column
          field="rating"
          header="Reviews"
          :sortable="true"
          :styles="{ 'min-width': '12rem' }"
        >
          <template #body="slotProps">
            <Rating
              :value="slotProps.data.rating"
              :readonly="true"
              :cancel="false"
            />
          </template>
        </Column> -->
        <!-- <Column
          field="inventoryStatus"
          header="Status"
          :sortable="true"
          :styles="{ 'min-width': '12rem' }"
        >
          <template #body="slotProps">
            <span
              :class="
                'product-badge status-' +
                (slotProps.data.inventoryStatus
                  ? slotProps.data.inventoryStatus.toLowerCase()
                  : '')
              "
              >{{ slotProps.data.inventoryStatus }}</span
            >
          </template>
        </Column>-->
        <Column :exportable="false" :styles="{ 'min-width': '8rem' }">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-button-raised mr-2"
              @click="editProduct(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-raised"
              @click="confirmDeleteProduct(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      :visible.sync="dataDialog"
      :style="{ width: '50%' }"
      header="Data Details"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="period">Period</label>
        <InputText
          id="period"
          v-model.number="product.period"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.period }"
        />
        <small class="p-invalid" v-if="submitted && !product.period"
          >Period is required.</small
        >
      </div>
      <div class="field">
        <label for="name">Organization</label>
        <InputText
          id="organization"
          v-model.trim="product.organization"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.organization }"
        />
        <small class="p-invalid" v-if="submitted && !product.organization"
          >Organization is required.</small
        >
      </div>
      <div class="field">
        <label for="indicator">Indicator</label>
        <InputText
          id="indicator"
          v-model.trim="product.indicator"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.indicator }"
        />
        <small class="p-invalid" v-if="submitted && !product.indicator"
          >Indicator is required.</small
        >
      </div>
      <div class="field">
        <label for="value">Value</label>
        <InputText
          id="value"
          v-model.number="product.value"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.value }"
        />
        <small class="p-invalid" v-if="submitted && !product.value"
          >Value is required.</small
        >
      </div>
          <div class="field">
        <label for="program_area">Program Area</label>
        <InputText
          id="program_area"
          v-model.trim="product.program_area"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.program_area }"
        />
        <small class="p-invalid" v-if="submitted && !product.program_area"
          >Program Area is required.</small
        >
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProduct"
        />
      </template>
    </Dialog>

     <Dialog
      :visible.sync="deletedataDialog"
      :styles="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product"
          >Are you sure you want to delete <b>{{ product.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deletedataDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="deleteProductsDialog"
      :styles="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product"
          >Are you sure you want to delete the selected products?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="js">
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
// import InputNumber from 'primevue/inputnumber';
import Toolbar from 'primevue/toolbar'
import FileUpload from 'primevue/fileupload'
import { FilterMatchMode } from 'primevue/api'
// import dataValue from '../data/data.json';
import { mapGetters } from 'vuex'

export default {
  name: 'NGFTable',
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    // InputNumber,
    Toolbar,
    FileUpload
  },
  computed: {
    ...mapGetters(['getUserDetails']),
    stateName () {
      return this.getUserDetails.state
    }
  },
  data () {
    return {
      NgfData: [
        {
          period: 2020, organization: 'Oyo', indicator: 'Under 5 Mortality Rate', value: 0.0, program_area: 'HIV/AIDS'
        }
      ],
      product: {
        period: '',
        organization: '',
        indicator: '',
        value: '',
        program_area: ''
      },
      products: null,
      dataDialog: false,
      deletedataDialog: false,
      deleteProductsDialog: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
      statuses: [
        { label: 'INSTOCK', value: 'instock' },
        { label: 'LOWSTOCK', value: 'lowstock' },
        { label: 'OUTOFSTOCK', value: 'outofstock' }
      ]
    }
  },
  methods: {
    formatCurrency (value) {
      if (value) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      }
      return false
    },
    openNew () {
      this.product = {}
      this.submitted = false
      this.dataDialog = true
    },
    hideDialog () {
      this.dataDialog = false
      this.submitted = false
    },
    saveProduct () {
      this.submitted = true

      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus
          this.products[this.findIndexById(this.product.id)] = this.product
          this.$toast.add({
            severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000
          })
        } else {
          this.product.id = this.createId()
          this.product.code = this.createId()
          this.product.image = 'product-placeholder.svg'
          this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value : 'INSTOCK'
          this.products.push(this.product)
          this.$toast.add({
            severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000
          })
        }

        this.dataDialog = false
        this.product = {}
      }
    },
    editProduct (product) {
      this.product = { ...product }
      this.dataDialog = true
    },
    confirmDeleteProduct (product) {
      this.product = product
      this.deletedataDialog = true
    },
    deleteProduct () {
      this.products = this.products.filter((val) => val.id !== this.product.id)
      this.deletedataDialog = false
      this.product = {}
      this.$toast.add({
        severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000
      })
    },
    findIndexById (id) {
      let index = -1
      for (let i = 0; i < this.products.length; i + 1) {
        if (this.products[i].id === id) {
          index = i
          break
        }
      }

      return index
    },
    createId () {
      let id = ''
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return id
    },
    exportCSV () {
      this.$refs.dt.exportCSV()
    },
    confirmDeleteSelected () {
      this.deleteProductsDialog = true
    },
    deleteSelectedProducts () {
      this.products = this.products.filter((val) => !this.selectedProducts.includes(val))
      this.deleteProductsDialog = false
      this.selectedProducts = null
      this.$toast.add({
        severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000
      })
    },
    initFilters () {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    }
  },
  created () {
    // this.data = dataValue.data;
    this.initFilters()
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep .p-toolbar {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
