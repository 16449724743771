const state = {
  data: [],
  datas: [],
  singledata: [],
  indicatorOptions: [],
  classification: [],
  governors: [],
  singleGovernor: {}
}

export default state
