<template>
  <div class="card w-100">
    <div class="card-body py-0">
      <div
        class="d-flex my-3"
        style="display: flex; justify-content: space-between"
      >
        <img
          rel="preload"
          :src="require(`@/assets/programAreaIcons/${data?.title}.svg`)"
          width="35"
          class="ml-2 img-fluid"
        />
        <h5
          class="ml-2 mt-3 custom-font-size font-weight-bold"
          style="margin-right: auto"
        >
          {{ data.title || "-" }}
        </h5>
        <h6
          v-if="indicator === 'Institution'"
          class="mt-3 ml-5 mr-4 custom-font-size font-weight-bold"
          style="margin-left: auto"
        >
          Total
        </h6>
      </div>
      <div
        class="m-0 d-flex w-100 justify-content-lg-between justify-content-md-around"
        v-for="(item, i) in dataArr"
        :key="i"
      >
        <p class="ml-5 mt-lg-1">{{ item.indicator }}</p>
        <p class="ml-5 mr-4 font-weight-bold">
          {{ formatPopulation(item?.value.replace(/\.0$/, "")) || "-" }}
        </p>
      </div>
      <div
        v-if="indicator === 'Demography'"
        class="state__box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: <span>NPC</span></p>
      </div>
      <div
        v-else
        class="state__box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: <span>SMoH</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatToMoney } from '@/utils/index'

export default {
  name: 'StateProfileCard',
  props: {
    data: {
      // type: ,
      default: () => {}
    },
    indicator: {
      type: String
    }
  },
  computed: {
    dataArr () {
      return this.data.data
    }
  },
  methods: {
    formatPopulation (value) {
      if (value !== '' && value !== undefined) {
        return formatToMoney(value)
      }
      return '-'
    }
  }
}
</script>

<style scoped>
.state__box {
  color: #fff;
  background-color: #2e5d5c;
  /* border-color: #007d53; */
  width: auto;
  margin: 1px 22px 10px 45px;
  float: left;
  padding: 0.2rem;
}
.state__box p {
  font-size: 0.65rem;
  margin-bottom: 1px;
  margin-top: -2px;
}
.state__box span {
  font-size: 0.8rem;
}
.custom-font-size {
  font-size: 1rem !important;
  /* color: #007d53; */
  color: #2e5d5c;
}
.custom-border {
  border: #b2b2b2 0.1rem solid;
}
.custom-boder-top {
  border-top: 0.1rem solid #b2b2b2 !important;
}
</style>
