<template>
  <b-modal id="audit" size="lg" centered title="">
    <div class="container p-3 d-flex justify-content-between">
      <h5 class="h5 d-flex align-self-center">Audit Trail Logs</h5>
      <select
        class="form-select ms-5"
        name="filter"
        id="filter"
        v-model="filter"
      >
        <option selected value="null" disabled>Sort By</option>
        <option value="1">One</option>
      </select>
    </div>
    <b-table striped hover :items="items"></b-table>
    <p v-b-modal.contact class="mt-5">Contact Us</p>
  </b-modal>
</template>

<script>
export default {
  name: 'AuditLog',
  data () {
    return {
      filter: null,
      items: [
        { SN: 1, Name: 'Dickerson', AccessLevel: 'Macdonald', State: 'Abia', Date: '22-04-2022', Time: '17:54' }
      ]
    }
  }
}
</script>

<style>
table-row {
  border: none !important;
}
tbody > tr {
  border: 1px block !important;
  margin: 2px !important;
}
.modal-footer {
  display: none !important;
}
.modal-header {
  border: none !important;
}
</style>
