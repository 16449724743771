<template>
  <div class="container-fluid w-100">
        <div class="d-flex align-items-center top justify-content-between p-4 mx-auto">
          <h2 class="">Profile Update</h2>
          <form class="w-50 ms-5">
            <div>
              <div class="mb-3">
                <div class="form-group w-100">
                  <label>Select State tto update profile</label>
                  <select
                    class="form-control"
                    id="selectedGovernor"
                    v-model="selectedGovernor"
                    aria-placeholder="Select State"
                  >
                    <option value="" disabled>--Select State--</option>
                    <option v-for="el in getGovernors" :key="el.id">
                      {{el.state}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="container p-3">
        <div class="row mx-auto" v-if="Object.keys(getSingleGovernor).length !== 0">
          <div class="
              user
              col-lg-8
              col-md-12
              shadow
              m-auto
              p-4
              h-100
              mr-5
              mb-6
            "
          >
            <h4 class="mb-4">Details</h4>
            <div class="user-data">
              <span class="user-info mr-4">Email:</span>
              <span class="user-detail">{{getSingleGovernor?.user?.email}}</span>
            </div>
            <div class="user-data">
              <span class="user-info mr-4">Title:</span>
              <span class="user-detail">{{getSingleGovernor.type}}</span>
            </div>
            <div class="user-data">
              <span class="user-info mr-4">State:</span>
              <span class="user-detail">{{getSingleGovernor.state}}</span>
            </div>
            <div class="user-data">
              <span class="user-info mr-4">Username:</span>
              <span class="user-detail">{{getSingleGovernor.user.username}}</span>
            </div>
          </div>
          <div class="user col-lg-8 col-md-12 m-auto shadow p-4 w-100">
            <h4 class="mb-4">Update Profile</h4>
            <form @submit.prevent="updateProfile">
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  v-model="username"
                />
              </div>
              <button type="submit" class="btn btn-primary">Update</button>
            </form>
          </div>
        </div>
        </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DetailsUpdate',
  data () {
    return {
      selectedGovernor: '',
      email: '',
      username: ''
    }
  },
  watch: {
    async selectedGovernor (newval) {
      console.log(newval)
      const user = this.getGovernors.find(el => el.state === newval)
      try {
        await this.GET_SINGLE_GOVERNOR(user?.id)
      } catch (err) {
        console.log(err)
      }
    }
  },
  methods: {
    ...mapActions('ADMIN_STORE', ['GET_GOVERNORS', 'GET_SINGLE_GOVERNOR', 'UPDATE_SINGLE_GOVERNOR']),
    async updateProfile () {
      if (this.getSingleGovernor !== undefined) {
        const { user, ...otherDetails } = this.getSingleGovernor
        console.log(otherDetails, 'otherdets')
        const User = {
          username: this.username,
          email: this.email
        }
        await this.UPDATE_SINGLE_GOVERNOR({ id: this.getSingleGovernor.id, govDetail: { user: User, ...otherDetails } })
      }
    }
  },
  computed: {
    ...mapGetters('ADMIN_STORE', ['getGovernors', 'getSingleGovernor'])
  },
  async mounted () {
    await this.GET_GOVERNORS()
  }
}
</script>

<style scoped>
 .container-fluid{
  background-color: white;
  min-height: calc(100vh - 70px)
 }
.user-inf0{
 font-size: 20px;
 font-weight: bolder;
}
.user-detail{
  justify-self: end;
}
</style>
