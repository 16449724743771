<template>
  <div>
    <div class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          class="carousel-item bg-info py-md-5 py-3 px-4 text-white home-background"
          :data-bs-interval="interval"
          v-for="(count, index) in slides"
          :key="index"
          :class="[index === 0 ? 'active' : '']"
          :style="{
            'background-image': 'url(' + require(`@/assets/${options[index][1]}.png`) + ')'
          }"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-12 pt-md-4 pt-3">
                <h3 class="h3 font-weight-bold">
                  {{ options[index][0] }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12 pt-2 d-none d-md-block" :class="LoggedIn === true ? '' : 'my-3'">
                <router-link v-if="LoggedIn === false" to="/login">
                  <button class="btn btn-primary btn-lg">
                    Log into your account
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="controls">
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselComponent',
  data () {
    return {}
  },
  computed: {
    LoggedIn () {
      return this.$store.getters.isUser
    }
  },
  props: {
    controls: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => []
    },
    slides: {
      type: Number
    },
    interval: {
      type: Number,
      default: () => 4000
    }
  }
}
</script>

<style lang="scss" scoped>
.home-background {
  // linear-gradient(88deg, #000000D1 0%, #00000055 100%),
  background-image: url("~@/assets/2.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  // background-size: 100% 100%;
}
div.carousel-inner{
  height: 30vh;
}
</style>
