<template>
  <div class="text-center">
    <div class="btn-group" role="group" v-for="(link, index) in links" :key="index">
      <!-- CHANGE THE ORIGINAL 3TABS TO 2TABS AS REQUESTED BY THE STAKEHOLDERS -->
      <router-link :to="link.to">
        <button
          class="btn text-primary"
          :class="[
            index == 0 ? 'left-curved-button' : '',
            index == 1 ? 'right-curved-button' : '',
            currentRoute === link.path ? 'smooth active' : ''
          ]"
        >
          {{ link.label }}
        </button>
      </router-link>
    </div>
  </div>

  <!-- COMMENTED CHANGE -->
  <!-- :class="[ index == 0 ? 'left-curved-button' : '', index == 1 ? 'rounded-0' :
  '', index == 2 ? 'right-curved-button' : '', currentRoute === link.path ?
  'smooth active' : '' ]" -->
</template>

<script>
export default {
  data () {
    return {
      links: [
        {
          label: 'Zonal Comparison',
          to: '/dashboard/detailed_Indices/compare_zones',
          path: '/dashboard/detailed_Indices/compare_zones'
        },
        {
          label: 'National Comparison ',
          to: '/dashboard/detailed_Indices/compare_states',
          path: '/dashboard/detailed_Indices/compare_states'
        }
      ]
    }
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #007d53;
$info: #e7f8f8;
.left-curved-button {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}
.right-curved-button {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}
.btn {
  background-color: $info;
  font-size: 1.2rem !important;
  padding: 0.6rem 4rem !important;
  border: 2.2px solid #e7e7e7 !important;
  font-size: 1.1rem !important;
}
.btn:hover {
  background-color: $primary;
  color: white !important;
}

.active {
  background-color: $primary;
  color: white !important;
}
.smooth {
  transition: all 1s ease-in-out;
}
</style>
