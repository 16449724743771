const mutations = {
  setDataSource (state, payload) {
    state.dataSource = payload
    state.dataSourceId = payload.map((el) => el.id)
  },
  setIndicators (state, payload) {
    state.indicators = payload
    state.indicatorsId = payload.map((el) => el.id)
  },
  setYear (state, payload) {
    state.years = payload
  },
  setLocation (state, payload) {
    state.location = payload
    const locationId = payload.map((el) => el.id)[0]
    state.locationId = locationId
  },
  setLastUpdate (state, payload) {
    state.lastUpdate = payload
  },
  setData (state, payload) {
    state.data = payload
  },
  setSectionData (state, payload) {
    state.sectionData = payload
  },
  setUpKeyHealthIndices (state, payload) {
    state.keyHealthIndices = payload
  },
  setYearDropdown (state, payload) {
    state.yearDropdown = payload
  },
  setActiveTab (state, payload) {
    state.activeTab = payload
  },
  setZonalData (state, payload) {
    state.zonalData = payload
  },
  setStateData (state, payload) {
    state.stateData = payload
  },
  setDataGroup (state, payload) {
    state.dataGroup = payload
  },
  setSectionSelected (state, payload) {
    state.sectionSelected = payload
  },
  setPartnerMapping (state, payload) {
    state.partnerMapping = payload
  },
  setFlagshipProject (state, payload) {
    state.flagshipProject = payload
  }
}

export default mutations
