/* eslint-disable quotes */
/* eslint-disable max-len */
const dashboardHelp = [
  {
    title: 'What is the platform about?',
    // steps: {
    // First_Method: [
    //   'Click on the dropdown arrow next to the main indicator.',
    //   'Scroll down  to the appropriate program area where the desired indicator can be found.',
    //   'Click on the program dropdown.',
    //   'You can also type keywords of the desired indicator into the search field.',
    //   'When found, click on the desired indicator.',
    // ],
    // Second_Method: [
    //   'Go to the indicator search field at the top of the platform.',
    //   'Scroll down to the appropriate program area where the desired indicator can be found.',
    //   'Click on the program dropdown.',
    //   'You can also type keywords of the desired indicator into the search field.',
    //   'When found, click on the desired indicator.',
    // ],
    // },
    step: 'The Governors’ Dashboard provides a simple executive overview of health indices for performance monitoring. The dashboard is designed to provide accurate, brief and compelling information to governors and policy-makers, to enable them make informed decisions on any affected health care system indicators. Governors and policy-makers can access at a glance, their state\'s most recent indicator data and trend analysis in order to evaluate performance and address areas with pressing needs',
    show: false
  },
  {
    title: 'Where is the data for the platform obtained from? And how?',
    step: 'Data is obtained from the National Health Management Information System (NHMIS) and the DHIS2 instance of NGF. Data is extracted, cleaned and reviewed before it is used on the platform.',
    show: false
  },
  {
    title: 'What are the levels of data aggregation found on the platform? (i.e. is state, LGA, community/facility-level data available?',
    step: 'Zonal, State, and LGA data are available on the platform.',
    show: false
  },
  {
    title: 'What health program areas are available?',
    steps: [
      'Health Services',
      'Health Financing',
      'Mortality',
      'Human Resources',
      'Primary Health Care Under One Roof (PHCUOR)'
    ],
    show: false
  },
  {
    title: 'How often is the data on the governors dashboard platform updated? Is the data real-time?',
    step: 'No. The data is not real-time. The platform is reviewed monthly and where new data is available, the corresponding changes are made on a monthly basis.',
    show: false
  },

  {
    title: 'What checks are in place to ensure that data is accurate and up to date?',
    step: 'The data undergoes a six-sigma methodology with the aim of ensuring data accuracy on the platform on a monthly basis. Data is sourced only from the NGF and government-approved bodies.',
    show: false
  },
  {
    title: 'Whom can I contact for feedback concerning the platform?',
    step: `You can send an email to <a href="mailito=msdatfeedback@e4email.net">msdatfeedback@e4email.net</a>`,
    show: false
  },
  {
    title: 'How do I get login information?',
    step: `The platform makes it exclusively available to governors of each state. This login information has already been shared with the governors. In the case that you have issues logging in, please send an email to <a href="mailito=msdatfeedback@e4email.net">msdatfeedback@e4email.net</a> or contact the NGF help desk for further assistance.`,
    show: false
  }
]

export default dashboardHelp
