var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getSectionSelected === 'Health Training Institutions')?_c('div',[_c('b-table',{staticClass:"shadow-sm pb-1 bg-white",attrs:{"sticky-header":"30rem","bordered":"","items":_vm.items,"busy":_vm.isBusy,"outlined":""}})],1):(_vm.getSectionSelected === 'Human Resources for Health')?_c('div',[_c('b-table',{staticClass:"shadow-sm pb-1 bg-white",attrs:{"sticky-header":"30rem","bordered":"","items":_vm.items,"busy":_vm.isBusy,"outlined":""}})],1):_vm._e(),(_vm.isZonal)?_c('b-table',{staticClass:"shadow-sm pb-1 bg-white",staticStyle:{"max-height":"60rem"},attrs:{"responsive":"","sticky-header":"30rem","bordered":"","items":_vm.dataItem,"fields":_vm.fields,"busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [(_vm.states.includes(data.field.key))?_c('div',[(data.item[data.field.key])?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[(data.item[data.field.key].increase)?_c('img',{staticClass:"arrow",attrs:{"src":require('@/assets/img/arrow-up.png')}}):_vm._e(),(!data.item[data.field.key].increase)?_c('img',{staticClass:"arrow",attrs:{"src":require('@/assets/img/arrow-down.png')}}):_vm._e(),_c('div',{staticClass:"box bg-success mx-2",class:[
                data.item[data.field.key].data >
                parseFloat(data.item.target || 0)
                  ? 'bg-success'
                  : 'bg-danger',
              ]}),(data.item[data.field.key]?.data)?_c('span',{staticClass:"minimum-width"},[_vm._v(" "+_vm._s(data.item[data.field.key].data)+" ")]):_c('span',{staticClass:"minimum-width"},[_vm._v(" - ")])])]):_vm._e()]):_c('span',[_vm._v(_vm._s(data.item[data.field.key]))])]}}],null,false,3570919270)}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable2 && !_vm.isZonal),expression:"showTable2 && !isZonal"}]},[_c('b-table',{staticClass:"shadow-sm pb-1 bg-white",staticStyle:{"max-height":"60rem"},attrs:{"responsive":"","sticky-header":"30rem","bordered":"","items":_vm.dataItem,"fields":_vm.fields,"busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell()",fn:function(data){return [(_vm.states.includes(data.field.key))?_c('div',[(data.item[data.field.key])?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[(data.item[data.field.key].increase)?_c('img',{staticClass:"arrow",attrs:{"src":require('@/assets/img/arrow-up.png')}}):_vm._e(),(!data.item[data.field.key].increase)?_c('img',{staticClass:"arrow",attrs:{"src":require('@/assets/img/arrow-down.png')}}):_vm._e(),_c('div',{staticClass:"box bg-success mx-2",class:[
                  data.item[data.field.key].data >
                  parseFloat(data.item.target || 0)
                    ? 'bg-success'
                    : 'bg-danger',
                ]}),(data.item[data.field.key]?.data)?_c('span',{staticClass:"minimum-width"},[_vm._v(" "+_vm._s(data.item[data.field.key].data)+" ")]):_c('span',{staticClass:"minimum-width"},[_vm._v(" - ")])])]):_vm._e()]):_c('span',[_vm._v(_vm._s(data.item[data.field.key]))])]}},{key:"cell(indicator)",fn:function(data){return [(data.item.programAreaSeletable || data.item.programArea)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$emit('emit-row-data', data.item);
            _vm.toggleActive();}}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('img',{staticClass:"mr-4",attrs:{"src":require(`@/assets/stateProfiIeIcons/${
                  data.item.programAreaSeletable || data.item.programArea
                }.png`),"width":"40"}}),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(_vm.contentHtml(data.item)),expression:"contentHtml(data.item)",modifiers:{"hover":true,"bottom":true}}],staticClass:"d-flex flex-column"},[_c('span',{staticClass:"mt-1 font-weight-bold"},[_vm._v(" "+_vm._s(data.item.indicator.split("(").length === 2 ? data.item.indicator.split("(")[1].split(")")[0] : data.item.indicator.split("(")[2].split(")")[0])+" ")]),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(data.item.indicator.split("(").length === 2 ? data.item.indicator.split("(")[0] : data.item.indicator .split(",")[0] .split(")")[0] .concat(")"))+" ")])])])]):_c('div',{staticClass:"d-flex align-items-center",class:{ highlight: data.item.indicator === _vm.selected },staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$emit('emit-row-data', data.item);
            _vm.toggleActive(data.item.indicator);}}},[_vm._v(" "+_vm._s(data.item.indicator)),(data.item.status)?_c('span',{staticClass:"mx-1 d-none"},[_vm._v("("+_vm._s(_vm.formatValue(data.item.status.displayFactor))+")")]):_vm._e()])]}},{key:"cell(trend)",fn:function(row){return [_c('span',{staticClass:"d-flex justify-content-center"},[_c('highcharts',{attrs:{"options":row.item.trend || row.trend}})],1)]}},{key:"cell(status)",fn:function(data){return [(data.item.status)?_c('div',[_c('div',{staticClass:"h-100 d-flex"},[(_vm.getSectionSelected === 'Access and Service Utilization')?_c('div',{staticClass:"box bg-success mx-2",class:[
                data.item.status.data > parseFloat(data.item.target)
                  ? 'bg-success'
                  : 'bg-danger',
              ]}):_vm._e(),_c('div',[(data.item.status.data)?_c('span',{staticClass:"minimum-width"},[_vm._v(_vm._s(_vm.formatValue(Math.floor(data.item.status.data)))+"  "),(
                    _vm.getSectionSelected === 'Access and Service Utilization'
                  )?_c('small',[_vm._v(" (target: "+_vm._s(data.item.target || 0)+") ")]):_vm._e(),_vm._v("   "),(!data.item.status)?_c('span',[_vm._v("("+_vm._s(_vm.formatValue(data.item.status.displayFactor))+")")]):_vm._e()]):_c('span',{staticClass:"minimum-width"},[_vm._v(" - ")])])])]):_c('span',[_vm._v(_vm._s(_vm.formatValue(data.item.status(data.item.status))))])]}},{key:"cell(target)",fn:function(){return [_c('div',{staticClass:"text-center"},[(_vm.data.item.target)?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(Math.floor(_vm.data.item.target))))]):_vm._e()])]},proxy:true},{key:"cell(density)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.item.status)?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(Math.floor(data.item.status))))]):_c('span',[_vm._v(" - ")])])]}},{key:"empty",fn:function(){return [(_vm.dataItem?.length === 0)?_c('div',{staticStyle:{"height":"20rem"}},[_c('NoData')],1):_vm._e()]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }