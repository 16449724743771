/* eslint-disable camelcase */
import { uniq, uniqBy } from 'lodash'
import Request from '@/utils/Request'
import TrendLineConfig from '@/config/trendline'

const actions = {
  /**
   * @function: GET_DATA_BY_PARAMS
   * @description: Get data from API by id
   * @param: {Object} payload {indicatorId, dataSourceId, locationId, period}
   * @return: {Object} data
   */
  async GET_DATA_BY_PARAMS (context, payload) {
    const { indicator, locationId, period, dataSource } = payload
    const resp = await Request(
      `data/?location=${locationId}&period=${period}&datasource=${dataSource}&indicator=${indicator}`
    )
    return resp.results[0]?.value || 0
  },
  async GET_STATE_DATA ({ commit }, payload) {
    const { id } = payload
    const resp = await Request(`dmi/state/?state_id=${id}`)
    commit('setStateData', resp)
    // return resp.results[0]?.value || 0
  },
  async GET_PARTNER_MAP ({ commit }, payload) {
    const { id } = payload
    const resp = await Request(`dmi/partner-mapping/?state_id=${id}`)
    // const data = resp.results
    commit('setPartnerMapping', resp)
    console.log(resp, 'ndataeataw')
    // return resp.results[0]?.value || 0
  },
  async GET_FLAGSHIP_PROJECT ({ commit }, payload) {
    const { state } = payload
    // const { state } = rootState.user
    const resp = await Request(`dmi/flagship-projects/?state=${state}`)
    // const data = resp.results
    commit('setFlagshipProject', resp)
    console.log(resp, 'helloooo')
  },
  /**
   * @function: GET_DATA_BY_PARAMS_MONTH
   * @description: Get data from API by query parameters
   * @param: {Object} payload {indicatorId, dataSourceId, locationId, period, month}
   * @return: {Object} data
   */
  async GET_DATA_BY_PARAMS_MONTH (context, payload) {
    const { indicator, locationId, period, dataSource, month } = payload
    const resp = await Request(
      `data/?location=${locationId}&period=${period}&datasource=${dataSource}&indicator=${indicator}&month=${month}`
    )
    return resp.results[0]?.value || 0
  },
  /**
   * @Function GetLocation
   * @Author davebenard
   * @param {object} context
   * @param {object} payload
   * @description Function to get all location
   * @returns {array}
   */
  async GET_LOCATIONS ({ commit, rootState }) {
    const { state } = rootState.user
    const State = state.toLowerCase()
    // const stateName = State.charAt(0).toUpperCase() + State.slice(1)
    const response = await Request('location/?size=1000')
    const result = response.results.filter(
      (el) => el.name.toLowerCase() === State.toLowerCase()
    )
    commit('setLocation', result)
    return result[0].id
  },
  // GET INDICATOR BY ID
  async GET_INDICATOR_BY_ID ({ commit }, payload) {
    return await Request(`indicators/${payload}/`)
  },
  // GET LOCATION BY ID
  async GET_LOCATION_BY_ID (context) {
    const locationId = await context.dispatch('GET_LOCATIONS')
    return await Request(`location/${locationId}/`)
  },
  // GET DATASOURCE BY ID
  async GET_DATASOURCE_BY_ID ({ commit }, payload) {
    return await Request(`datasources/${payload}/`)
  },
  /**
   * @function: SET_YEAR_DROPDOWN
   * @description: function to get all the year between [current year - 1] and 2017
   * @returns: list of years in between
   */
  async SET_YEAR_DROPDOWN ({ commit }, payload) {
    // const response = await Request(`indicators/${payload}/`)
    // return response?.years
    // get the current year
    const defaultYear = 2017
    const Year = new Date().getFullYear()
    const currentYear = Year - 2
    // get the list of year from the current year to the default year
    const yearDropdown = Array.from(
      { length: currentYear - defaultYear + 1 },
      (_, i) => currentYear - i
    )
    // convert the list of year to an array of objects
    const yearDropdownObject = yearDropdown?.map((year) => {
      return {
        value: year,
        text: year.toString()
      }
    })
    commit('setYearDropdown', yearDropdownObject)
    return yearDropdown
  },
  /**
   * @Function GetIndicatorsByDataSource
   * @Author davebenard
   * @param {object} context
   * @param {object} payload
   * @description Function to get all indicators associated with a dataSource
   * @returns {array}
   */
  async GET_INDICATORS_BY_DATA_SOURCE ({ commit }, payload) {
    const indicators = []
    await Promise.all(
      payload?.map(async (id) => {
        const resp = await Request(`datasources/${id}/`)
        indicators.push(...resp.indicators)
      })
    )
    commit('setIndicators', indicators)
    return indicators
  },
  /**
   * @Function GetData
   * @Author davebenard
   * @param {object} context
   * @param {object} payload
   * @description Function to get data from the API
   * based on the payload {dataSource, indicator, location, year}
   * @returns {array}
   */
  async GET_DATA (context, payload) {
    const result = []
    await Promise.all(
      payload?.map(async (el) => {
        const resp = await Request(
          `data/?datasource=${el.split('-')[1]}&indicator=${el.split('-')[0]}`
        )
        result.push(...resp)
      })
    )
    context.commit('setData', result)
  },
  /**
   * @Function GetLastUpdate
   * @Author davebenard
   * @param {object} context
   * @description Function to get the data last update date
   * @returns {array}
   */
  async GET_LAST_UPDATE ({ commit }) {
    const response = await Request('data/?ordering=-updated_at&size=1')
    const lastUpdate = response.results[0].updated_at
    commit('setLastUpdate', lastUpdate)
  },
  /**
   * @Function GetYears
   * @Author davebenard
   * @param {number} payload
   * @description Function to get the available years by indicator
   * @returns {array}
   */
  async GET_YEARS_BY_INDICATOR (context, payload) {
    const resp = await Request(
      `indicator_years_available/?indicator=${payload}&size=200`
    )
    const years = resp.results?.map((el) => el.year)
    const uniqYears = uniq(years?.map((el) => el.split('-')[0]))
    const checkUniqYears = uniqYears.map((el) => {
      return el?.split(' ')?.length > 1 ? el?.split(' ')[1] : el?.split(' ')[0]
    })
    // sort the checkUniqYears
    const sortedYears = checkUniqYears.sort((a, b) => {
      return a - b
    })
    return sortedYears
  },
  /**
   * @Function SET_UP_STATE_PROFILE - set up the state profile
   * @Author davebenard
   * @param {object} context
   * @param {object} payload
   * @description Function to get the available data for each of the card
   * @returns {array}
   */
  async SET_UP_STATE_PROFILE (context, payload) {
    const result = []
    await context.dispatch('SET_LOADING', true, { root: true })
    // const indicators = []
    // STEP 1: get the state profile config and locationId
    const locationID = await context.dispatch('GET_LOCATIONS')
    const { stateProfile, locationId } = context.rootState.DASHBOARD_STORE
    if (locationId == null || locationId === '') {
      await context.dispatch('GET_LOCATIONS')
    }

    const { indicator } = stateProfile.find((el) => el.programArea === payload)
    // STEP 2: get the indicator from the state for each programArea
    const location = locationId || locationID
    // Get the current year
    const currentYears = await context.dispatch('SET_YEAR_DROPDOWN')
    const period = currentYears[0]
    // console.log(currentYears, 'currentYears')

    await Promise.all(
      indicator?.map(async (el) => {
        const { results } = await Request(
          `data/?datasource=${el.split('-')[1]}&indicator=${
            el.split('-')[0]
          }&location=${location}&period=${period}`
        )
        const { full_name, id } = await Request(
          `indicators/${el.split('-')[0]}/`
        )
        await result.push({
          id,
          indicator: full_name,
          value: results[0]?.value
        })
      })
    )

    // Sort the result array
    const sortedResult = await indicator
      ?.map((el) => {
        return result.find((el2) => el2.id === Number(el.split('-')[0]))
      })
      .filter((el) => el !== undefined)
    await context.dispatch('SET_LOADING', false, { root: true })
    console.log(sortedResult, 'sortedResult')
    return sortedResult
    // ===============================
  },
  /**
   * @Function setUpKeyHealthIndices - set up key health indices
   * @Author davebenard
   * @param {object} context
   * @description Function to get the available years by indicator
   * @returns {array}
   */
  async SET_UP_KH (context) {
    await context.dispatch('SET_LOADING', true, { root: true })

    const locationId = await context.dispatch('GET_LOCATIONS')
    const { ngf } = await context.state
    const result = []
    // await ngf?.forEach(async (el) => {
    // use promise to loop through the ngf array
    await Promise.all(
      ngf?.map(async (el) => {
        const year = await context.dispatch(
          'GET_YEARS_BY_INDICATOR',
          el.indicator[0].split('-')[0]
        )
        const lastYear = Number(year[year.length - 1])

        const resp1 = await Request(
          `data/?location=${locationId}&period=${lastYear}&datasource=${
            el.indicator[0].split('-')[1]
          }&indicator=${el.indicator[0].split('-')[0]}`
        )
        const data2 = await context.dispatch('GET_DATA_BY_PARAMS', {
          indicator: el.indicator[0].split('-')[0],
          locationId,
          period: lastYear - 1,
          dataSource: el.indicator[0].split('-')[1]
        })
        const data3 = await context.dispatch('GET_DATA_BY_PARAMS', {
          indicator: el.indicator[0].split('-')[0],
          locationId,
          period: lastYear - 2,
          dataSource: el.indicator[0].split('-')[1]
        })
        const data1 = (await resp1.results[0]?.value) || 0
        // const data2 = resp2.results[0]?.value || 0
        // const data3 = resp3.results[0]?.value || 0

        const indicatorName = await Request(
          `indicators/${el.indicator[0].split('-')[0]}/`
        )
        const dataSourceName = await Request(
          `datasources/${el.indicator[0].split('-')[1]}/`
        )

        const trendline = {
          ...TrendLineConfig
        }
        trendline.series = [
          {
            name: `${dataSourceName.datasource}`,
            data: [Number(data3), Number(data2), Number(data1)]
          }
        ]
        await result.push({
          programArea: el.programArea,
          indicatorId: el.indicator[0].split('-')[0],
          indicator: `${indicatorName.full_name} (${el.programArea})`,
          data: data1,
          year: Number(year[year.length - 1]) || '-',
          status: {
            data: data1,
            increase: data1 > indicatorName.national_target,
            displayFactor:
              (await resp1.length) > 0 &&
              (await resp1?.map((el2) => el2.value_type).toString())
          },
          trend: trendline,
          target: indicatorName.national_target,
          dataSource: dataSourceName.datasource
        })
      })
    )

    // sort result data to  match the order of the ngf state and return the reordered data
    const sortedResult = await ngf
      ?.map((el) => {
        return result.find(
          (el2) => el2.indicatorId === el.indicator[0].split('-')[0]
        )
      })
      .filter((el) => el !== undefined)

    await context.commit('setUpKeyHealthIndices', sortedResult)
    await context.dispatch('SET_LOADING', false, { root: true })
    return result
  },
  /**
   * @Function setUpDetailedIndices - set up detailed indices scorecard
   * @Author davebenard
   * @param {object} context
   * @description Function to get the available years by indicator
   * @returns {array}
   */
  async SET_UP_DETAILED_INDICES (context, payload) {
    await context.dispatch('SET_LOADING', true, { root: true })

    const result = []
    const { object, yearValue } = await payload
    const { indicator } = await object
    // STEP 0: set up the year dropdown
    await context.dispatch('SET_YEAR_DROPDOWN')
    // STEP 1: get the location id
    const locationId = await context.dispatch('GET_LOCATIONS')

    // STEP 2: get the years
    // ------------------------------
    if (indicator !== undefined || indicator?.length > 0) {
      await Promise.all(
        await indicator?.map(async (el) => {
          const year = await context.dispatch(
            'GET_YEARS_BY_INDICATOR',
            el.split('-')[0]
          )
          const statusData = await context.dispatch('GET_DATA_BY_PARAMS', {
            indicator: el.split('-')[0],
            locationId,
            period: Number(yearValue) || year[year.length - 1],
            dataSource: el.split('-')[1]
          })
          const indicatorData = await context.dispatch(
            'GET_INDICATOR_BY_ID',
            el.split('-')[0]
          )

          const { national_target, value_type, full_name } = indicatorData

          // STEP 3: get the data for the previous 2 years
          const data2 = await context.dispatch('GET_DATA_BY_PARAMS', {
            indicator: el.split('-')[0],
            locationId,
            period: Number(yearValue) - 1 || year[year.length - 1] - 1,
            dataSource: el.split('-')[1]
          })
          const data3 = await context.dispatch('GET_DATA_BY_PARAMS', {
            indicator: el.split('-')[0],
            locationId,
            period: Number(yearValue) - 2 || year[year.length - 1] - 2,
            dataSource: el.split('-')[1]
          })
          const trendline = {
            ...TrendLineConfig
          }
          trendline.series = [
            {
              name: `${el.split('-')[1]}`,
              data: [Number(data3), Number(data2), Number(statusData || 0)]
            }
          ]

          // STEP 4: get the data for the current year
          result.push({
            // programArea,
            indicator: full_name,
            indicatorId: el.split('-')[0],
            status: {
              data: statusData,
              increase: statusData > national_target,
              displayFactor: value_type
            },
            target: national_target,
            trend: trendline,
            datasource: el.split('-')[1],
            year: year[year.length - 1] || null
          })
        })
      )
      // STEP 5: sort the result data to  match the order of the ngf state and return the reordered data
      const sortedResult = indicator
        ?.map((el) => {
          return result.find((el2) => el2.indicatorId === el.split('-')[0])
        })
        .filter((el) => el !== undefined)
      await context.dispatch('SET_LOADING', false, { root: true })
      // return result
      return sortedResult
    }
  },
  /**
   * @Function setUpDetailedIndicesMonth - set up detailed indices scorecard
   * @Author davebenard
   * @param {object} context
   * @description Function to get the datapoints by query parameters
   * @returns {array}
   */
  async SET_UP_DETAILED_INDICES_MONTH (context, payload) {
    await context.dispatch('SET_LOADING', true, { root: true })
    const dataSource = 33 // NHMIS_MONTHLY ID

    const result = []
    const mapResult = []
    const onTarget = []
    const offTarget = []
    const emptyValue = []
    const noTarget = []

    const { object, yearValue } = await payload
    const { indicator } = await object
    // STEP 0: set up the year dropdown
    await context.dispatch('SET_YEAR_DROPDOWN')
    // STEP 1: get the location ids
    const locationId = await context.dispatch('GET_LOCATIONS')

    // STEP 2: get the years
    // ------------------------------
    await Promise.all(
      await indicator?.map(async (el) => {
        const year = await context.dispatch(
          'GET_YEARS_BY_INDICATOR',
          el.split('-')[0]
        )
        const statusData = await context.dispatch('GET_DATA_BY_PARAMS', {
          indicator: el.split('-')[0],
          locationId,
          period: yearValue,
          dataSource
        })
        const indicatorData = await context.dispatch(
          'GET_INDICATOR_BY_ID',
          el.split('-')[0]
        )

        const { national_target, value_type, full_name } = indicatorData

        // STEP 3: get the data for the previous 2 years
        const data2 = await context.dispatch('GET_DATA_BY_PARAMS', {
          indicator: el.split('-')[0],
          locationId,
          period: Number(yearValue) - 1 || year[year.length - 1] - 1,
          dataSource
        })
        const data3 = await context.dispatch('GET_DATA_BY_PARAMS', {
          indicator: el.split('-')[0],
          locationId,
          period: Number(yearValue) - 2 || year[year.length - 1] - 2,
          dataSource
        })
        const trendline = {
          ...TrendLineConfig
        }
        trendline.series = [
          {
            name: `${el.split('-')[1]}`,
            data: [Number(data3), Number(data2), Number(statusData || 0)]
          }
        ]

        // STEP 4: get the data for the current year
        result.push({
          // programArea,
          indicator: full_name,
          indicatorId: el.split('-')[0],
          status: {
            data: statusData,
            increase: statusData > national_target,
            displayFactor: value_type
          },
          target: national_target,
          trend: trendline,
          datasource: el.split('-')[1],
          year: year[year.length - 1] || null
        })
        // ================
        // PREPARE LGA MAP DATA

        const lgaLocation = await context.dispatch('GET_LOCATION_BY_ID')

        if (national_target?.length <= 0) {
          noTarget.push([lgaLocation.full_name, statusData])
        } else if (statusData >= national_target) {
          onTarget.push([lgaLocation.full_name, statusData])
        } else if (statusData < national_target) {
          offTarget.push([lgaLocation.full_name, statusData])
        } else {
          emptyValue.push([lgaLocation.full_name, 0])
        }
      })
    )
    // STEP 5: sort the result data to  match the order of the ngf state and return the reordered data
    const sortedResult = indicator
      .map((el) => {
        return result.find((el2) => el2.indicatorId === el.split('-')[0])
      })
      .filter((el) => el !== undefined)
    mapResult.push([onTarget, offTarget, noTarget, emptyValue])
    await context.dispatch('SET_LOADING', false, { root: true })
    // return result
    return { table: sortedResult, map: mapResult }
  },
  /**
   * @Function setUpZonalComparison
   * @Author davebenard
   * @param {object} context
   * @description Function to get the available years by indicator
   * @returns {array}
   */
  async SET_UP_ZONAL_COMPARISON (context, payload) {
    await context.dispatch('SET_LOADING', true, { root: true })

    const zonalResult = []
    const { object, yearValue } = payload
    const { indicator } = object
    // STEP 0: set up the year dropdown
    const yearData = new Date().getFullYear()
    const currentYear = yearData - 1
    // STEP 1: get the location id
    const locationId = await context.dispatch('GET_LOCATIONS')
    const location = await Request(`location/${locationId}/`)
    const { results } = await Request(`location/?parent=${location.parent}`)
    const fieldName = results?.map((el) => el.name)
    const locations = await results?.map((el) => el.id)
    const indicatorId =
      (await indicator?.length) > 1 && indicator?.map((el) => el.split('-')[0])
    const dataSourceId =
      (await indicator?.length) > 1 && indicator?.map((el) => el.split('-')[1])
    const dataSourceList = uniq(dataSourceId)
    const dS = dataSourceList[0]?.toString()
    const indicatorIdList = indicatorId.toString()
    // STEP 2:
    // await Promise.all(
    const zonalData = await Promise.all(
      locations?.map(async (el) => {
        const { results } = await Request(
          `data/?location=${el}&period=${
            yearValue || currentYear
          }&indicator__in=${indicatorIdList}6&datasource=${dS}`
        )
        return results
      })
    )
    // merge all the zonal data into one array
    const zonalDataMerge = zonalData.flat()
    // get the indicator data
    await Promise.all(
      zonalDataMerge.map(async (el) => {
        const { indicator, value, location } = el
        const { national_target, value_type, full_name } =
          await context.dispatch('GET_INDICATOR_BY_ID', indicator)
        const { name } = await Request(`location/${location}/`)
        zonalResult.push({
          indicator: full_name,
          [name]: {
            data: value || '-',
            increase: value > national_target,
            displayFactor: value_type
          },
          target: national_target
        })
      })
    )

    // Loop through the zonal Result and combine by indicator name and national target
    const newArr1 = []
    zonalResult.forEach((el) => {
      // eslint-disable-next-line no-unused-vars
      const { indicator, ...rest } = el
      const newArr = zonalResult.filter((el) => el.indicator === indicator)
      const newObj = {
        indicator,
        ...newArr.reduce((acc, cur) => {
          Object.keys(cur).forEach((key) => {
            acc[key] = acc[key] || cur[key]
          })
          return acc
        }, {})
      }
      newArr1.push(newObj)
    })
    // STEP: 5
    // return the uniq array
    const newArr2 = uniqBy(newArr1, 'indicator')
    context.commit('setZonalData', zonalResult)
    await context.dispatch('SET_LOADING', false, { root: true })
    // create the fields for the table
    const newFields =
      (await fieldName.length) > 0 &&
      fieldName?.map((item) => {
        return {
          key: item,
          sortable: false,
          thClass: 'bg-secondary text-white'
        }
      })
    return {
      newFields,
      data: newArr2
    }
  },
  /**
   * @Function setUpZonalComparison
   * @Author davebenard
   * @param {object} context
   * @description Function to get the available years by indicator
   * @returns {array}
   */
  async SET_UP_NATIONAL_COMPARISON (context, payload) {
    await context.dispatch('SET_LOADING', true, { root: true })
    const result = []
    // STEP 0: set up the indicator dropdown
    await context.dispatch('SET_YEAR_DROPDOWN')
    // STEP 1: get payload values and get the national target for the indication
    const { indicator, dataSource, period } = payload
    // -----
    const indicatorData =
      indicator !== '' &&
      (await context.dispatch('GET_INDICATOR_BY_ID', indicator))
    // STEP 2: get the data
    const resp = await Request(
      `data/?datasource=${dataSource}&indicator=${indicator}&period=${period}&size=100&location__level=3`
    )
    // STEP 3: make the dataObject for the chart
    await Promise.all(
      resp.results?.map(async (el) => {
        const locationName = await Request(`location/${el.location}`)
        const color =
          el.value < indicatorData.national_target ? '#dc3545' : '#28a745'
        result.push({
          name: locationName.name,
          y: Number(el.value),
          color
        })
      })
    )
    await context.dispatch('SET_LOADING', false, { root: true })
    return result.sort((a, b) => b.y - a.y)
  },

  // setting the data group
  async SET_DATA_GROUP (context, data) {
    await context.commit('setDataGroup', data)
    // await context.dispatch('SET_LOADING', false, { root: true })
  },

  // setting the section selected
  async SET_SECTION_SELECTED (context, data) {
    await context.commit('setSectionSelected', data)
    // await context.dispatch('SET_LOADING', false, { root: true })
  }
}

export default actions
