const mutations = {
  set_scorecard (state, payload) {
    state.scorecard = []
    state.scorecard = payload
  },
  set_dataentry (state, payload) {
    state.datas = []
    state.datas = payload
  },
  set_singleEntry (state, payload) {
    state.singledata = []
    state.singledata = payload
  },
  set_indicator_options (state, payload) {
    state.indicatorOptions = []
    state.indicatorOptions = payload
  },
  set_classification (state, payload) {
    state.classification = []
    state.classification = payload
  },
  set_governors (state, payload) {
    state.governors = []
    state.governors = payload
  },
  set_single_governor (state, payload) {
    state.singleGovernor = {}
    state.singleGovernor = payload
  }
}

export default mutations
