const getters = {
  data: (state) => state.data,
  getIndicatorOptions: (state) => state.indicatorOptions,
  getData: (state) => state.datas,
  getSingleData: (state) => state.singledata,
  getClassification: (state) => state.classification,
  getGovernors: (state) => state.governors,
  getSingleGovernor: (state) => state.singleGovernor
}

export default getters
