<template>
  <div class="row bg-secondary align-items-center pt-2">
    <div class="col-md-6 col-12 text-white mb-3">
      <div class="d-flex profile justify-content-start align-items-center">
        <!-- <i class="fa fa-user profile__avatar"></i> -->
        <img
          rel="preload"
          :src="
            require(`@/assets/img/governors/${user.state.toLowerCase()}.jpeg`)
          "
          alt="Governors Image"
          class="rounded-circle img-fluid"
          style="width: 87px; height: 85px; border: 0.9px solid"
        />
        <div class="flex-column ml-2 mt-3">
          <h5 class="h5 font-weight-bold">
            His Excellency <span class="text-capitalize"> {{ name }} </span>
          </h5>
          <h6 class="h6 text-capitalize">
            Governor of {{ user.state.toLowerCase() }} State
          </h6>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-12 text-white text-center">
      <div class="d-flex justify-content-end align-items-center">
        <form class="form-inline">
          <div class="form-group">
            <!--===== Year =====-->
            <div v-if="searchType === 'year'">
              <selectInput
                v-model="year"
                label="year"
                :options="getYearDropdown"
              />
            </div>
            <!--===== Year && Month =====-->
            <div v-else-if="searchType === 'yearmonth'">
              <div class="d-flex">
                <selectInput
                  v-model="year"
                  label="year"
                  :options="getYearDropdown"
                />
                <span class="mx-2"></span>
                <selectInput
                  v-model="month"
                  label="month"
                  :options="getMonthOptions"
                />
              </div>
            </div>
            <!--===== Empty Parameter =====-->
            <div v-else></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import { capitalize } from 'lodash'
import selectInput from '@/components/SelectInput.vue'
import setup from '@/mixins/setup'

export default {
  name: 'ProfilePage',
  mixins: [setup],
  props: {
    searchType: {
      type: String
    }
  },
  data () {
    return {
      currentRoute: 'State',
      search: this.searchType,
      year: '',
      month: ''
    }
  },
  components: {
    selectInput
  },
  computed: {
    name () {
      return capitalize(this.user.name)
    },
    user () {
      return this.$store.state.user
    },
    imagesUrl () {
      return '/img/governors/delta.jpg'
    },
    ...mapGetters('DASHBOARD_STORE', ['getYearDropdown', 'getMonthOptions'])
  },
  watch: {
    // currentRoute(newValue) {
    //   this.$router.push({ path: newValue });
    // },
    currentRoute (value) {
      switch (value) {
        case 'State':
          this.$router.push({ name: 'State' })
          // this.$emit('card-change', 'state');
          break
        case 'Zonal':
          this.$router.push({ name: 'Zonal' })
          // this.$emit('card-change', 'zonal');
          break
        case 'StateComparison':
          this.$router.push({ name: 'StateComparison' })
          break
        default:
          break
      }
    },
    year (yearParam) {
      this.$emit('selectedYear', yearParam)
    },
    month (monthParam) {
      this.$emit('selectedMonth', monthParam, this.selectedYear)
    },
    indicator () {
      this.getData()
    }
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_YEAR_DROPDOWN'])
  },
  async mounted () {
    await this.SET_YEAR_DROPDOWN()
    this.year = this.getYearDropdown[0].value
    this.month = this.getMonthOptions[0]
  }
}
</script>

//
<style lang="scss" scoped>
// .h5,
// h6 {
//   font-size: 0.9rem;
// }
//
</style>
