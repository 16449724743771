<template>
  <div>
    <b-modal
      size="xl"
      centered
      ref="intro-modal"
      id="intro-modal"
      hide-header
      hide-footer
      ok-only
    >
      <div class="d-block text-center">
        <div v-if="loading === false">
          <img
            v-if="`@/assets/img/state-logo/${state}.png`"
            :src="require(`@/assets/img/state-logo/${state}.png`)"
            :alt="`${state}`"
            width="100"
          />
          <h4 class="mt-4 text-uppercase">welcome to {{ state }} state</h4>
          <h5>{{ stateDetails.slogan }}</h5>
          <div class="p-4">
            <div class="row">
              <div class="col-md-6">
                <img
                  v-if="`@/assets/img/state/${state}.jpeg`"
                  :src="require(`@/assets/img/state/${state}.jpeg`)"
                  :alt="`${state}`"
                  height="450"
                  class="img-fluid rounded"
                />
                <img
                  v-else
                  :src="require(`@/assets/logo.png`)"
                  :alt="`${state}`"
                  height="450"
                  class="img-fluid rounded"
                />
              </div>
              <div class="col-md-6">
                <div class="d-flex flex-column flex-lg-column">
                  <p class="text-justify">
                    {{ stateDetails.description }}
                  </p>

                  <div class="mx-auto container-fluid">
                    <div class="mt-lg-3 text-right">
                      <div class="d-flex justify-content-end">
                        <button
                          @click="closeModal"
                          class="btn-lg btn-primary btn_contd"
                        >
                          Continue&nbsp;<span>&#8594;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      loading: true,
      state: '',
      stateDetails: {}
    }
  },
  computed: {
    ...mapGetters({
      modalValue: 'modalState',
      stateProfile: 'getCurrentStateProfile',
      userDetails: 'getUserDetails'
    })
  },
  methods: {
    /**
     * closeModal
     * * prevents modal popup more than ones when re-rendering state-profile section
     * ! This changes the state variable {modalState} to 'True' on modal close
     */
    ...mapActions(['SET_MODAL']),
    closeModal () {
      this.$bvModal.hide('intro-modal')
      this.SET_MODAL()
    }
  },
  async mounted () {
    this.loading = true
    await this.stateProfile
    /**
     ** checks if modal should be rendered
     */
    if (!this.modalValue) {
      this.$bvModal.show('intro-modal')
    }
    const description = {
      slogan: this.stateProfile[0][2],
      description: this.stateProfile[0][3]
    }
    this.stateDetails = description
    this.state = this.userDetails.state.split(' ').join(' ').toLowerCase()
    this.loading = false
  }
}
</script>

<style scoped>
.state-logo {
  width: 150px;
  height: 150px;
}
</style>
