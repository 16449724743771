<template>
  <div>
    <div v-if="getSectionSelected === 'Health Training Institutions'">
      <b-table
        sticky-header="30rem"
        bordered
        :items="items"
        :busy="isBusy"
        class="shadow-sm pb-1 bg-white"
        outlined
      >
      </b-table>
    </div>
    <div v-else-if="getSectionSelected === 'Human Resources for Health'">
      <b-table
        sticky-header="30rem"
        bordered
        :items="items"
        :busy="isBusy"
        class="shadow-sm pb-1 bg-white"
        outlined
      >
      </b-table>
    </div>
    <!-- <pre>
      {{ dataToConvert }}
    </pre> -->
    <b-table
      responsive
      sticky-header="30rem"
      bordered
      :items="dataItem"
      :fields="fields"
      :busy="loading"
      class="shadow-sm pb-1 bg-white"
      show-empty
      style="max-height: 60rem"
      v-if="isZonal"
    >
      <!-- Indicator Slot -->
      <template #cell()="data">
        <div v-if="states.includes(data.field.key)">
          <div v-if="data.item[data.field.key]">
            <div class="d-flex align-items-center">
              <img
                v-if="data.item[data.field.key].increase"
                class="arrow"
                :src="require('@/assets/img/arrow-up.png')"
              />
              <img
                v-if="!data.item[data.field.key].increase"
                class="arrow"
                :src="require('@/assets/img/arrow-down.png')"
              />

              <div
                class="box bg-success mx-2"
                :class="[
                  data.item[data.field.key].data >
                  parseFloat(data.item.target || 0)
                    ? 'bg-success'
                    : 'bg-danger',
                ]"
              ></div>
              <span
                class="minimum-width"
                v-if="data.item[data.field.key]?.data"
              >
                {{ data.item[data.field.key].data }}
              </span>
              <span class="minimum-width" v-else> - </span>
            </div>
            <!-- <span>{{ data.item[data.field.key] }}</span> -->
          </div>
        </div>
        <span v-else>{{ data.item[data.field.key] }}</span>
      </template>
    </b-table>
    <div v-show="showTable2 && !isZonal">
      <b-table
        responsive
        sticky-header="30rem"
        bordered
        :items="dataItem"
        :fields="fields"
        :busy="loading"
        class="shadow-sm pb-1 bg-white"
        show-empty
        style="max-height: 60rem"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <!-- Indicator Slot -->
        <template #cell()="data">
          <div v-if="states.includes(data.field.key)">
            <div v-if="data.item[data.field.key]">
              <div class="d-flex align-items-center">
                <img
                  v-if="data.item[data.field.key].increase"
                  class="arrow"
                  :src="require('@/assets/img/arrow-up.png')"
                />
                <img
                  v-if="!data.item[data.field.key].increase"
                  class="arrow"
                  :src="require('@/assets/img/arrow-down.png')"
                />

                <div
                  class="box bg-success mx-2"
                  :class="[
                    data.item[data.field.key].data >
                    parseFloat(data.item.target || 0)
                      ? 'bg-success'
                      : 'bg-danger',
                  ]"
                ></div>
                <span
                  class="minimum-width"
                  v-if="data.item[data.field.key]?.data"
                >
                  {{ data.item[data.field.key].data }}
                </span>
                <span class="minimum-width" v-else> - </span>
              </div>
              <!-- <span>{{ data.item[data.field.key] }}</span> -->
            </div>
          </div>
          <span v-else>{{ data.item[data.field.key] }}</span>
        </template>
        <!-- ADDITIONAL TABLE SLOT -->
        <!-- INDICATOR SLOT -->
        <template #cell(indicator)="data">
          <div
            class=""
            style="cursor: pointer"
            @click="
              $emit('emit-row-data', data.item);
              toggleActive();
            "
            v-if="data.item.programAreaSeletable || data.item.programArea"
          >
            <div class="d-flex align-items-center mb-2">
              <img
                :src="
                  require(`@/assets/stateProfiIeIcons/${
                    data.item.programAreaSeletable || data.item.programArea
                  }.png`)
                "
                class="mr-4"
                width="40"
              />
              <div
                class="d-flex flex-column"
                v-b-popover.hover.bottom="contentHtml(data.item)"
              >
                <span class="mt-1 font-weight-bold">
                  <!-- Manipulate the string -->
                  {{
                    data.item.indicator.split("(").length === 2
                      ? data.item.indicator.split("(")[1].split(")")[0]
                      : data.item.indicator.split("(")[2].split(")")[0]
                  }}
                </span>
                <span class="mt-1">
                  {{
                    data.item.indicator.split("(").length === 2
                      ? data.item.indicator.split("(")[0]
                      : data.item.indicator
                          .split(",")[0]
                          .split(")")[0]
                          .concat(")")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center"
            style="cursor: pointer"
            :class="{ highlight: data.item.indicator === selected }"
            @click="
              $emit('emit-row-data', data.item);
              toggleActive(data.item.indicator);
            "
            v-else
          >
            {{ data.item.indicator
            }}<span class="mx-1 d-none" v-if="data.item.status"
              >({{ formatValue(data.item.status.displayFactor) }})</span
            >
            <!-- <img
            :src="
              require(`../assets/indicatorIcons/${
                data.item.indicator
              }.svg`)
            "
            class="mr-4"
            width="40"
          /> -->
          </div>
        </template>
        <!-- <template #cell(indicator)="data">
        <h5 class="text-decoration-none h6">{{ data.item?.indicator }}</h5>
      </template> -->
        <!-- TREND CELL -->
        <template #cell(trend)="row">
          <span class="d-flex justify-content-center">
            <!-- {{ row.item.trend || row.trend }} -->
            <highcharts :options="row.item.trend || row.trend"></highcharts>
          </span>
        </template>
        <!-- STATUS CELL -->
        <template #cell(status)="data">
          <div v-if="data.item.status">
            <div class="h-100 d-flex">
              <!-- <img
              rel="preload"
              v-if="data.item.status.increase"
              class="arrow"
              :src="require('@/assets/img/arrow-up.png')"
            />
            <img
              rel="preload"
              v-if="!data.item.status.increase"
              class="arrow"
              :src="require('@/assets/img/arrow-down.png')"
            /> -->

              <!-- circle representation -->
              <div
                v-if="getSectionSelected === 'Access and Service Utilization'"
                class="box bg-success mx-2"
                :class="[
                  data.item.status.data > parseFloat(data.item.target)
                    ? 'bg-success'
                    : 'bg-danger',
                ]"
              ></div>
              <div>
                <span class="minimum-width" v-if="data.item.status.data"
                  >{{ formatValue(Math.floor(data.item.status.data)) }}&nbsp;
                  <small
                    v-if="
                      getSectionSelected === 'Access and Service Utilization'
                    "
                  >
                    (target: {{ data.item.target || 0 }})
                  </small>
                  &nbsp;
                  <span v-if="!data.item.status"
                    >({{ formatValue(data.item.status.displayFactor) }})</span
                  ></span
                >
                <span class="minimum-width" v-else> - </span>
              </div>
            </div>
          </div>
          <span v-else>{{
            formatValue(data.item.status(data.item.status))
          }}</span>
        </template>
        <!-- TARGET CELL -->
        <template #cell(target)>
          <div class="text-center">
            <span v-if="data.item.target">
              {{ formatValue(Math.floor(data.item.target)) }}</span
            >
          </div>
        </template>
        <!-- DENSITY CELL -->
        <template #cell(density)="data">
          <div class="text-center">
            <span v-if="data.item.status">
              {{ formatValue(Math.floor(data.item.status)) }}</span
            >
            <span v-else> - </span>
          </div>
        </template>
        <!-- No Data -->
        <template #empty>
          <div v-if="dataItem?.length === 0" style="height: 20rem">
            <NoData />
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import NoData from '@/components/NoData.vue'
import { formatToMoney } from '@/utils/index'

export default {
  name: 'TableComponent',
  components: {
    NoData
  },
  props: {
    IsIndicatorImages: {
      required: false,
      type: Boolean
    },
    dataItem: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    loading: {
      required: true,
      type: Boolean
    },
    isZonal: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getDataGroup', 'getSectionSelected'])
  },
  methods: {
    formatValue (value) {
      if (value !== '' && value !== undefined) {
        return formatToMoney(value)
      }
      return '-'
    },
    contentHtml ({ dataSource, indicator, indicatorDefinition, year }) {
      return {
        content: `<div>
                  <h5 class="font-weight-bold">${indicator}</h5>
                  <div class="mt-2">
                  <p class="d-none"> ${indicatorDefinition}</p>
                  <div> <p class="">Data Source: ${dataSource}</p></div>
                  <div> <p class="font-weight-bold">Year ${year}</p></div>
                  </div>`,
        html: true
      }
    },
    // Function to add the active class to the row
    toggleActive (id) {
      if (id) {
        this.selected = id
      }
    },
    convertKeyToPlaceholder (fields) {
      return fields.map(field => {
        return {
          ...field,
          key: field.placeholder
        }
      })
    },
    replaceFieldNames (dataItem, fields) {
      return dataItem.map(item => {
        const newItem = {}
        for (const field of fields) {
          const { key, placeholder } = field
          if (key in item) {
            newItem[placeholder] = item[key]
          } else {
            newItem[key] = item[key]
          }
        }
        return newItem
      })
    }
  },
  data () {
    return {
      loader: true,
      showTable2: true,
      dataToConvert: '',
      items: [],
      convertedFields: [],
      replacedDataItem: [],
      states: [
        'Akwa Ibom',
        'Bayelsa',
        'Cross River',
        'Delta',
        'Edo',
        'Rivers',
        'Abia',
        'Anambra',
        'Ebonyi',
        'Enugu',
        'Imo',
        'Ekiti',
        'Lagos',
        'Ogun',
        'Ondo',
        'Osun',
        'Oyo',
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'Federal Capital Territory',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa'
      ],
      selected: null
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    getDataGroup (val) {
      if (this.getSectionSelected === 'Health Training Institutions') {
        this.showTable2 = false
        // remove extra properties form the dataGroup Array
        function removePropertiesFromArray (array) {
          return array.map(innerArray => {
            return innerArray.map(obj => {
              const { trend, tooltip, plotOptions, ...rest } = obj
              return rest
            })
          })
        }
        const dataToConvert = removePropertiesFromArray(val)
        this.dataToConvert = removePropertiesFromArray(val)

        function convertData (dataArray) {
          const result = []
          const institutions = {}

          dataArray.forEach(group => {
            group.forEach(item => {
              const match = item.indicator.match(/^(.*?)\s+(.*?)\s*\((.+?)\)$/)
              if (match) {
                const category = match[1].trim()
                const institution = match[2].trim()
                let indicator = match[3].trim()
                if (indicator === 'Admission quota') {
                  indicator = 'Admission_quota'
                }

                if (!institutions[institution]) {
                  institutions[institution] = { institution }
                }
                institutions[institution][indicator] = parseFloat(item.status.data)
                institutions[institution][category] = parseFloat(item.status.data)
              }
            })
          })

          for (const institution in institutions) {
            result.push(institutions[institution])
          }

          return result
        }
        // eslint-disable-next-line no-unused-vars
        const convertedData = convertData(dataToConvert)

        function convertArray (inputArray) {
          const outputArray = []

          const institutionMap = {
            'Public School(s) of Health Technology': 'Schools of Health Technology',
            'Public School(s) of Nursing and Midwifery': 'Schools of Nursing and Midwifery',
            'Public College(s) of Medicine': 'Medical Colleges',
            'Public School(s) of Pharmacy': 'Schools of Pharmacy',
            'Private School(s) of Health Technology': 'Schools of Health Technology',
            'Private School(s) of Nursing and Midwifery': 'Schools of Nursing and Midwifery',
            'Private College(s) of Medicine': 'Medical Colleges',
            'Private School(s) of Pharmacy': 'Schools of Pharmacy',
            'Admission quota for School(s) of Health Technology': 'Schools of Health Technology',
            'Admission quota for School(s) of Nursing and Midwifery': 'Schools of Nursing and Midwifery',
            'Admission quota for College(s) of Medicine': 'Medical Colleges',
            'Admission quota for School(s) of Pharmacy': 'Schools of Pharmacy'
          }

          // const headers = ['Public', 'Private', 'Admission_quota']

          for (const innerArray of inputArray) {
            for (const obj of innerArray) {
              const { indicator, status } = obj
              const institution = institutionMap[indicator] || indicator

              let targetObj = outputArray.find(item => item.institution === institution)

              if (!targetObj) {
                targetObj = { institution }
                outputArray.push(targetObj)
              }

              const data = parseFloat(status.data)

              if (indicator.startsWith('Public')) {
                targetObj.Public = data
              } else if (indicator.startsWith('Private')) {
                targetObj.Private = data
              } else if (indicator.startsWith('Admission quota')) {
                targetObj.Admission_quota = data
              }
            }
          }

          return outputArray
        }
        const convertedArray = convertArray(dataToConvert)
        this.items = convertedArray
      }

      if (this.getSectionSelected === 'Human Resources for Health') {
        this.showTable2 = false
        // remove extra properties form the dataGroup Array
        function removePropertiesFromArray (array) {
          return array.map(innerArray => {
            return innerArray.map(obj => {
              const { trend, tooltip, plotOptions, ...rest } = obj
              return rest
            })
          })
        }
        const dataToConvert = removePropertiesFromArray(val)
        this.dataToConvert = removePropertiesFromArray(val)

        function convertData (dataArray) {
          const result = []
          const institutions = {}

          dataArray.forEach(group => {
            group.forEach(item => {
              const match = item.indicator.match(/^(.*?)\s+(.*?)\s*\((.+?)\)$/)
              if (match) {
                const category = match[1].trim()
                const institution = match[2].trim()
                let indicator = match[3].trim()
                if (indicator === 'WHO target') {
                  indicator = 'WHO_target'
                }

                if (!institutions[institution]) {
                  institutions[institution] = { institution }
                }
                institutions[institution][indicator] = parseFloat(item.status.data)
                institutions[institution][category] = parseFloat(item.status.data)
              }
            })
          })

          for (const institution in institutions) {
            result.push(institutions[institution])
          }

          return result
        }
        // eslint-disable-next-line no-unused-vars
        const convertedData = convertData(dataToConvert)

        function convertArray (inputArray) {
          const outputArray = []

          const occupationMapping = {
            'Number of Doctors': 'Doctors',
            'Density of Doctors (per 10,000)': 'Doctors',
            'WHO target for Doctors': 'Doctors',
            'Number of Nurses/Midwives': 'Nurses/Midwives',
            'Density of Nurses (per 10,000)': 'Nurses/Midwives',
            'WHO target for Nurses & Midwives': 'Nurses/Midwives',
            'Number of Community Health Workers': 'Community Health Workers',
            'Density of Community health workers (per 10,000)': 'Community Health Workers',
            'WHO target for Community health workers': 'Community Health Workers',
            'Number of Pharmacists': 'Pharmacists',
            'Density of Pharmacists (per 10,000)': 'Pharmacists',
            'WHO target for Pharmacists': 'Pharmacists'
          }

          for (let i = 0; i < inputArray.length; i++) {
            const data = inputArray[i]

            for (let j = 0; j < data.length; j++) {
              const occupation = occupationMapping[data[j].indicator]
              const number = parseFloat(data[j].status.data)
              const density = data[j].status.data

              if (outputArray.findIndex((item) => item.occupation === occupation) === -1) {
                outputArray.push({
                  occupation,
                  Number: null,
                  Density: null,
                  WHO_target: null
                })
              }

              if (data[j].indicator.includes('Number')) {
                const index = outputArray.findIndex((item) => item.occupation === occupation)
                outputArray[index].Number = number
              } else if (data[j].indicator.includes('Density')) {
                const index = outputArray.findIndex((item) => item.occupation === occupation)
                outputArray[index].Density = density
              } else if (data[j].indicator.includes('WHO target')) {
                const index = outputArray.findIndex((item) => item.occupation === occupation)
                outputArray[index].WHO_target = number
              }
            }
          }

          return outputArray
        }
        const convertedArray = convertArray(dataToConvert)
        this.items = convertedArray
      }
    },
    getSectionSelected (val) {
      this.showTable2 = true
    }
    // converting the array to a desired format
  },
  async mounted () {
    this.loader = false
    // remove extra properties form the dataGroup Array
    function removePropertiesFromArray (array) {
      return array.map(innerArray => {
        return innerArray.map(obj => {
          const { trend, tooltip, plotOptions, ...rest } = obj
          return rest
        })
      })
    }
    const dataToConvert = removePropertiesFromArray(this.getDataGroup)
    this.dataToConvert = removePropertiesFromArray(this.getDataGroup)

    function convertData (dataArray) {
      this.loader = true
      const result = []
      const institutions = {}

      dataArray.forEach(group => {
        group.forEach(item => {
          const match = item.indicator.match(/^(.*?)\s+(.*?)\s*\((.+?)\)$/)
          if (match) {
            const category = match[1].trim()
            const institution = match[2].trim()
            let indicator = match[3].trim()
            if (indicator === 'WHO target') {
              indicator = 'WHO_target'
            }

            if (!institutions[institution]) {
              institutions[institution] = { institution }
            }
            institutions[institution][indicator] = parseFloat(item.status.data)
            institutions[institution][category] = parseFloat(item.status.data)
          }
        })
      })

      for (const institution in institutions) {
        result.push(institutions[institution])
      }
      this.loader = false
      return result
    }
    // eslint-disable-next-line no-unused-vars
    const convertedData = await convertData(dataToConvert)

    function convertArray (inputArray) {
      this.loader = true
      const outputArray = []

      const occupationMapping = {
        Doctors: 'Doctors',
        'Density of Doctors (per 10,000)': 'Doctors',
        'WHO target for Doctors': 'Doctors',
        'Nurses/Midwives': 'Nurses/Midwives',
        'Density of Nurses (per 10,000)': 'Nurses/Midwives',
        'WHO target for Nurses & Midwives': 'Nurses/Midwives',
        'Community Health Workers': 'Community Health Workers',
        'Density of Community health workers (per 10,000)': 'Community Health Workers',
        'WHO target for Community health workers': 'Community Health Workers',
        Pharmacists: 'Pharmacists',
        'Density of Pharmacists (per 10,000)': 'Pharmacists',
        'WHO target for Pharmacists': 'Pharmacists'
      }

      for (let i = 0; i < inputArray.length; i++) {
        const data = inputArray[i]

        for (let j = 0; j < data.length; j++) {
          const occupation = occupationMapping[data[j].indicator]
          const number = parseFloat(data[j].status.data)
          const density = parseFloat(data[j].status.data)

          if (outputArray.findIndex((item) => item.occupation === occupation) === -1) {
            outputArray.push({
              occupation,
              Number: null,
              Density: null,
              WHO_target: null
            })
          }

          if (data[j].indicator.includes('Number')) {
            const index = outputArray.findIndex((item) => item.occupation === occupation)
            outputArray[index].Number = number
          } else if (data[j].indicator.includes('Density')) {
            const index = outputArray.findIndex((item) => item.occupation === occupation)
            outputArray[index].Density = density
          } else if (data[j].indicator.includes('WHO target')) {
            const index = outputArray.findIndex((item) => item.occupation === occupation)
            outputArray[index].WHO_target = number
          }
        }
      }
      return outputArray
    }
    const convertedArray = await convertArray(dataToConvert)
    this.items = convertedArray
    this.convertedFields = await this.convertKeyToPlaceholder(this.fields)
    this.replacedDataItem = await this.replaceFieldNames(this.dataItem, this.fields)
    // getting the data group from the state

    this.loader = false
  }
}
</script>

<style lang="scss" scoped>
// .loader{
//   position: absolute;
//   width: 100vw;
//   height: 60vh;
//   background-color: black;
//   z-index: 10;
// }
table {
  font-size: 0.9rem;
}
.arrow {
  font-size: 10px;
  margin-left: 5px;
  width: 18px;
}
.minimum-width {
  min-width: 50px;
}

.popover {
  max-width: 40rem !important;
}

.highlight {
  font-size: 1.05rem;
  text-decoration: underline;
  font-weight: bold;
}
</style>
