<template>
  <div>
    <div class="container" v-if="Loading">
      <div
        class="d-flex flex-column justify-content-center"
        style="height: 90vh"
      >
        <div class="text-center">
          <img
            src="@/assets/img/ngf_logo.png"
            width="300px"
            class="m-4"
            alt=""
            srcset=""
          />
          <h2 class="h2 text-center mb-4">
            Welcome To the Governors Dashboard
          </h2>
          <div
            class="spinner-border m-3 text-success"
            style="width: 5rem; height: 5rem"
            role="status"
          ></div>
          <p><strong>Loading... </strong></p>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view v-if="!Loading" />
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
// import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashboardPage',
  data () {
    return {
      gov_state_profile:
        'https://car-dashboard-others.firebaseio.com/governors-dashboard-state-profile.json',
      gov_state_info:
        'https://car-dashboard-others.firebaseio.com/governors-dashboard-stateinfo.json',
      gov_demography:
        'https://car-dashboard-others.firebaseio.com/demography.json',
      gov_health_facilities:
        'https://car-dashboard-others.firebaseio.com/health_facilities.json',
      gov_scorecard:
        'https://car-dashboard-others.firebaseio.com/ngf_scorecard.json',
      gov_yearly_url:
        'https://car-dashboard-others.firebaseio.com/governors-dashboard-yearly.json',
      gov_programArea_url:
        'https://car-dashboard-others.firebaseio.com/governors-dashboard-programArea.json',
      Loading: true
    }
  },
  computed: {
    // ...mapGetters(['getUserDetails']),
    // ...mapGetters('DASHBOARD_STORE', [
    //   'getDefault'
    // ])
  },
  methods: {
    // ...mapActions('DASHBOARD_STORE', ['GET_LOCATIONS', 'GET_DATA']),
    async getData () {
      try {
        this.Loading = true
        if (this.$store.state.stateProfile.length <= 0) {
          const Url = [
            this.gov_state_info,
            this.gov_state_profile,
            this.gov_demography,
            this.gov_health_facilities,
            this.gov_scorecard,
            this.gov_yearly_url
            // this.gov_programArea_url
          ]

          const request = Url.map((url) => axios.get(url))
          const data = await Promise.all(request)
          this.$store.dispatch('SET_STATE_INFO', data[0].data)
          this.$store.dispatch('SET_STATE_PROFILE', data[1].data)
          this.$store.dispatch('SET_DEMOGRAPHY_DATA', data[2].data)
          this.$store.dispatch('SET_HEALTH_FACILITY_DATA', data[3].data)
          this.$store.dispatch('SET_SCORECARD_DATA', data[4].data)
          this.$store.dispatch('SET_YEARLY_DATA', data[5].data)
          // this.$store.dispatch('SET_PROGRAM_AREA_DATA', data[6].data)
        }
      } catch (error) {
        console.log(error, '==>', 'error fetching data')
      } finally {
        this.Loading = false
      }
    },
    async getNewData () {
      try {
        this.loading = true
        const dataId = []
        await this.getDefault.forEach((el) => dataId.push(...el.indicator))
        await this.GET_DATA(dataId)
        await this.SET_UP_KH()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
    // async getStateProfile() {
    //   this.$store.dispatch('SET_STATE_PROFILE', stateProfile);
    // },
  },
  async mounted () {
    await this.getData()
    // await this.getNewData()
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
