<template>
    <div class="w-100">
      <!-- <Profile /> -->
    <router-view></router-view>
    </div>
</template>

<script>
// import Profile from '@/components/Profile.vue'

export default {
  name: 'AdminHome',
  components: {
    // Profile
  }
}
</script>
