<template>
  <b-modal id="failed" centered title="" v-model="show">
     <div class="container m-auto d-flex flex-column align-items-center p-3 justify-content-center">
        <div class="circle rounded-5 d-flex align-items-center justify-content-center mb-2"><b-icon icon="x" scale="2" variant="light"></b-icon></div>
        <h3 class="p mb-3">Upload Failed</h3>
        <p class="text-danger"> <span class="text-secondary">Error:</span> <br>Lorem Ipsum donor er mavix savis mon la cap <br> Lorem Ipsum donor er mavix savis mon la cap</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FailedComponent',
  data () {
    return {
      show: false
    }
  }
}
</script>

<style scoped>
 .circle{
    height:50px;
    width:50px;
    background-color: #2E5D5C;
    border-radius:50%;
    color:white;
 }
 .container{
    color:#2E5D5C;
 }
</style>
