import Dashboard from '../views/Dashboard.vue'
import StateScoreCard from '../views/detailed-indices/StateScoreCard.vue'
import NationalComparison from '../views/detailed-indices/NationalComparison.vue'
import ZonalComparison from '../views/detailed-indices/ZonalComparison.vue'
import StateProfile from '../views/StateProfileSection.vue'
import KeyHealthIndices from '../views/indices/index.vue'
import Scorecard from '../views/scorecard/Scorecard.vue'
import FlagshipProject from '../views/FlagshipPartner.vue'
import PartnerMapping from '../views/PartnerMapping.vue'
import scorecardView from '../views/scorecard/View.vue'
import Home from '../views/Home.vue'
import Upload from '../views/Upload.vue'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        alias: 'home',
        name: 'StateHome',
        component: Home
      },
      {
        path: '/upload',
        alias: 'upload',
        name: 'Upload',
        component: Upload
      },
      {
        path: 'state_profile',
        component: StateProfile
      },
      {
        path: 'detailed_Indices/scorecard',
        name: 'DetailedIndices',
        component: StateScoreCard
      },
      {
        path: 'detailed_Indices/compare_states',
        name: 'Compare State',
        component: NationalComparison
      },
      {
        path: 'detailed_Indices/compare_zones',
        name: 'Compare Zones',
        component: ZonalComparison
      },
      {
        path: 'key-health-indices',
        component: KeyHealthIndices
      },
      {
        path: 'scorecard',
        name: 'Scorecard',
        component: Scorecard
      },
      {
        path: 'flagship-projects',
        name: 'FlagshipProject',
        component: FlagshipProject
      },
      {
        path: 'partner-mapping',
        name: 'PartnerMapping',
        component: PartnerMapping
      },
      {
        path: 'scorecard/:slug',
        name: 'Scorecard-View',
        component: scorecardView
      }
    ]
  }
]
