<template>
  <!-- <div class="mb-5"> -->
  <div>
    <ul class="nav justify-content-between border-bottom px-3">
      <li class="nav-item" v-for="(links, i) in navbar" :key="i">
        <a
          class="nav-link text-capitalize font-weight-bold"
          :class="[i == activeTab ? 'active' : '']"
          @click="passIndicator(i, links)"
          >{{ links }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import setup from '@/mixins/setup'
import { mapGetters } from 'vuex'

export default {
  name: 'TopNav',
  mixins: [setup],
  data () {
    return {
      activeTab: 0,
      programAreasQuery: this.$route.query.program,
      navbar: []
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getDefault'])
  },
  methods: {
    passIndicator (index, links) {
      this.activeTab = index
      this.$emit('switched', links)
    },
    watchProgramAreas () {
      const program = this.programAreasQuery
      const index = this.navbar.indexOf(program)
      this.passIndicator(index, program)
    },
    async getTabs () {
      this.navbar = await this.getDefault.map((item) => item.programArea)
    }
  },
  async mounted () {
    await this.getTabs()
    if (this.programAreasQuery) {
      this.watchProgramAreas()
    }
    /**
    |--------------------------------------------------
    | Get the program areas from the config file
    |--------------------------------------------------
    */
  }
}
</script>
<style lang="scss" scoped>
$primary: #007d53;
a:hover {
  cursor: pointer !important;
  color: $primary !important;
  border-bottom: 3.5px solid $primary !important;
  margin-top: -3.5px;
}
.active {
  cursor: pointer !important;
  color: $primary !important;
  border-bottom: 3.5px solid $primary !important;
  margin-top: -3.5px;
}
a {
  color: black;
}
</style>
