<!-- eslint-disable vue/valid-v-model -->
<template>
  <main class="container-lg container-fluid">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="cover">
          <!-- TAB CONTROL -->
          <ul class="tab_control">
            <li class="active" id="upload" @click.prevent="handleTab('upload')">
              Upload Data
            </li>
            <li class="pipe"></li>
            <li id="download" @click.prevent="handleTab('download')">
              Download Template
            </li>
          </ul>
          <!-- UPLOAD SECTION -->
          <keep-alive><Upload v-if="tab === 'upload'" /></keep-alive>
          <Download v-if="tab === 'download'" />
          <!-- fOOTER SECTION -->
          <div class="d-flex justify-content-between mt-3">
            <h6 v-b-modal.audit>Audit Trails Log</h6>
            <h6 v-b-modal.contact>Contact Us</h6>
            <!-- <span v-b-modal.completed>Completed</span> -->
            <!-- <span v-b-modal.failed>Failed</span> -->
          </div>
          <div>
            <Contact />
            <AuditLog />
            <Completed />
            <Failed />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Contact from '../../admin/components/Contact.vue'
import AuditLog from '../../admin/components/AuditLog.vue'
import Completed from '../../admin/components/Completed.vue'
import Failed from '../../admin/components/Failed.vue'
import Download from '../../admin/components/Download.vue'
import Upload from '../../admin/components/Upload.vue'

export default {
  name: 'UploadPage',
  components: {
    Contact,
    AuditLog,
    Completed,
    Failed,
    Upload,
    Download
  },
  data () {
    return {
      isClicked: false,
      isLoading: false,
      data: [],
      tab: 'upload',
      fileName: ''
    }
  },
  methods: {
    handleTab (data) {
      this.tab = data
      const element = document.getElementById(data)
      element.classList.add('active')
      // remove active class from other tabs
      const tabs = document.querySelectorAll('.tab_control li')
      tabs.forEach((tab) => {
        if (tab.id !== data) {
          tab.classList.remove('active')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.cover {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #464343;

  ul.tab_control {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 0.5rem;
    li {
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0px;
      color: #5a6e6e;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      &.active {
        opacity: 1;
      }
      &:hover:first-child {
        margin-right: 0.5rem;
      }
      &:hover:last-child {
        padding-left: 0.5rem;
      }
    }
  }

  div.upload_wrapper {
    width: 100%;
    padding: 5rem 2rem;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #617474;
    background: #2e5d5c08 0% 0% no-repeat padding-box;
    border: 1px dashed #2e5d5c73;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    div.description {
      margin-bottom: 1rem;
      h4 {
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0px;
        color: #5a6e6e;
        text-decoration: none;
      }
      p {
        text-align: left;
        letter-spacing: 0px;
        color: #5a6e6e;
        font-size: 1rem;
      }
    }
  }
  .pipe {
    margin: 0 10px;
    width: 2px;
    background-color: #2e5d5c;
  }
}
</style>
