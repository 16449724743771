const state = {
  dataSource: [],
  dataSourceId: [],
  indicators: [],
  indicatorsId: [],
  years: [],
  locations: [],
  locationId: '',
  lastUpdate: '',
  data: [],
  sectionData: [],
  activeTab: 'Outcomes',
  yearDropdown: [],
  programArea: [],
  stateData: [],
  dataGroup: [],
  sectionSelected: '',
  partnerMapping: [],
  flagshipProject: [],
  ngf: [
    // {
    //   programArea: 'PHC',
    //   indicator: [
    //     '427-25',
    //     '431-25',
    //     '432-25',
    //     '433-25',
    //     '434-25',
    //     '435-25',
    //     '436-25',
    //     '437-25',
    //     '438-25',
    //     '439-25'
    //   ],
    //   dataSource: [25]
    // },
    {
      programArea: 'Human Resources for Health',
      indicator: [
        '617-25', '618-25', '619-25', '620-25'
      ],
      indicatorNumber: ['613-25', '614-25', '615-25', '616-25'],
      indicatorDensity: ['617-25', '618-25', '619-25', '620-25'],
      indicatorTargetWho: ['621-25', '622-25', '623-25', '624-25'],
      dataSource: [25]
    },
    {
      programArea: 'Health Training Institutions',
      indicator: ['419-25', '420-25', '421-25', '600-25', '601-25', '602-25', '603-25', '604-25', '605-25', '606-25', '607-25', '608-25', '609-25', '610-25', '611-25', '612-25'],
      indicatorPrivate: ['602-25', '605-25', '608-25', '611-25'],
      indicatorPublic: ['601-25', '604-25', '607-25', '610-25'],
      indicatorAdmission: ['603-25', '606-25', '609-25', '612-25'],
      dataSource: [25]
    },
    // {
    //   programArea: 'Health Insurance',
    //   indicator: [
    //     '427-25',
    //     '431-25',
    //     '432-25',
    //     '433-25',
    //     '434-25',
    //     '435-25',
    //     '436-25',
    //     '437-25',
    //     '438-25',
    //     '439-25'
    //   ],
    //   dataSource: [25]
    // },
    {
      programArea: 'Health Financing',
      indicator: [
        '445-25',
        '633-25',
        '202-25',
        '447-25',
        '263-25',
        '625-25',
        '626-25',
        '627-25'
      ],
      indicatorAllocation: ['445-25', '633-25', '202-25', '447-25', '681-25'],
      indicatorPerformance: ['625-25', '678-25', '626-25', '679-25', '627-25'],
      dataSource: [25]
    },
    {
      programArea: 'Access and Service Utilization',
      indicator: [
        '704-25',
        '628-25',
        '706-25',
        '705-25',
        '707-25',
        '708-25',
        '629-25',
        '635-25',
        '671-25',
        '455-25',
        '672-25',
        '673-25',
        '674-25',
        '675-25',
        '676-25',
        '677-25'
      ],
      dataSource: [25]
    },
    {
      programArea: 'Health Outcomes',
      indicator: [
        '631-25',
        '632-25',
        '630-25',
        '666-25',
        '667-25',
        '668-25'
      ],
      dataSource: [25]
    }
    // {
    //   programArea: 'Nutrition',
    //   indicator: ['631-25', '632-25'],
    //   dataSource: [25]
    // }
    // {
    //   programArea: 'Outcomes',
    //   indicator: ['31-6', '30-6', '32-6', '29-6', '457-6'],
    //   dataSource: [6]
    // }
  ],
  stateProfile: [
    {
      programArea: 'Demography',
      indicator: ['62-25', '412-25', '413-25', '414-25', '415-25']
    },
    {
      programArea: 'Human Resources for Health',
      indicator: ['613-25', '614-25', '615-25', '616-25']
    },
    {
      programArea: 'Health Training Institutions',
      indicator: ['419-25', '420-25', '421-25', '600-25']
    },
    {
      programArea: 'Health Facilities',
      indicator: ['416-25', '417-25', '418-25', '599-25']
    },
    {
      programArea: 'SSHIS',
      indicator: [
        '464-25',
        '465-25',
        '466-25',
        '467-25',
        '468-25',
        '469-25',
        '470-25',
        '471-25',
        '472-25',
        '473-25',
        '474-25'
      ]
    },
    {
      programArea: 'Health Insurance',
      indicator: [
        '464-25',
        '465-25',
        '466-25',
        '467-25',
        '468-25',
        '469-25',
        '470-25',
        '471-25',
        '472-25',
        '473-25',
        '474-25'
      ]
    },
    {
      programArea: 'Drug Management Agency',
      indicator: [
        '464-25',
        '465-25',
        '466-25',
        '467-25',
        '468-25',
        '469-25',
        '470-25',
        '471-25',
        '472-25',
        '473-25',
        '474-25'
      ]
    }
  ],
  keyHealthIndices: [],
  zonalData: []
}

export default state
