export default {
  chart: {
    type: 'column'
  },
  title: {
    text: null
  },
  subtitle: {
    text: null
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: 270,
      title: {
        text: 'State'
      },
      style: {
        fontSize: '13px',
        fontFamily: 'Verdana, sans-serif'
      }
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Density'
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  tooltip: {
    pointFormat: 'Density: <b>{point.y:.1f}</b>'
  },
  // series: [{
  //   name: 'Population',
  //   data: [
  //   ],
  //   dataLabels: {
  //     enabled: false,
  //     rotation: -90,
  //     color: '#FFFFFF',
  //     align: 'right',
  //     format: '{point.y:.1f}', // one decimal
  //     y: 10, // 10 pixels down from the top
  //     style: {
  //       fontSize: '13px',
  //       fontFamily: 'Verdana, sans-serif'
  //     }
  //   }
  // }]
  series: []
}
