<template>
  <div class="upload_wrapper">
    <!-- DESCRIPTION -->
    <div class="description">
      <h4>Upload Data</h4>
      <p>
        files in the format of .xslx .xlsm .xlsb, are permitted for submittion
        and upload. Other file format apart from these highlighted are not
        permitted
      </p>
      <p>Maximum file size: <span class="fw-bolder">1470KB</span></p>
    </div>
    <!-- 3RD SECTION -->
    <div class="d-flex align-item-center flex-column justify-content-center">
      <img
        v-if="fileName !== ''"
        class="d-flex align-self-center mb-3"
        src="@/assets/img/downloadExcel.png"
        alt="download icon"
        width="65.5"
      />
      <img
        v-else
        class="d-flex align-self-center mb-3"
        src="@/assets/img/upload.png"
        alt="download icon"
        width="65.5"
      />
      <h6 v-if="fileName !== ''">{{ fileName }}</h6>
      <div class="d-flex justify-content-center mb-3">
        <div>
          <input
            type="file"
            class="form-control input_alt"
            placeholder="Enter your email"
            id="uploadData"
            accept=".xlsx, .xlsm, .xlsb"
            @change="handleFileChange"
            ref="data"
          />
          <label
            for="uploadData"
            class="btn btn-primary py-2 px-3 rounded-0 mb-0"
            style="background-color: #2e5d5c"
          >
            Browse Files
          </label>
        </div>
      </div>
      <p class="text-center">Need help? First time users can</p>
      <a class="text-center" href="#" @click.prevent="handleDownload"
        >Download File Template</a
      >
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'UploadComponent',
  data () {
    return {
      fileName: ''
    }
  },
  methods: {
    handleFileChange () {
      const file = this.$refs.data.files[0]
      this.fileName = file.name
      const formData = new FormData()
      formData.append('file', file)
      this.isLoading = true
    },
    handleDownload () {
      const url = '@/assets/docs/NGF_Data_Upload.xlsx'
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function (event) {
        const blob = xhr.response
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'NGF_Data_Upload.xlsx'
        link.click()
      }
      xhr.send()
    }
  }
}
</script>

<style lang="scss" scoped>
input.input_alt {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  z-index: -1;
}
h4 {
  text-decoration: none;
}
</style>
