<template>
  <div class="loading" v-if="getLoading">
    <div class="loading_content">
      <b-spinner variant="light" style="width: 3rem; height: 3rem"></b-spinner>
      <h4>Setting up Dashboard</h4>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
  name: 'LoaderComponent',
  computed: {
    ...mapGetters([
      'getLoading'
    ])
  }
}
</script>

<style lang="scss" scoped>
div.loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  div.loading_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    h4 {
      color: #ffffff;
      margin-top: 2rem;
      text-decoration: none;
    }
  }
}
</style>
