<template>
  <div>
    <!--===== User Profile =====-->
    <div class="container-fluid">
      <Profile
        @selectedYear="selectYear"
        @selectedMonth="SelectMonth"
        :searchType="type"
        :yearOptions="yearOptions"
        :monthOptions="monthOptions"
      />
    </div>

    <div class="container-fluid px-0">
      <TopNav @switched="switchTab" />
    </div>

    <div class="container-fluid px-4 mb-2">
      <GenericTooltip />
    </div>

    <div class="row mx-lg-5 mx-md-3">
      <!--===== MAP Data Representation =====-->
      <!-- <div class="col-12" v-if="dataAvailable"> -->
      <div class="col-12">
        <!-- <template v-if="programArea === 'Health Financing'">
          <LocalTable v-if="dataObject.length > 0" :dataItem="dataObject" :loading="Loading" />
        </template>
        <template v-else-if="programArea !== 'Health Financing'"> -->
        <Table
          class="mb-5"
          :dataItem="dataObject"
          :loading="Loading"
          :fields="fields"
          :isZonal="true"
        />
      </div>
      <!-- </template> -->
      <!-- </div> -->
      <!-- <div class="col-12 col mx-auto py-4 text-center" v-else>
        <h4>No Data Available</h4>
      </div> -->
    </div>

    <!--===== Bottom Navigation =====-->
    <Loader />
    <BottomNav />
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
// import { findKey } from 'lodash'
import GenericTooltip from '@/components/generic/GenericTooltip.vue'
import { previousValue, formatToCurrency } from '@/utils/index'
// import setup from '@/mixins/setup'
import Profile from '@/components/Profile.vue'
import TopNav from '@/components/TopNav.vue'
import Table from '@/components/TableComponent.vue'
import BottomNav from '@/components/BottomNav.vue'
// import LocalTable from './components/Zones_Table.vue';
import Loader from '@/modules/dashboard/components/Loader.vue'

export default {
  name: 'ZonalComparison',
  // mixins: [setup],
  components: {
    Profile,
    TopNav,
    Table,
    BottomNav,
    GenericTooltip,
    Loader
  },
  data () {
    return {
      dataAvailable: true,
      dataObject: [],
      sortable: false,
      Loading: true,
      type: 'year',
      yearOptions: [],
      monthOptions: [],
      yearParam: '',
      programObject: {},
      fields: []
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getProgramArea', 'getDefault', 'getZonalData'])
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_UP_ZONAL_COMPARISON']),
    ...mapActions(['SET_NO_DATA']),
    /**
     * ?? Get Data (Yearly Data)
     */
    getData () {
      let programArea = this.getProgramAreas()

      if (this.programArea !== 'all') {
        programArea = [this.programArea]
      }
      const states = this.zones[this.zone]
      /**
       * set the table Headers
       */
      this.titleFieldSetup(states)
      this.dataObject = []
      programArea.forEach((program) => {
        this.getProgramAreaSource(program)

        let indicators = this.getProgramAndIndicator(program)
        if (this.indicator !== 'all') {
          indicators = [this.indicator]
        }
        this.dataAvailable = Number.isFinite(this.year)
        if (!this.dataAvailable) {
          return
        }
        indicators.forEach((indicator) => {
          const indicatorsAndSource = `${indicator} -> ${this.dataSource}`
          const index = this.yearlyData[0].indexOf(indicatorsAndSource)
          const object = {}
          if (!this.dataObject.some((obj) => obj.program_area === program)) {
            // object.program_area = program;
          }
          object.indicator = indicator
          object.target = this.getNationalTarget(indicator)
          states.forEach((stateName) => {
            const stateData = this.yearlyData.filter((elem) => elem[1] === stateName)
            stateData.forEach(async (data) => {
              if (data[0] === Number(this.year)) {
                const displayFactor = this.getDisplayFactor(indicator)
                if (data[index] !== undefined && data[index].toString().length > 0) {
                  const increase = previousValue(stateData, stateName,
                    Number(this.year) - 1, index) < data[1]
                  object[stateName] = {
                    // data:
                    //   displayFactor.type === 'currency'
                    //     ? formatToCurrency(data[index])
                    //     : Number.parseFloat(data[index]),
                    data: program === 'Health Financing' ? formatToCurrency(data[index]) : Number.parseFloat(data[index]),
                    increase,
                    displayFactor: displayFactor.sign
                  }
                }
                // else {
                //   object[stateName] = {
                //     data: null,
                //     increase: false,
                //     displayFactor: displayFactor.sign,
                //   };
                // }
              }
            })
          })

          this.dataObject.push(object)
        })
      })
      this.fields.push({
        key: 'target',
        sortable: false,
        thClass: 'bg-secondary text-white'
      })
      this.yearOptions = this.availableYears
      this.monthOptions = this.availableMonths
    },
    /**
     * ?? Get monthly zonal data
     */
    getMonthlyData () {
      let programArea = this.getProgramAreas()

      if (this.programArea !== 'all') {
        programArea = [this.programArea]
      }
      const states = this.zones[this.zone]
      /**
       * set the table Headers
       */
      this.titleFieldSetup(states)
      this.dataObject = []

      programArea.forEach((program) => {
        // this.getProgramAreaSource(program);

        let indicators = this.getProgramAndIndicator(program)
        if (this.indicator !== 'all') {
          indicators = [this.indicator]
        }
        this.dataAvailable = Number.isFinite(this.year)
        if (!this.dataAvailable) {
          return
        }
        indicators.forEach((indicator) => {
          const indicatorsAndSource = `${indicator} -> ${this.dataSource}`
          const index = this.monthlyData[0].indexOf(indicatorsAndSource)
          const object = {}
          if (!this.dataObject.some((obj) => obj.program_area === program)) {
            // object.program_area = program;
          }
          object.indicator = indicator
          object.target = this.getNationalTarget(indicator)
          states.forEach((stateName) => {
            const stateData = this.monthlyData.filter((elem) => elem[1] === stateName)
            stateData.forEach((data) => {
              const Name = moment(data[0], 'YYYY/MM/DD')
              const yearValue = Name.format('YYYY')
              const monthName = Name.format('MMMM')
              if (Number(yearValue) === Number(this.year) && monthName === this.month) {
                const displayFactor = this.getDisplayFactor(indicator)
                if (data[index] !== '') {
                  const increase = previousValue(stateData, stateName,
                    Number(this.year) - 1, index) < data[index]

                  object[stateName] = {
                    data: data[index],
                    increase: !!increase,
                    displayFactor:
                      displayFactor.type === 'Per 1000' || displayFactor.type === 'Per 100,000'
                        ? ''
                        : '%'
                  }
                } else {
                  object[stateName] = {
                    data: null,
                    increase: false,
                    displayFactor:
                      displayFactor.type === 'Per 1000' || displayFactor.type === 'Per 100,000'
                        ? ''
                        : '%'
                  }
                }
              }
            })
          })
          this.dataObject.push(object)
        })
      })
      this.fields.push({
        key: 'target',
        sortable: false,
        thClass: 'bg-secondary text-white'
      })
      this.yearOptions = this.availableYears
      this.monthOptions = this.availableMonths
    },
    /**
     * ?? Onclick of any programArea
     */
    Switch (value) {
      this.programArea = value
      const indicators = this.getProgramAndIndicator(value)
      if (this.checkMonthlyDataIsAvailable(indicators[0], 'NHMIS')) {
        this.type = 'yearmonth'
        this.getMonthlyData()
      } else {
        this.type = 'year'
        this.getData()
      }
    },
    /**
     * ?? Display data for the selected year
     */
    SelectYear (selectedYear) {
      this.year = selectedYear
      this.getData()
    },
    /**
     * ?? Display data for the selected month only on data that has monthly data
     */
    SelectMonth (selectedMonth) {
      this.month = selectedMonth
      if (this.type === 'yearmonth') {
        this.getMonthlyData()
      }
    },
    async switchTab (value) {
      this.Loading = true
      const item = this.getDefault.find((item) => item.programArea === value)
      this.programObject = item
      const response = await this.SET_UP_ZONAL_COMPARISON({ object: this.programObject, yearValue: this.yearParam })
      this.dataObject = response.data
      this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
      this.Loading = false
    },
    async selectYear (value) {
      this.Loading = true
      this.yearParam = value
      const response = await this.SET_UP_ZONAL_COMPARISON({ object: this.programObject, yearValue: this.yearParam })
      this.dataObject = response.data
      // get the field setup for the table
      this.fields.push(
        {
          name: 'Indicator',
          key: 'indicator',
          sortable: false,
          thClass: 'bg-secondary text-white'
        },
        ...response.newFields,
        {
          key: 'target',
          sortable: false,
          thClass: 'bg-secondary text-white'
        })
      this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
      this.Loading = false
    }
  },
  watch: {
  },
  async mounted () {
    // this.zone = findKey(this.zones, (el) => el.includes(this.stateName))
    // ====== Set ProgramArea Dropdown ======
    this.programAreas = await this.getProgramArea
    this.programArea = await this.getProgramArea[0]
    const initial = await this.getDefault[0]
    this.programObject = initial
    const response = await this.SET_UP_ZONAL_COMPARISON({
      object: initial,
      year: ''
    })
    this.dataObject = response.data
    this.fields.push(
      {
        name: 'Indicator',
        key: 'indicator',
        thClass: 'bg-secondary text-white',
        sortable: false
      },
      ...response.newFields,
      {
        key: 'target',
        sortable: false,
        thClass: 'bg-secondary text-white'
      })
    this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
    this.Loading = false
  }
}
</script>

<style lang="scss" scoped></style>
