<template>
  <div class="card border-0 p-3 my-3">
    <form @submit.prevent="handleSubmit" @reset="handleReset">
      <div class="row">
        <!-- -------- -->
        <div class="col-md-4 mb-3">
          <div class="form-group">
            <label for="period">Period</label>
            <select
              class="form-control"
              name="period"
              id="period"
              ref="period"
              v-model="selectedPeriod"
            >
              <option value="" disabled>--Select Period--</option>
              <option
                v-for="el in getYearDropdown"
                :key="el.value"
                :value="el.value"
              >
                {{ el.value }}
              </option>
            </select>
          </div>
        </div>
        <!-- -------- -->
        <div class="col-md-4 mb-3">
          <div class="form-group">
            <label for="location">Location</label>
            <input
              type="text"
              v-model="location"
              name="location"
              id="location"
              class="form-control"
              readonly
            />
          </div>
        </div>
        <!-- -------- -->
        <div class="col-md-4 mb-3">
          <div class="form-group">
            <label for="datasource">DataSource</label>
            <input
              v-model="datasource"
              type="text"
              name="datasource"
              id="datasource"
              class="form-control"
              readonly
            />
          </div>
        </div>
        <!-- -------- -->
      </div>
      <!-- <pre>{{ entry }}</pre> -->
      <!-- <div class="list_entry d-none" v-if="selectedPeriod !== ''">
        <h4 class="mb-3 h4">Entered Data</h4>
        <div
          v-for="(row, i) in entry"
          class="row d-flex align-items-center"
          :key="i"
        >
          <div class="col-md-4 mb-sm-3">
            <div class="form-group">
              <label for="programarea">ProgramArea {{ i + 1 }}</label>
              <select
                class="form-control"
                name="programarea"
                :id="`programarea-${[i]}`"
                v-model="entry[i].programArea"
                @change="handleRow(i)"
              >
                <option value="" disabled>--Select Program Area--</option>
                <option v-for="(el, i) in getProgramArea" :key="i" :value="el">
                  {{ el }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 mb-sm-3">
            <div class="form-group">
              <label for="indicator">Indicator {{ i + 1 }}</label>
              <select
                class="form-control"
                name="indicator"
                :id="`indicator-${[i]}`"
                disabled
                v-model="entry[i].indicator"
              >
                <option value="" disabled>--Select Indicator--</option>
                <option
                  v-for="el in indicatorOptions"
                  :key="el.id"
                  :value="el.id"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2 mb-sm-3">
            <div class="form-group">
              <label for="value">Value</label>
              <input
                type="text"
                name="value"
                :id="`value-${[i]}`"
                class="form-control"
                placeholder="Value"
                disabled
                @blur="handleRow(row.id)"
              />
            </div>
          </div>
          <div class="col-md-2 h-100">
            <button
              class="btn btn-primary mx-3 d-none"
              @click.prevent="editRow(i)"
            >
              edit
            </button>
            <button class="btn btn-danger" @click.prevent="deleteRow(i)">
              delete
            </button>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <b-button @click.prevent="handleSubmit">Submit</b-button>
          <button class="btn btn-outline-primary" @click.prevent="addRow">
            Add
          </button>
        </div>
      </div> -->
    </form>

    <!-- -------------------- -->

    <div class="accordion" role="tablist" v-if="selectedPeriod !== ''">
      <b-card
        no-body
        class="mb-1"
        v-for="(el, i) in getIndicatorOptions"
        :key="i"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle="`row-${[i]}`"
            variant="primary"
            class="text-left"
            >{{ el.programArea }}</b-button
          >
        </b-card-header>
        <b-collapse :id="`row-${[i]}`" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="list_entry">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-weight-bold text-uppercase"
                    >indicator</label
                  >
                </div>
                  <div class="col-md-3">
                  <div class="form-group">
                    <label class="font-weight-bold text-uppercase mb-5"
                      >Classification</label
                    >
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold text-uppercase">Value</label>
                </div>
              </div>
            </div>
            <div class="">
              <div
                v-for="(row, j) in el.data"
                class="row d-flex align-items-center"
                :key="j"
              >
                <div class="col-md-6 mb-sm-3">
                  <p>{{ row.full_name }}</p>
                </div>
                <div class="col-md-3 mb-sm-3">
                  <div class="form-group">
                    <label
                      class="font-weight-bold text-uppercase sr-only"
                      for="value"
                      >Value</label
                    >
                    <select
                      class="form-control"
                      name="value_type"
                      :id="`value_type-${[row.id]}`"
                      @change="handleRowSelect(row.id)"
                    >
                      <option value="" disabled selected>--Select value type--</option>
                      <option
                        v-for="el in getClassification"
                        :key="el.id"
                        :value="el.id"
                      >
                        {{ el.value_type }}
                      </option>
                    </select>
                    <p v-show="show" style='color: red; margin-left: 10px;' >A value type must be select</p>
                  </div>
                </div>
                <div class="col-md-3 mb-sm-3">
                  <div class="form-group">
                    <label
                      class="font-weight-bold text-uppercase sr-only"
                      for="value"
                      >Value</label
                    >
                    <input
                      type="text"
                      name="value"
                      :id="`value-${[row.id]}`"
                      class="form-control"
                      placeholder="Value"
                      disabled="true"
                      @blur="handleRowInput(row.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <div class="w-100 d-flex justify-content-end">
        <b-button @click.prevent="handleSubmit">Submit</b-button>
      </div>
    </div>
    <DataTable
      :header="headerTable"
      :body="bodyTable"
      @proceed="handleProceed"
    />
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex'
import DataTable from './DataTable.vue'

export default {
  name: 'BaseValue',
  components: {
    // NGFTable,
    DataTable
  },
  data () {
    return {
      loading: false,
      show: false,
      entry: [],
      editEntry: {},
      datasource: 'SMOH-DHPRS',
      location: '',
      selectedPeriod: '',
      indicatorOptions: [],
      programAreaOptions: [],
      newOptions: [],
      headerTable: [],
      bodyTable: [],
      value_type: ''
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getIndicators', 'getDefault', 'getProgramArea', 'getLocationId', 'getYearDropdown']),
    ...mapGetters(['getUserDetails']),
    ...mapGetters('ADMIN_STORE', ['getIndicatorOptions', 'getClassification'])
  },
  watch: {
    selectedPeriod () {
      // this.$refs.period.setAttribute('disabled', true)s
      if (this.entry.length === 0) {
        this.addRow()
      } else {
        this.entry.forEach(el => {
          // update all the period in the entry array
          el.period = this.selectedPeriod
        })
      }
    }
  },
  methods: {
    ...mapActions('ADMIN_STORE', ['UPLOAD_FILE', 'POST_DATA_ENTRY', 'GROUP_INDICATORS_BY_PROGRAM_AREA', 'GET_CLASSIFICATION']),
    ...mapActions('DASHBOARD_STORE', ['GET_INDICATORS_BY_DATA_SOURCE', 'GET_LOCATIONS', 'SET_YEAR_DROPDOWN']),
    async handleSubmit () {
      const newEntry = this.entry.filter((el) => el.value !== '')

      this.bodyTable = newEntry
      this.headerTable = [this.selectedPeriod, this.location, this.datasource]
      this.$bvModal.show('data-entry')
    },
    async handleProceed (data) {
      this.loading = true
      try {
        const newEntry = this.entry.filter((el) => el.value !== '')
        const validEntry = newEntry.map((el) => ({
          datasource: el.datasource,
          indicator: el.indicator,
          location: el.location,
          period: el.period,
          value: el.value,
          value_type: Number(el.value_type)
        }))
        const data = {
          name: `${this.getUserDetails?.state}-${this.selectedPeriod}-data`,
          file_type: 'Data',
          data: validEntry,
          application: 'NGF'
        }
        await this.POST_DATA_ENTRY(data)
        this.$vToastify.success('Successful', `${data.name} has been submitted successfully`)
        this.loading = false
      } catch (error) {
        console.log(error.error)
      } finally {
        this.loading = false
      }
    },
    /**
     * @Author davebenard
     * @description Function to add the entry fields on select of a period
     * @returns {array}
     */
    async addRow () {
      //* check if this.getIndicatorOptions is empty
      if (this.getIndicatorOptions.length === 0) {
        this.entry = []
        await this.GROUP_INDICATORS_BY_PROGRAM_AREA()
      } else {
        const option = this.getIndicatorOptions
        const ind = []
        option.forEach(el => {
          ind.push(...el.data)
        })
        this.entry = await ind.map((el, i) => {
          return {
            id: el.id,
            indicator: el.id,
            indicatorName: el.full_name,
            value: '',
            datasource: 25,
            location: this.getLocationId,
            period: this.selectedPeriod,
            value_type: this.value_type
          }
        })
      }
    },
    /**
     * @Author davebenard
     * @description Function to delete a field by id
     * @returns {array}
     */
    deleteRow (id) {
      // show confirm dialog
      const confirm = window.confirm('Are you sure you want to delete this row?')
      if (confirm) {
        this.entry = this.entry.filter((item) => item.id !== id)
      }
    },
    /**
     * @Author davebenard
     * @description Function to edit the entry fields by id
     * @returns {array}
     */
    editRow (id) {
      this.entry = this.entry.map((item) => {
        if (item.id === id) {
          this.editEntry = item
        }
        return item
      })
    },
    /**
     * @Author davebenard
     * @description Function to save an entry
     * @returns {array}
     */
    saveEditRow (id) {
      this.entry = this.entry.map((item) => {
        if (item.id === id) {
          // eslint-disable-next-line no-param-reassign
          item = this.editEntry
        }
        return item
      })
    },
    // * Function to handle the form reset
    handleReset () {
      console.log('reset')
    },
    /**
     * @Author davebenard
     * @Function to handle the change of the program area dropdown
     * and the population of the indicator dropdown based on the programArea
     * @param {number} [id]
     */
    async handleRowInput (id) {
      const valueData = document.getElementById(`value-${id}`).value

      if (isNaN(valueData)) {
        alert('Please enter a number')
        document.getElementById(`value-${id}`).value = ''
        document.getElementById(`value-${id}`).focus()
        return
      }
      const newValueData = parseInt(valueData, 10)
      if (valueData?.length > 0) {
        this.entry.find(el => el.id === id).value = newValueData
      } else {
        document.getElementById(`value-${id}`).value = ''
        document.getElementById(`value-${id}`).focus()
      }
    },
    async handleRowSelect (id) {
      const valueTypeData = document.getElementById(`value_type-${id}`).value
      const valueData = document.getElementById(`value-${id}`).value

      if (valueData?.length > 0 && valueTypeData === '') {
        alert('Please select a value-type for the corresponding indicator')
        document.getElementById(`value_type-${id}`).value = ''
        document.getElementById(`value_type-${id}`).focus()
        return
      }
      document.getElementById(`value-${id}`).focus()
      document.getElementById(`value-${id}`).removeAttribute('disabled')
      document.getElementById(`value-${id}`).setAttribute('required', true)
      this.entry.find(el => el.id === id).value_type = valueTypeData
    },
    async filter (name) {
      const options = await this.getIndicatorOptions.filter(el => el.programArea === name)
      this.newOptions = await options.map(el => {
        return {
          id: el.indicatorName.id,
          name: el.indicatorName.full_name
        }
      })
    }
  },
  async mounted () {
    await this.GET_CLASSIFICATION()
    this.GET_LOCATIONS()
    this.location = await this.getUserDetails.state
    this.GROUP_INDICATORS_BY_PROGRAM_AREA()
    this.SET_YEAR_DROPDOWN()
  }

}
</script>
