<template>
  <b-table
    responsive
    bordered
    :items="dataItem"
    :fields="fields"
    :busy="loading"
    class="shadow-sm pb-1 bg-white"
  >
    <!-- sticky-header="60vh" -->
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <!-- INDICATOR SLOT -->
    <template #cell(indicator)="data">
      <p>{{ data.item[3] }}</p>
    </template>
    <!-- YEAR CELL -->
    <!-- <template #cell(period)="data">
        <p>{{ data.item[4] }}</p>
      </template> -->
    <!-- VALUE CELL -->
    <template #cell(status)="data">
      <p
        class="display"
        :class="data.item[5] === 1 ? 'bg-success' : 'bg-danger'"
      ></p>
    </template>
    <!-- PERFORMANCE CELL -->
    <template #cell(performance)="data">
        <p
          class="display"
          :class="data.item[7] === 1 ? 'bg-success' : 'bg-danger'"
        ></p>
      </template>
        {{ dataItem }}
    <!-- TARGET CELL -->
    <template #cell(target)="data">
        <div class="text-center">
          <p
            v-if="data"
            class="display"
            :class="data.item[6] === 1 ? 'bg-success' : 'bg-danger'"
          ></p>
          <span v-else> - </span>
        </div>
      </template>
  </b-table>
</template>

<script>
export default {
  name: 'ScoreCardTable',
  props: {
    dataItem: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    loading: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    // Function to add the active class to the row
    toggleActive (id) {
      if (id) {
        this.selected = id
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  font-size: 1.05rem;
  text-decoration: underline;
  font-weight: bold;
}
</style>
