<template>
  <div class="container-fluid pt-lg-5 p-md-3">
    <div class="row">
      <div
        class="col-lg-3 col-md-4 col-12 mb-4"
        v-for="(item, i) in data"
        :key="i"
      >
        <div
          :title="item"
          :ref="item"
          class="card card-body shadow-sm score_card h-100 text-center"
          @click.prevent="showIndicators(item)"
        >
          <img
            rel="preload"
            :src="require(`../../../../assets/stateProfiIeIcons/${item}.svg`)"
            width="80"
            class="ml-2 img-fluid py-2"
          />
          <h4>
            <!-- <a
              href="https://ehealth4everyone.com/"
              target="_blank"
              rel="noopener noreferrer"
              > -->
            {{ item }}
            <!-- </a> -->
          </h4>
        </div>
      </div>
    </div>
  </div>
  <!--
    <div class="mx-auto container-fluid">
      <div class="px-lg-5 mt-1">
        <div class="d-flex justify-content-end">
          <router-link to="/dashboard/key-health-indices">
            <button class="btn-lg mr-4 btn-primary btn_contd">
              Continue&nbsp;<span>&#8594;</span>
            </button>
          </router-link>
        </div>
      </div>
    </div> -->
</template>

<script type="js">
import { mapGetters, mapMutations } from 'vuex'
import { uniq } from 'lodash'

export default {
  name: 'ScorecardPage',
  components: {},
  data () {
    return {
      data: [
        'Nutrition Scorecard',
        'SSHIS Scorecard',
        'Health Insurance Scorecard',
        'DMA Scorecard',
        'UHC Scorecard',
        'PHC Challenge Fund Scorecard',
        'RMNCH Scorecard',
        'Joint External Evaluation Scorecard'
      ]
    }
  },
  computed: {
    ...mapGetters({
      scoreCard: 'getStateScoreCard'
    })
  },
  methods: {
    ...mapMutations(['setStateScoreCard']),
    async getData () {
      this.setStateScoreCard()
    },
    // set card without data from setStateScoreCard to inactive
    async setInactiveCard () {
      const newData = []
      this.data.forEach((item) => {
        newData.push(item.split(' ').slice(0, -1).join(' ').toLowerCase())
      })
      // get the 3rd item from scorecard and store it in an array
      const header = this.scoreCard.map((item) => item[2])
      this.data = uniq(header)
    },
    async showIndicators (item) {
      this.$router.push(`/dashboard/scorecard/${item}`)
    }
  },
  mounted () {
    this.getData()
    this.setInactiveCard()
  }
}
</script>

<style>
div.score_card {
  min-height: 15rem;
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 0.5rem;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
  cursor: pointer;
}
div.score_card:hover {
  background-color: #e6e6e6;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
h4 {
  /* text-decoration: underline; */
  color: green;
  font-size: 1.3rem;
  font-weight: 600;
}
ul {
  list-style: none;
  padding-left: 0;
}
ul li {
  align-items: center;
  display: flex;
  min-height: 2rem;
}
p.display {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-bottom: 0;
  margin-left: 0.4rem;
}
strong {
  margin-right: 0.4rem;
}
</style>
