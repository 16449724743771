// import { msdatInstance } from '../config/axios';
// // import Toast from './Toast';

// /**
//  * AXIOS FUNCTION
//  * @param {*} method i.e post, get, patch, delete
//  * @param {*} url i.e BaseURL + Api-url
//  * @param {*} body: formData-Object
//  * @returns response only if status is 'OK'
//  */

// const Request = (url) => new Promise((resolve, reject) => {
//   msdatInstance({
//     method: 'GET',
//     url,
//   })
//     .then((response) => {
//       resolve(response.data);
//     })
//     .catch((err) => {
//       console.log('Error', err);
//     //   Toast('', err.response.data, 'error');
//       reject(err);
//       throw err;
//     });
// });

// export default Request;

import { msdatInstance } from '@/config/axios'

const baseURL = process.env.VUE_APP_API_BASE_API

const Request = (
  url,
  method = 'GET',
  body = null
) => new Promise((resolve, reject) => {
  msdatInstance({
    url: `${baseURL}${url}`,
    method,
    data: body
  })
    .then(
      (response) => {
        resolve(response.data)
      }
    )
    .catch((err) => {
      console.log(err, 'error')
      const data = err.response?.data
      reject(data)
      throw err
    })
})

export default Request
