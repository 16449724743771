<template>
  <div class="container-lg container-fluid">
    <div class="row login_container">
      <div
        class="card card-body bg-white col-md-6 mx-auto p-3 rounded-lg shadow-sm border-0"
      >
        <div class="d-flex align-items-center flex-column pt-3">
          <img
            rel="preload"
            src="@/assets/img/ngf_logo.png"
            width="50%"
            alt="NGF_Logo"
          />
          <div class="my-3 text-center">
            <h2 class="h2 font-weight-bold py-3">ADMIN LOGIN</h2>
            <!-- <p>
              Your Excellency, please login to have access to your personal
              dashboard
            </p> -->
          </div>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form class="pb-3">
            <div class="form-group">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  class="form-control form-control-lg"
                  v-model="user.username"
                  placeholder="Name"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="password"
                  class="form-control form-control-lg"
                  v-model="user.password"
                  placeholder="Password"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <button
              class="btn btn-primary btn-block py-2"
              @click.prevent="login"
              :disabled="invalid || Loading"
            >
              <span
                v-if="Loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ Loading ? "Loading.." : "Login" }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import auth from '@/services/authentication'

export default {
  name: 'AdminLogin',
  data () {
    return {
      user: {
        username: '',
        password: ''
      },
      Loading: false
    }
  },
  methods: {
    async login () {
      this.Loading = true
      try {
        const response = await auth.adminLoginRoutine(this.user)
        if (response.id !== undefined || response.id !== '') {
          this.$router.push('/admin/approval')
          this.Success()
        }
      } catch (error) {
        this.Error()
        console.log(error)
      } finally {
        this.Loading = false
      }
    },
    Success () {
      this.$notify({
        title: 'Success',
        message: `Welcome ${this.user.username}. you logged in as Admin`,
        type: 'success',
        duration: 3000
      })
    },
    Error () {
      this.$notify({
        title: 'Attention!!!',
        message: 'Unable to log, invalid credentials. make sure you are an Admin',
        type: 'warning',
        duration: 3000
      })
    },
    checkUser () {
      auth.getUserInfo().then((response) => {
        console.log(response)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.login_container {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
