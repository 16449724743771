export default {
  chart: {
    type: 'area',
    zoomType: 'xy',
    width: 80,
    height: 100,
    backgroundColor: 'transparent'
  },
  title: {
    text: null
  },
  xAxis: {
    visible: false
  },
  yAxis: {
    visible: false
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  series: [],
  tooltip: {
    formatter () {
      return `<p>data:${this.point.y}</p>`
    },
    enabled: true,
    backgroundColor: '#d4fbeee0',
    borderColor: 'green',
    borderRadius: 10,
    borderWidth: 1
  },
  plotOptions: {
    area: {
      fillColor: '#E8E8E8',
      color: '#2e5d5c',
      startPoint: 4
    }
  }
}
