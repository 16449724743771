<template>
  <div class="about">
    <Carousel :options="options" :slides="2" :interval="4000" :login="false" />

    <section>
      <div class="container-fluid mt-4 mb-2">
        <div class="">
          <h1 class="font-weight-bold h4 mb-4 text-center text-uppercase">
            About
          </h1>
          <p class="mb-2 lead">
            The NGF secretariat has built this resource to enhance collaborative
            performance management and monitoring by the Executive Governors for
            improving health outcomes and service delivery in the country. With
            this resource, Governors have access to a holistic view of key
            health performance indicators to support and improve health
            management decisions in their respective states As the resource
            evolves, the NGF hopes to foster improvements in performance
            management for better health outcomes for the citizens. The NGF
            secretariat welcomes feedback on this platform
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js">
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'AboutPage',
  data () {
    return {
      options: [
        ["WELCOME TO THE GOVERNORS' DASHBOARD", '1'],
        ["WELCOME TO THE GOVERNORS' DASHBOARD", '2']
      ]
    }
  },
  components: {
    Carousel
  }
}
</script>

<style lang="scss" scoped>
div.about {
  button {
    display: none;
  }
}
</style>
