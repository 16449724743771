<template>
  <b-modal id="completed" centered title="" v-model="show">
    <div
      class="container m-auto d-flex flex-column align-items-center p-3 justify-content-center"
    >
      <div
        class="circle rounded-5 d-flex align-items-center justify-content-center mb-3"
      >
        <b-icon icon="check" scale="2" variant="light"></b-icon>
      </div>
      <h3 class="p mb-3">Upload Complete</h3>
      <p>
        Lorem Ipsum donor er mavix savis mon la cap <br />
        Lorem Ipsum donor er mavix savis mon la cap
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CompletedComponent',
  data () {
    return {
      show: false
    }
  }
}
</script>

<style>
.circle {
  height: 50px;
  width: 50px;
  background-color: #2e5d5c;
  border-radius: 50%;
  color: white;
}
.container {
  color: #2e5d5c;
}
</style>
