<template>
  <Highmaps :options="defaultOptions" class="pt-2 pb-3 bg-white" />
</template>

<script>
import Highcharts from 'highcharts'
import loadMap from 'highcharts/modules/map'
import { genComponent } from 'vue-highcharts'

// default map
import NigerianMap from './ng-all.geo.json'

// default map options
import defaultOptions from './defaultOptions'

// map data
import regionsMap from './mapData/regions'

import {
  AkwaIbom,
  Bayelsa,
  CrossRiver,
  Rivers,
  Delta,
  Edo
} from './mapData/south-south'

import { Abia, Ebonyi, Imo, Enugu, Anambra } from './mapData/south-east'

import { Ondo, Ogun, Osun, Lagos, Ekiti, Oyo } from './mapData/south-west'

import {
  Adamawa,
  Bauchi,
  Borno,
  Gombe,
  Taraba,
  Yobe
} from './mapData/north-east'

import {
  Jigawa,
  Kano,
  Katsina,
  Kebbi,
  Sokoto,
  Kaduna,
  Zamfara
} from './mapData/north-west'

import {
  Nasarawa,
  Niger,
  Plateau,
  Kwara,
  Kogi,
  Benue,
  Fct
} from './mapData/north-central'

// load map
loadMap(Highcharts)

export default {
  name: 'BaseMap',
  components: {
    Highmaps: genComponent('Highmaps', Highcharts)
  },
  props: {
    mapObject: {
      type: Object,
      required: true,
      default: () => ({})
    },
    level: {
      type: Number,
      default: 0
    },
    lgaState: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      defaultOptions: { ...defaultOptions },
      lgaMapData: {
        Jigawa,
        Kano,
        Katsina,
        Kebbi,
        Sokoto,
        Kaduna,
        Zamfara,
        Nasarawa,
        Niger,
        Plateau,
        Kwara,
        Kogi,
        Benue,
        Fct,
        Abia,
        Ebonyi,
        Imo,
        Enugu,
        Anambra,
        Ondo,
        Ogun,
        Osun,
        Lagos,
        Ekiti,
        Oyo,
        AkwaIbom,
        Bayelsa,
        CrossRiver,
        Rivers,
        Delta,
        Edo,
        Adamawa,
        Bauchi,
        Borno,
        Gombe,
        Taraba,
        Yobe
      }
    }
  },
  methods: {
    plotMapLevel (level) {
      switch (level) {
        case 1:
          this.defaultOptions.plotOptions.map.mapData = NigerianMap
          break
        case 2:
          this.defaultOptions.plotOptions.map.mapData = regionsMap
          break
        case 3:
          // Extra validation check to counter the space on state name with multiple values
          // eslint-disable-next-line no-case-declarations
          const newLgaState = `${this.lgaState.split(' ')[0]}${this.lgaState
            .split(' ')[1]
            .charAt(0)
            .toUpperCase()}${this.lgaState.split(' ')[1].slice(1)}`
          this.defaultOptions.plotOptions.map.mapData =
            this.lgaMapData[newLgaState || this.lgaState].data
          break
        default:
          this.defaultOptions.plotOptions.map.mapData = NigerianMap
          break
      }
      this.defaultOptions = { ...this.defaultOptions }
    }
  },
  watch: {
    mapObject: {
      handler (newVal) {
        this.defaultOptions = Object.assign(this.defaultOptions, newVal)
      },
      immediate: true,
      deep: true
    },
    level (newVal) {
      this.plotMapLevel(newVal)
    }
  },
  created () {
    this.plotMapLevel(this.level)
  }
}
</script>
