<template>
  <b-form-group class="text-uppercase font-weight-bold" :label="label">
    <b-form-select
      v-model="selected"
      :options="options"
      class="mr-2 px-3 py-1 border w-100"
    >
      <template v-if="options.length <= 0 || options.length == undefined">
        <b-form-select-option :value="null" disabled
          >NO DATA</b-form-select-option
        >
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
import setup from '@/mixins/setup'

export default {
  name: 'SelectInput',
  mixins: [setup],
  data () {
    return {
      //   selected: null,
    }
  },
  computed: {
    selected: {
      get () {
        if (this.label === 'year') {
          return this.options[0]?.value
        }
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    options: {
      type: Array
    },
    label: {
      type: String,
      required: true
    },
    value: {}
  },
  methods: {
    // optionSelected() {
    //   this.$emit('selected:option', this.selected);
    // },
  }
}
</script>

<style lang="scss" scoped></style>
