import Request from '@/utils/Request'
// eslint-disable-next-line no-unused-vars

const actions = {
  /**
   * @Author davebenard
   * @Date   2020-07-07
   * @param  {Object}   payload
   * @return {Promise}
   * @description function to post data from the data entry module to the api endpoint
   */
  async POST_DATA_ENTRY ({ commit }, payload) {
    const response = await Request('dmi/fileuploads/json/', 'POST', payload)
    return response
  },
  // Function to get the scorecard data
  async GET_SCORECARD_DATA ({ commit }) {
    const response = await Request('GET', 'api/scorecard/getAll')
    commit('setScoreCards', response.data)
  },
  /**
   * Group indicators by program area
   * @param {object} rootState
   * @returns {array}
   */
  async GROUP_INDICATORS_BY_PROGRAM_AREA ({ commit, dispatch, rootState }) {
    // STEP 0: Get the ngf config structure
    const { ngf } = rootState.DASHBOARD_STORE
    const result = []
    const result2 = []
    const indicatorId = []
    const indicators = []
    // ============================================================
    // STEP 1: Get the programArea Object and ONLY INDICATOR_ID from the ngf config and push into the result array
    await ngf.forEach((el) => {
      result.push({
        programArea: el.programArea,
        indicator: el.indicator.map((item) => {
          return item.split('-')[0]
        })
      })
    })
    // ============================================================
    // STEP 2: Get just the indicatorId from the result array and push into the indicatorId array
    await result.forEach((el) => {
      indicatorId.push(...el.indicator)
    })
    // ============================================================
    // STEP 3: Use the indicatorId array to get the indicator data from the api
    await Promise.all(
      indicatorId.map(async (el) => {
        const response = await Request(`indicators/${el.split('-')[0]}/`)
        indicators.push(response)
      })
    )
    // ============================================================
    // STEP 4: Match indicator_id with programArea and indicatorName
    result.forEach(async (el) => {
      await el.indicator.forEach((item) => {
        if (indicators.map((el2) => el2.id === Number(item))) {
          result2.push({
            programArea: el.programArea,
            indicatorIds: item,
            indicatorName: indicators.find((el2) => el2.id === Number(item))
          })
        }
      })
    })
    // ============================================================
    // STEP 5: GROUP all indicator_id and name in result2 array by programArea
    const result3 = await result2.reduce((acc, curr) => {
      if (acc[curr.programArea]) {
        acc[curr.programArea].push(curr)
      } else {
        acc[curr.programArea] = [curr]
      }
      return acc
    }, {})

    // ============================================================
    // STEP 6: SORT the result3 array by programArea
    const result4 = await Object.keys(result3).reduce((acc, curr) => {
      acc.push({
        programArea: curr,
        // indicator: result3[curr],
        data: result3[curr].map((el) => el.indicatorName)
      })
      return acc
    }, [])
    // ============================================================
    // STEP 7: Push the result4 array into the state store and return the result4 array
    commit('set_indicator_options', result4)
    return result4
  },

  /**
   * @Author chisomchima
   * @Date   2022-09-12
   * @param  {Object}   payload
   * @return {Promise}
   * @description 4 functions to get, approve, reject and get data entries by id from the data entry module and for data entry approval
   *
   */

  async GET_DATA_ENTRIES ({ commit }) {
    try {
      const response = await Request('dmi/fileuploads?approved=false&application=NGF', 'GET')
      Promise.resolve(response)
      commit('set_dataentry', response.results)
    } catch (err) {
      console.log(err)
    }
  },

  async APPROVE_DATA_ENTRIES ({ commit }, payload) {
    const { id, comment } = payload
    try {
      const response = await Request(`dmi/fileuploads/${id}/approve/`, 'POST', comment)
      Promise.resolve(response)
      commit('set_dataentry', response.results)
    } catch (err) {
      console.log(err)
    }
  },
  async REJECT_DATA_ENTRIES ({ commit }, payload) {
    const { id, comment } = payload
    try {
      const response = await Request(`dmi/fileuploads/${id}/reject/`, 'POST', comment)
      // console.log(response, 'alldataentries')
      Promise.resolve(response)
      commit('set_dataentry', response.results)
    } catch (err) {
      console.log(err)
    }
  },
  async GET_DATAENTRIES_BY_ID ({ commit }, payload) {
    const result = []
    try {
      const response = await Request(
        `dmi/fileuploads/${payload}/get_data`,
        'GET'
      )
      // console.log(response)
      // ============================================================
      // STEP 1 :  getting indicators, location and datasource data and return the result 3 array
      // getting indicators, location and datasource data
      await Promise.all(
        response?.map(async (el) => {
          const { ...details } = el
          if (el?.indicator !== undefined && el?.location !== undefined && el?.datasource !== undefined) {
            const indicatorData = await Request(`indicators/${el?.indicator}/`)
            const locationData = await Request(`location/${el.location}/`)
            const datasourceData = await Request(`datasources/${el.datasource}/`)
            // ============================================================
            // STEP 2 :  getting indicators, location and datasource data and return the result 3 array
            // creating a new object with results gotten from the above funtion
            // creating a new object with results gotten from the above funtion
            result.push({
              msg: 'Data Available',
              indicatorName: indicatorData.full_name,
              locationName: locationData.name,
              datasourceName: datasourceData.datasource,
              ...details
            })
            console.log(result)
          } else {
            // console.log('hi chisom')
            // eslint-disable-next-line no-const-assign
            result.push({
              msg: 'No Data Available'
            })
          }
        })
      )
      return result
    } catch (err) {
      console.log(err)
    }
  },

  /**
   * @Author chisomchima
   * @Date   2022-09-12
   * @param  {Object}   payload
   * @return {Promise}
   * @description this function is used to get all classifications to fpopulate the value types in the admin page
   *
   */

  async GET_CLASSIFICATION ({ commit }) {
    try {
      const response = await Request('valuetypes/', 'GET')
      const valueType = [...new Set(response.results.map((el) => ({
        id: el.id,
        value_type: el.value_type
      })))]
      Promise.resolve(valueType)
      commit('set_classification', valueType)
    } catch (err) {
      console.log(err)
    }
  },

  /**
   * @Author chisomchima
   * @Date   2022-09-12
   * @param  {Object}   payload
   * @return {Promise}
   * @description these functions gets all governors, a single governor and updates a governors profile
   *
   */

  async GET_GOVERNORS ({ commit }) {
    try {
      const response = await Request('dmi/viewer/', 'GET')
      Promise.resolve(response)
      commit('set_governors', response)
      return response
    } catch (err) {
      console.log(err)
    }
  },
  async GET_SINGLE_GOVERNOR ({ commit }, payload) {
    try {
      const response = await Request(`dmi/viewer/${payload}`, 'GET')
      Promise.resolve(response)
      commit('set_single_governor', response)
    } catch (err) {
      console.log(err)
    }
  },
  async UPDATE_SINGLE_GOVERNOR ({ commit }, payload) {
    const { id, govDetail } = payload
    try {
      const response = await Request(`dmi/viewer/${id}/`, 'PATCH', govDetail)
      Promise.resolve(response)
      commit('set_single_governor', response)
    } catch (err) {
      console.log(err)
    }
  }
}

export default actions
