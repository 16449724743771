<template>
  <div class="row bg-secondary align-items-center pt-2 w-100 mx-0">
    <div class="col-md-6 col-12 text-white mb-3">
      <div class="d-flex profile justify-content-start align-items-center">
        <!-- <i class="fa fa-user profile__avatar"></i> -->
        <img
          rel="preload"
          :src="
            require(`@/assets/img/governors/${user.state.toLowerCase()}.jpeg`)
          "
          alt="Governors Image"
          class="rounded-circle img-fluid"
          style="width: 87px; height: 85px; border: 0.9px solid"
        />
        <div class="flex-column ml-2">
          <h5 class="h5 font-weight-bold">
            His Excellency <span class="text-capitalize"> {{ name }} </span>
          </h5>
          <h6 class="h6 text-capitalize">
            Governor of {{ user.state.toLowerCase() }} State
          </h6>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-end">
      <!-- ===== Continue Button ===== -->
      <div v-if="searchType === 'button'">
        <router-link to="/dashboard/detailed_Indices/scorecard"
          ><button class="btn btn-light">Continue</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash'

export default {
  name: 'ProfileSection',
  props: {
    searchType: {
      type: String
    }
  },
  data () {
    return {
      currentRoute: 'State',
      search: this.searchType
    }
  },
  computed: {
    name () {
      return capitalize(this.user.name)
    },
    user () {
      return this.$store.state.user
    },
    imagesUrl () {
      //   return require('@/assets/img/arrow-up.svg');
      return '/img/governors/delta.jpg'
    }
  },
  watch: {
    // currentRoute(newValue) {
    //   this.$router.push({ path: newValue });
    // },
    currentRoute (value) {
      switch (value) {
        case 'State':
          this.$router.push({ name: 'State' })
          // this.$emit('card-change', 'state');
          break
        case 'Zonal':
          this.$router.push({ name: 'Zonal' })
          // this.$emit('card-change', 'zonal');
          break
        case 'StateComparison':
          this.$router.push({ name: 'StateComparison' })
          break
        default:
          break
      }
    }
  }
}
</script>

//
<style lang="scss" scoped>
// .h5,
// h6 {
//   font-size: 0.9rem;
// }
//
</style>
