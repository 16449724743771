import {
  uniq, map, trim, compact, capitalize, sortBy
} from 'lodash'
import moment from 'moment'

export default {
  data () {
    return {
      dataSource: 'NHMIS',
      dataSources: [],
      programAreaData: [],
      programAreas: [],
      programArea: 'PHCUOR',
      indicators: [],
      indicator: 'all',
      years: [],
      year: '',
      yearlyData: [],
      months: [],
      month: '',
      monthlyData: [],
      lgaYearlyData: [],
      lgaMonthlyData: [],
      stateYearData: [],
      stateProfile: [],
      currentStateProfile: [],
      stateMonthData: [],
      availableYears: [],
      availableMonths: [],
      program_areas: ['PHCUOR'],
      indicatorLabel: 'Indicators',
      dataSourceLabel: 'Data Sources',
      programAreaLabel: 'Program Areas',
      demographyData: [],
      fields: [
        // {
        //   key: 'program_area',
        //   sortable: false,
        // },
        {
          key: 'indicator',
          sortable: false
        }
      ],
      zones_dropdown: [
        'South South',
        'South East',
        'South West',
        'North Central',
        'North East',
        'North West'
      ],
      zone: 'South East',
      zones: {
        'South South': ['Akwa-Ibom', 'Bayelsa', 'Cross River', 'Delta', 'Edo', 'Rivers'],
        'South East': ['Abia', 'Anambra', 'Ebonyi', 'Enugu', 'Imo'],
        'South West': ['Ekiti', 'Lagos', 'Ogun', 'Ondo', 'Osun', 'Oyo'],
        'North Central': [
          'Niger',
          'Kogi',
          'Benue',
          'Plateau',
          'Nasarawa',
          'Kwara',
          'Federal Capital Territory'
        ],
        'North East': ['Bauchi', 'Borno', 'Taraba', 'Adamawa', 'Gombe', 'Yobe'],
        'North West': ['Zamfara', 'Sokoto', 'Kaduna', 'Kebbi', 'Katsina', 'Kano', 'Jigawa']
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    stateName () {
      return capitalize(this.$store.state.user.state)
    }
  },
  methods: {
    getYearlyData () {
      return this.programAreaData
    },
    getMonthlyData () {
      // console.log(this.programAreaData);
    },
    getProgramAreasAndTheirIndicators () {
      const programAreas = this.getProgramAreas()
      const bulk = programAreas.map((element) => {
        const indictor = this.getProgramAndIndicator(element)
        const programAreaAndIndicator = {
          programArea: element,
          indicators: indictor
        }
        return programAreaAndIndicator
      })
      return bulk
    },
    /**
     *
     * @param {*} index
     * @param {*} year
     * @returns indicator index
     */
    getYearlydataNumber (index, year) {
      let val = 0
      this.stateYearData.forEach((data) => {
        if (data[0] === Number(year)) {
          val = data[index]
        }
      })
      return Number(val)
    },
    /**
     *
     * @param {*} index
     * @param {*} year
     * @param {*} month
     * @returns
     */
    getMonthlydataNumber (index, year, month) {
      let val = 0
      this.stateMonthData.forEach((data) => {
        const Name = moment(data[0], 'YYYY/MM/DD')
        const yearValue = Name.format('YYYY')
        const monthName = Name.format('MMMM')
        if (yearValue === year && monthName === month) {
          val = data[index]
        }
      })
      return Number(val)
    },
    getDataSources () {
      const data = this.yearlyData[0]
      const datasources = data.map((item) => item.split('->')[1])
      return map(compact(uniq(datasources)), trim)
    },
    getIndicators () {
      const data = this.yearlyData[0]
      const indicators = data.map((item) => item.split('->')[0])
      indicators.splice(0, 2)
      return map(compact(uniq(indicators)), trim)
    },
    getMonthlyIndicators () {
      const data = this.monthlyData[0]
      const indicators = data.map((item) => item.split('->')[0])
      indicators.splice(0, 2)
      return map(compact(uniq(indicators)), trim)
    },
    // Function to list all Local Government Areas
    getLga () {
      const state = capitalize(this.$store.state.user.state)
      const dataArr = []
      this.lgaYearlyData.forEach((el) => {
        const item = uniq(el)
        if (item[1] === state) {
          dataArr.push(item[2])
        }
      })
      return compact(uniq(dataArr))
    },
    // Function to get all indicators
    /**
     *
     * @param {indicator, state, year}
     * return and array of Lga and Data if it exist
     * @returns {array}
     *
     */
    getLgaIndicators () {
      const data = this.lgaYearlyData[0]
      const dataItem = data.map((item) => trim(item.split('->')[0]))
      dataItem.splice(0, 3)
      return dataItem
    },
    getLgaByIndicator (indicator, value) {
      const year = value === undefined ? this.year : value
      const data = this.lgaYearlyData[0]
      const dataItem = data.map((item) => trim(item.split('->')[0]))
      dataItem.splice(0, 3)
      const dataArr = []

      dataItem.forEach((item) => {
        if (item === indicator) {
          const index = dataItem.indexOf(item) + 3
          this.lgaYearlyData.forEach((el) => {
            if (el[1] === this.state && el[0] === year) {
              const element = trim(el[2].replace(/LGA/g, ''))
              dataArr.push([element, el[index]])
            }
          })
        } else {
          dataArr.push()
        }
      })
      return uniq(dataArr)
    },
    getLgaByIndicatorYearMonth (indicator, value, value2) {
      const year = value === undefined || value === null ? this.year : value
      const month = value2 === undefined || value === null ? this.month : value2
      const data = this.lgaMonthlyData[0]
      const dataItem = data.map((item) => trim(item.split('->')[0]))
      dataItem.splice(0, 3)
      const dataArr = []

      dataItem.forEach((item) => {
        if (item === indicator) {
          const index = dataItem.indexOf(item) + 3
          const found = this.lgaMonthlyData.filter((el) => el[1] === this.state)
          const foundd = found.map((el) => el)
          foundd.forEach((el) => {
            const Name = moment(el[0], 'YYYY/MM/DD')
            const yearValue = Name.format('YYYY')
            const monthName = Name.format('MMMM')
            if (Number(yearValue) === year && monthName === month) {
              const element = trim(el[2].replace(/LGA/g, ''))
              dataArr.push([element, el[index]])
            }
          })
        } else {
          dataArr.push()
        }
      })
      return map(compact(uniq(dataArr)))
    },
    // getStateInfo(state) {
    //   const data = this.$store.state.stateInfo;
    //   const stateData = [];
    //   data.map((location) => {
    //     return location[1] === _.capitalize(state)
    //       ? stateData.push(...location) : 'no match';
    //   });
    //   return stateData;
    // },

    /**
     *
     * @param {*} programArea
     * @description
     * @description
     * return and array of the indicator and there DataSource
     * @returns {array}
     * @example
     * [ANC Coverage (1 Visit) -> NHMIS,...]
     */
    getIndicatorsAndDataSourceByProgramArea (programArea) {
      const os = this.getProgramAndIndicator(programArea)
      const arrayTitle = this.yearlyData[0]
      const listOfIndicatorSource = []
      os.forEach((item) => {
        const indexx = arrayTitle.find((element) => element.includes(item))
        listOfIndicatorSource.push(indexx)
      })
      return listOfIndicatorSource
    },
    /**
     *
     * @returns the years with available data
     */
    getYears () {
      let indicatorsAndSource = []
      if (this.indicator === 'all') {
        indicatorsAndSource = this.getIndicatorsAndDataSourceByProgramArea(this.programArea)
      } else {
        indicatorsAndSource = [`${this.indicator} -> ${this.dataSource}`]
      }
      const allYears = []
      indicatorsAndSource.forEach((elem) => {
        const index = this.yearlyData[0].indexOf(elem)
        this.stateYearData.forEach((data) => {
          if (data[index] !== '') {
            allYears.push(data[0])
          }
        })
      })
      return compact(uniq(allYears))
    },

    /**
     * getMaxYearForEachIndicator
     * * method returns max year for each indicator
     * @param {*} indicator
     * @param {*} datasource
     * @returns max year for each indicator
     */
    getMaxYearForEachIndicator (indicator, datasource) {
      const allYears = []
      let indicatorsAndSource = []
      indicatorsAndSource = [`${indicator} -> ${datasource}`]
      indicatorsAndSource.forEach((elem) => {
        const index = this.yearlyData[0].indexOf(elem)
        this.stateYearData.forEach((data) => {
          if (data[index] !== '') {
            allYears.push(data[0])
          }
        })
      })
      // return Math.max(...compact(uniq(allYears)));
      return compact(uniq(allYears))
    },
    /**
     *
     * @returns the months with available data
     *
     * */
    getMonths () {
      let indicatorsAndSource = []
      if (this.indicator === 'all') {
        indicatorsAndSource = this.getIndicatorsAndDataSourceByProgramArea(this.programArea)
      } else {
        indicatorsAndSource = [`${this.indicator} -> ${this.dataSource}`]
      }
      const allMonths = []
      indicatorsAndSource.forEach((elem) => {
        const index = this.monthlyData[0].indexOf(elem)
        this.stateMonthData.forEach((data) => {
          if (data[index] !== '') {
            const datar = data[0]
            const convert = moment(datar, 'YYYY/MM/DD')
            const monthName = convert.format('MMMM')
            allMonths.push(monthName)
          }
        })
      })
      // Function to swap the first and last item in the array
      function swap (el) {
        const elem = el;
        [elem[0], elem[elem.length]] = [elem[elem.length], elem[0]]
        return elem
      }
      return compact(uniq(swap(allMonths)))
    },
    getProgramAreas () {
      const data = this.programAreaData
      const programArea = data.map((item) => item[1])
      programArea.splice(0, 1)
      // PROGRAM-AREA ARE DISPLAYED AS
      // ARRANGED ON THE NGF EB DEV SHEET without alphabetical order sorting
      // programArea.sort((a, b) => a.localeCompare(b));
      return map(compact(uniq(programArea)), trim)
    },
    getProgramAndIndicator (program) {
      const data = this.programAreaData
      const programArea = data.map((item) => {
        if (item[1] === program) {
          return item[0]
        }
        return ''
      })
      return map(compact(uniq(programArea)), trim)
    },
    getIndicatorProgramArea (indicator) {
      const data = this.programAreaData
      const indicators = data.filter((item) => item[0] === indicator)
      return trim(indicators[0][1])
    },
    getProgramAreaSource (programArea) {
      //  let sources =  this.getDataSources();
      const indicator = this.getProgramAndIndicator(programArea)
      const sources = this.getDataSources()
      for (let index = 0; index < sources.length; index += 1) {
        const element = sources[index]
        if (this.checkIndicatorSource(indicator[0], element)) {
          this.dataSource = trim(element)
          break
        }
      }
    },
    checkIndicatorSource (indicator, source) {
      const indicatorsAndSource = `${indicator} -> ${trim(source)}`
      const index = this.yearlyData[0].indexOf(indicatorsAndSource)
      if (index !== -1) {
        return true
      }
      return false
    },
    convertToSelectOptionFormat (arrayObject, addAll, name) {
      const newArray = []
      if (addAll) {
        newArray.push({
          value: 'all',
          text: `All ${name}`
        })
      }
      arrayObject.forEach((item) => {
        newArray.push({
          value: item,
          text: item
        })
      })

      return newArray
    },

    titleFieldSetup (states) {
      this.fields = [
        // {
        //   key: 'program_area',
        //   sortable: false,
        //   thClass: 'bg-secondary text-white',
        // },
        {
          key: 'indicator',
          sortable: false,
          thClass: 'bg-secondary text-white',
          // thStyle: 'width:160px',
          stickyColumn: true
        }
      ]
      /**
       * This is to sort state data in alphabetical order
       */
      const orderedStates = sortBy(states)

      if (orderedStates.length > 2) {
        orderedStates.forEach((item) => {
          this.fields.push({
            key: item,
            thClass: 'bg-secondary text-white'
          })
        })
      } else {
        orderedStates.forEach((item) => {
          this.fields.push({
            key: item,
            thClass: 'bg-secondary text-white',
            label: 'Status'
          })
        })
      }
    },
    /**
     * @param {*} indicator
     * @description
     * return and array of the indicator Meta Data
     * @returns {array}
     *
     */
    getIndicatorMetaData (indicator) {
      const metaData = this.indicatorMetaData
      const data = metaData.filter((elem) => elem[0] === indicator)
      return data[0]
    },
    /**
     *
     * @param {string} indicator
     * @returns {number}
     * @description
     * the returns the national target for an indicator
     */
    getIndicatorTarget (indicator) {
      const data = this.getIndicatorMetaData(indicator)
      if (data === undefined || data === '') {
        return ''
      }
      return data[7]
    },
    /**
     * The is returns the Display Factor For indicators
     * @param {string} indicator
     */
    getDisplayFactor (indicator) {
      const metaData = this.indicatorDisplayFactor
      // const metaData = this.indicatorMetaData;
      const data = metaData.filter((elem) => elem[0] === indicator)
      let factor = {}
      if (data[0]) {
        const displayFactor = data[0][1]
        switch (displayFactor) {
          case 'Percentage':
            factor = {
              type: 'percentage',
              sign: '%'
            }
            break
          case 'Number':
            factor = {
              type: 'number',
              sign: '#'
            }
            break
          case 'Currency':
            factor = {
              type: 'currency',
              sign: '₦'
            }
            break
          default:
            factor = {
              type: `${displayFactor}`,
              sign: `${displayFactor}`
            }
            break
        }
      } else {
        return false
      }
      return factor
    },

    /**
     * The is returns the National Target For indicators
     * @param {string} indicator
     */
    getNationalTarget (indicator) {
      const metaData = this.indicatorDisplayFactor
      const data = metaData.filter((elem) => elem[0] === indicator)
      if (data[0]) {
        if (data[0][2].length === 0) {
          return 0
        }
        return data[0][2]
      }
      return null
    },

    /**
     * The is returns the  Indicators Definition
     * @param {string} indicator
     */
    getDefinition (indicator) {
      const metaData = this.indicatorMetaDataLong
      const data = metaData.filter((elem) => elem[2] === trim(indicator))
      if (data.length) {
        return data[0][8]
      }
      return null
    },

    /**
     * This  returns the Indicator For MetaData
     * @param {string} indicator
     */
    getIndicatorMetaDataLong (indicator) {
      const metaData = this.indicatorMetaDataLong
      const data = metaData.filter((elem) => elem[2] === indicator)
      return data[0]
    },

    /**
     * This  returns True has indicator has monthly data
     * @return Boolean
     */
    checkMonthlyDataIsAvailable (indicator, source) {
      const { monthlyData } = this
      const restructureData = `${indicator} -> ${source}`
      const value = monthlyData[0].find((element) => element === restructureData)
      if (value) {
        return true
      }
      return false
    },
    /**
     * This  returns the MetaData Header
     */
    getIndicatorMetaDataHeaders () {
      const headers = [...this.indicatorMetaDataLong[0]]
      return headers
    },
    /**
     * @param {string}:stateName
     * @return {Array}: returns an array containing the demographics data per year
     */
    getDemographyData () {
      const data = []
      const state = this.stateName.split(' ').join(' ').toLowerCase()
      for (let i = 0; i < this.demographyData.length; i += 1) {
        if (this.demographyData[i][0].toLowerCase() === state) {
          data.push(this.demographyData[i])
        }
      }
      return data
    },
    /**
     * @param {string}:stateName
     * @return {Array}: returns an array containing the demographics data per year
     */
    getFacilityData () {
      const data = []
      for (let i = 0; i < this.facilityData.length; i += 1) {
        if (this.facilityData[i][0].toLowerCase() === this.stateName.split(' ').join(' ').toLowerCase()) {
          data.push(this.facilityData[i])
        }
      }
      return data
    },
    /**
     * This method setup the db and initializes
     */
    initializeDashboard () {
      this.yearlyData = this.$store.state.yearlyData
      // this.monthlyData = this.$store.state.monthlyData
      // this.programAreaData = this.$store.state.programArea
      // this.indicatorDisplayFactor = this.$store.state.indicatorDisplayFactorData
      // this.indicatorMetaData = this.$store.state.indicatorMeta
      // this.indicatorMetaDataLong = this.$store.state.indicatorMetaLong
      // this.lgaYearlyData = this.$store.state.lgaYearlyData
      // this.lgaMonthlyData = this.$store.state.lgaMonthlyData
      this.stateProfile = this.$store.state.stateProfile
      this.state = capitalize(this.$store.state.user.state)
      this.demographyData = this.$store.state.demography
      this.facilityData = this.$store.state.healthFacility
      this.keyHealthIndicesData = this.$store.state.keyHealthIndices

      this.dataSources = this.convertToSelectOptionFormat(this.getDataSources(), false)
      // this.dataSources = this.getDataSources();
      this.indicators = this.convertToSelectOptionFormat(
        this.getProgramAndIndicator(this.programArea),
        true,
        this.indicatorLabel
      )

      this.programAreas = this.getProgramAreas()
      this.stateYearData = this.yearlyData.filter((item) => item[1].toLowerCase() === this.stateName.split(' ').join(' ').toLowerCase())
      this.stateMonthData = this.monthlyData.filter((item) => item[1].toLowerCase() === this.stateName.split(' ').join(' ').toLowerCase())
      this.currentStateProfile = this.stateProfile.filter((item) => item[1].toLowerCase() === this.stateName.split(' ').join(' ').toLowerCase())
      this.availableYears = sortBy(this.getYears())
      this.year = Math.max(...this.availableYears)
      this.availableMonths = this.getMonths()

      const date = new Date()
      const monthName = moment(date, 'YYYY/MM/DD')
      this.month = monthName.format('MMMM')
    }
  },
  watch: {
    indicator (newValue) {
      this.availableYears = sortBy(this.getYears())
      this.year = Math.max(...this.availableYears)
      if (newValue !== 'all') {
        this.programArea = this.getIndicatorProgramArea(newValue)
      }
      this.getData()
    },
    zone () {
      this.getData()
    },
    programArea (newValue) {
      this.availableYears = sortBy(this.getYears())
      this.year = Math.max(...this.availableYears)
      this.indicators = this.convertToSelectOptionFormat(
        this.getProgramAndIndicator(newValue),
        true,
        this.indicatorLabel
      )
      if (this.indicator === 'all') {
        this.getData()
      }
      this.indicator = 'all'
    }
  },
  mounted () {
    this.initializeDashboard()
  }
}
