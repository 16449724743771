<template>
  <div>
    <!-- <div class="font-italic mt-4 mx-5 row"> //removed 'mt-4' space was more than specified-->
    <div class="font-italic mt-2 row">
      <div class="d-none col-md-6">
        <!-- <p>Indicator distribution across {{this.stateName}} State</p> -->
      </div>
      <div class="col-md-6 mx-auto">
        <div class="d-flex justify-content-center">
          <small><strong>Understand dashboard legend</strong></small>
          <LegendTooltip />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setup from '@/mixins/setup'
import LegendTooltip from '@/components/LegendTooltip.vue'

export default {
  mixins: [setup],
  components: {
    LegendTooltip
  }
}
</script>

<style lang="scss" scoped></style>
