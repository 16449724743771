<template>
  <div class="bg-secondary">
    <div
      v-if="stateValue"
      class="d-flex flex-lg-row flex-column justify-content-between align-items-center py-lg-1"
    >
      <div class="flex-fill">
        <div class="d-flex profile text-white">
          <img
            rel="preload"
            :src="
              require(`@/assets/img/governors/${user.state.toLowerCase()}.jpeg`)
            "
            alt="Governors Image"
            class="rounded-circle"
            style="width: 87px; height: 85px; border: 0.9px solid"
          />
          <div class="d-flex flex-column py-3 mx-2">
            <h5 class="h5 font-weight-bold">
              His Excellency <span class="text-capitalize"> {{ name }} </span>
            </h5>
            <h6 class="h6 text-capitalize">
              Governor of {{ user.state.toLowerCase() }} State
            </h6>
          </div>
        </div>
      </div>

      <div class="mx-lg-2">
        <StateProfileSectionCard
          :imageLink="require(`@/assets/img/gov-svg/created.svg`)"
          :indicator="'Created'"
          :value="formatDate(cardValues.established_date)"
        />
      </div>

      <div class="mx-lg-2">
        <StateProfileSectionCard
          :imageLink="require(`@/assets/img/gov-svg/landmass.svg`)"
          :indicator="'Landmass'"
          :value="cardValues.land_mass + 'km²'"
        />
      </div>

      <div class="mx-lg-2">
        <StateProfileSectionCard
          :imageLink="require(`@/assets/img/gov-svg/population.svg`)"
          :indicator="'Population'"
          :value="cardValues.population"
        />
      </div>

      <div class="mx-lg-2">
        <StateProfileSectionCard
          :imageLink="require(`@/assets/img/gov-svg/lga.svg`)"
          :indicator="'LGAs'"
          :value="cardValues.lgas"
        />
      </div>

      <div class="mx-lg-2">
        <StateProfileSectionCard
          :imageLink="require(`@/assets/img/gov-svg/political-ward.svg`)"
          :indicator="'Political Wards'"
          :value="cardValues.political_wards"
        />
      </div>

      <!-- <div
        class="mx-lg-3 date-created state__box text-white rounded d-flex text-center p-2"
      >
        <p>
          LGAs <br />
          17
        </p>
      </div> -->
      <!-- <div
        class="mx-lg-3 date-created state__box text-white rounded d-flex text-center p-2"
      >
        <p>
          Political Wards <br />
          184
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { trim, capitalize } from 'lodash'
import StateProfileSectionCard from '@/components/StateProfileSectionCard.vue'
import { formatToCurrency, formatToMoney } from '@/utils/index'
import setup from '@/mixins/setup'

export default {
  name: 'StateProfileSection',
  mixins: [setup],
  props: {
    propdata: {
      // type: Array,
      // default: () => []
      type: [Array, Object],
      required: true
      // default: () => {}
    }
  },
  components: {
    StateProfileSectionCard
  },
  data () {
    return {
      currentRoute: 'State',
      stateInfoData: 'value',
      stateValue: null,
      cardValues: {}
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getStateValue']),
    name () {
      return capitalize(this.user.name)
    },
    user () {
      return this.$store.state.user
    },
    imagesUrl () {
      return '/img/governors/delta.jpg'
    },
    forBudget () {
      if (this.stateValue[4].value) {
        return formatToCurrency(this.stateValue[4].value)
      }
      return '-'
    },
    formatValue (value) {
      if (value !== '' && value !== undefined) {
        return formatToMoney(value)
      }
      return '-'
    }
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['GET_STATE_DATA']),
    getStateInfoIndicator (data) {
      const indicators = data[0].map((item) => item)
      indicators.splice(0, 1)
      return trim(indicators)
    },
    getIndicatorAndIndex (data) {
      const indicators = data[0].map((item, index) => ({
        item,
        index
      }))
      indicators.splice(0, 1)
      return indicators
    },
    getStateData (data) {
      const stateName = this.stateName.toLowerCase()
      return data.filter((item) => item[1].toLowerCase() === stateName)
    },
    getValues () {
      const data = this.getStateData(this.stateInfoData)[0]
      const indicatorsAndIndex = this.getIndicatorAndIndex(this.stateInfoData)
      const values = []
      indicatorsAndIndex.forEach((item) => {
        values.push({
          indicator: item.item,
          value: data[item.index]
        })
      })
      return values
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day}-${month}-${year}`
    }
  },

  watch: {
    // currentRoute(newValue) {
    //   this.$router.push({ path: newValue });
    // },
    currentRoute (value) {
      switch (value) {
        case 'State':
          this.$router.push({ name: 'State' })
          // this.$emit('card-change', 'state');
          break
        case 'Zonal':
          this.$router.push({ name: 'Zonal' })
          // this.$emit('card-change', 'zonal');
          break
        case 'StateComparison':
          this.$router.push({ name: 'StateComparison' })
          break
        default:
          break
      }
    }
  },
  async mounted () {
    const data = {
      id: 8,
      state: 'abia'
    }
    await this.GET_STATE_DATA(data)
    this.stateInfoData = this.$store.state.stateInfo
    this.stateValue = this.getValues()
    this.cardValues = this.getStateValue.filter(
      (item) => item.name === this.stateName.toLowerCase()
    )[0]
  }
}
</script>

<style lang="scss" scoped>
.date-created {
  color: #fff;
  background-color: #4e7675;
  height: 54px;
  font-size: 13px;
}

// .date-created p {
//   margin-left: 12px;
// }
</style>
