<template>
  <div>
    <main class="container mb-5">
      <div class="faq_img_container">
        <img rel="preload"  :src="headerImg" alt="frequently_asked_question" class="img-fluid" />
        <div class="content container">
          <h3 class="font-weight-bold mb-0">How to use the dashboard</h3>
          <small>Nigeria Governors Forum-Dashboard</small>
          <div class="form-group w-50 mx-auto mt-5">
            <input
              type="text"
              class="form-control"
              aria-label="Search"
              aria-describedby="basic-addon1"
              v-model="searchKey"
              placeholder="Search Help by typing a keyword..."
            />
          </div>
        </div>
      </div>

      <section
        class="card-body faq-card"
        id="faq_accordion"
        v-if="filterText.length !== 0 && searchKey !== ''"
      >
        <div class="accordion" role="tablist">
          <b-card no-body class="border-0" v-for="(data, index) in filterText" :key="index">
            <b-card-header header-tag="header" class="pb-0 px-0 bg-transparent border-0" role="tab">
              <b-button
                @click="data.show = !data.show"
                block
                v-b-toggle="`accordion-${index}`"
                class="bg-header py-2 pb-3 rounded-0 text-left"
                >{{ data.title
                }}<img rel="preload"
                  :src="data.show ? caretUp : caretDown"
                  alt="caret"
                  medium
                  style="float:right;color:white;cursor:pointer;"
              /></b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${index}`" accordion="my-accordion" role="tabpanel">
              <b-card-body class="border-1 mb-1 shadow-sm">
                <ul v-if="data.steps">
                  <li v-for="(item, index) in data.steps" :key="index">{{ item }}</li>
                </ul>
                <p v-if="data.step">{{ data.step }}</p>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </section>

      <Accordion :data="Faq" v-if="searchKey === ''" />
    </main>
  </div>
</template>

<script>
import searchIcon from '@/assets/img/searchIcon.svg'
import caretUp from '@/assets/img/caretUp.svg'
import caretDown from '@/assets/img/caretDown.svg'
import headerImg from '@/assets/img/FAQ_Asset.png'
import Accordion from '@/components/Accordion.vue'
import Faq from '@/assets/js/Faq'

export default {
  name: 'FaqPage',
  components: {
    Accordion
  },
  data () {
    return {
      searchIcon,
      caretUp,
      caretDown,
      headerImg,
      Faq,
      searchKey: ''
    }
  },
  computed: {
    filterText () {
      return this.Faq.filter((item) => item.title.toLowerCase()
        .includes(this.searchKey.toLowerCase()))
    }
  }
}
</script>
<style lang="scss" scoped>
div.faq_img_container {
  min-height: 35vh;
  position: relative;
  div.content {
    text-align: center;
    position: absolute;
    top: 9rem;
  }
  div.form-group {
    border-radius: 0;
    border: 0;
    box-shadow: 0px 5px 10px #87aeae7a;
  }
  input.form-control {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    padding: 1.5rem;
    &:active,
    &:focus {
      box-shadow: none;
      border: 0;
    }
  }
}
// ===============================
section#faq_accordion {
  font-family: "Work Sans";
  font-size: 15px;
  button.btn.bg-header {
    background-color: #007d53e1;
    color: #ffffff;
    font-weight: 500;
    box-shadow: none;
    font-size: 17px;
    border: 0;
    border-bottom: 1.5px solid #00000015;
    text-transform: capitalize;
  }
}
.faq-card {
  padding: 0;
  border-radius: 0.3rem;
  ul {
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0;
    border-radius: 0.3rem;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    padding-left: 1rem;
    li {
      margin-bottom: 0.8rem;
      font-size: 1rem;
    }
  }
}
</style>
