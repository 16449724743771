import index from '../views/index.vue'
import Approval from '../views/Approval.vue'
import DetailsUpdate from '../views/DetailsUpdate.vue'
import AdminLogin from '../views/Login.vue'

export default [
  {
    path: '/admin',
    component: index,
    children: [
      {
        path: '/approval',
        alias: 'approval',
        name: 'Approval',
        component: Approval,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/update-details',
        alias: 'update-details',
        name: 'update-details',
        component: DetailsUpdate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/admin-login',
        alias: 'admin-login',
        name: 'admin-login',
        component: AdminLogin
      }
    ]
  }
]
