<template>
  <div>
    <b-modal
      id="data-entry"
      centered
      title="Data Entry"
      scrollable
      size="lg"
      v-model="show"
    >
      <div class="mb-3 contain">
        <div class="d-flex flex-column">
          <h5>Year</h5>
          <p class="text-primary">{{ header[0] }}</p>
        </div>
        <div class="d-flex flex-column">
          <h5>Location</h5>
          <p class="text-primary">{{ header[1] }}</p>
        </div>
        <div class="d-flex flex-column">
          <h5>DataSource</h5>
          <p class="text-primary">{{ header[2] }}</p>
        </div>
      </div>
      <div class="contain-2">
        <b-table hover :items="tableData"></b-table>
      </div>
     <div class="d-flex w-100 justify-content-end">
       <button class="btn btn-danger mr-3 mt-3" @click.prevent="cancel">
        Cancel
      </button>
      <button class="btn btn-primary mt-3" @click.prevent="proceed">
        Proceed
      </button>
     </div>
    </b-modal>
  </div>
</template>

<script lang="js">
export default {
  name: 'DataTable',
  props: {
    body: {
      type: Array,
      required: true
    },
    header: {
      type: Array,
      required: true
    },
    show1: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    tableData () {
      // loop through thw body and return indicatorName and value
      return this.body.map(item => {
        return {
          indicator: item.indicatorName,
          value: item.value
          // classification: item.value_type
        }
      })
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    proceed () {
      this.show = false
      this.$emit('proceed')
    },
    cancel () {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.contain {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contain-2 {
  height: 50vh;
  overflow-y: auto;
}
</style>
