<template>
  <div>
    <GovernorsProfle :searchType="''" />
    <div class="container-fluid px-lg-5">
      <div class="text-right mb-2">
        <GenericToolTip />
      </div>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card>
            <b-skeleton width="100%" height="60vh"></b-skeleton>
          </b-card>
        </template>

        <TableComponent
          :fields="fields"
          :loading="loading"
          :dataItem="row"
          @emit-row-data="onClick"
        />
      </b-skeleton-wrapper>
    </div>
    <div class="mx-auto container-fluid">
      <div class="px-lg-5 mt-1">
        <div class="d-flex justify-content-end">
          <router-link to="/dashboard/detailed_Indices/scorecard">
            <button class="btn-lg mr-4 btn-primary btn_contd">
              Continue&nbsp;<span>&#8594;</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <loader />
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex'
import { previousValue, formatToCurrency } from '@/utils/index'
// import TableComponent from '@/components/TableComponent.vue'
import GenericToolTip from '@/components/generic/GenericTooltip.vue'
import setup from '@/mixins/setup'
import GovernorsProfle from '@/components/ProfileSection.vue'
import TrendLineConfig from '@/config/trendline'
import Loader from '@/modules/dashboard/components/Loader.vue'

export default {
  mixins: [setup],
  name: 'key-health-indices',
  data () {
    return {
      trendLine: TrendLineConfig,
      row: [],
      loading: false,
      /**
       * * Table header field data configuration
       */
      fields: [
        {
          key: 'indicator',
          thClass: 'bg-secondary text-white'
        },
        {
          key: 'status',
          thClass: 'bg-secondary text-white text-center',
          tdClass: 'text-center'
        },
        {
          key: 'trend',
          thClass: 'bg-secondary text-white text-center',
          tdClass: 'text-center'
        },
        {
          key: 'target',
          thClass: 'bg-secondary text-white text-center',
          tdClass: 'text-center'
        }
      ]
    }
  },
  components: {
    // TableComponent,
    GenericToolTip,
    GovernorsProfle,
    Loader
  },
  computed: {
    ...mapGetters(['getUserDetails']),
    ...mapGetters('DASHBOARD_STORE', ['getKHIndices'])
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_UP_KH']),
    ...mapActions(['SET_NO_DATA']),
    /**
     * getProgramAreasAndIndicatorTrendData.
     * * Returns an array of indicators (program areas),
     * *  targets and trend for key health indices table.
     * @params no parameters required for this method.
     */

    getProgramAreasAndIndicatorTrendData () {
      let status = {}
      const prog = this.getProgramAreas()
      const indicators = prog.map((el) => {
        this.getProgramAreaSource(el)

        let val = {}
        const programAreasIndicators = this.getProgramAndIndicator(el)
        const selectOnlyOneIndicator = programAreasIndicators.slice(0, 1)

        selectOnlyOneIndicator.forEach((ind) => {
          const indicatorDefinition = this.getDefinition(ind)
          const availableYearsValue = this.getMaxYearForEachIndicator(ind, this.dataSource)
          const maxYears = Math.max(...availableYearsValue)
          const indicatorsAndSource = `${ind} -> ${this.dataSource}`
          const index = this.yearlyData[0].indexOf(indicatorsAndSource)
          const passedYear = this.getYearlydataNumber(index, maxYears - 2)
          const previousYear = this.getYearlydataNumber(index, maxYears - 1)
          const currentYear = this.getYearlydataNumber(index, maxYears)
          const target = this.getIndicatorTarget(ind)
          this.stateYearData.forEach((data) => {
            if (data[0] === Number(maxYears)) {
              const displayFactor = this.getDisplayFactor(ind)
              if (data[index] !== '') {
                const increase = previousValue(this.stateYearData, this.stateName, Number(maxYears) - 1, index) <
                  data[index]

                status = {
                  data:
                    displayFactor.type === 'currency'
                      ? formatToCurrency(data[index])
                      : Number.parseFloat(data[index]),
                  increase,
                  displayFactor: displayFactor.sign
                }
              } else {
                status = {
                  data: null,
                  increase: false,
                  displayFactor: displayFactor.sign
                }
              }
            }
          })
          const trendline = {
            ...this.trendLine
          }
          trendline.series = [
            {
              name: 'hello',
              data: [passedYear, previousYear, currentYear]
            }
          ]
          val = {
            indicator: `${ind} (${el})`,
            status,
            trend: trendline,
            programAreaSeletable: el,
            indicatorDefinition: indicatorDefinition || '',
            indicatorSelectable: ind,
            target: `${target}` || '--',
            dataSource: this.dataSource,
            year: maxYears === -Infinity ? '' : maxYears
          }
        })
        return val
      })
      this.row = indicators
    },

    parseTrend () {},

    /**
     * onClick
     * * listen for onClick events on table component
     * @params no params required
     */
    onClick (data) {
      this.$router.push({
        path: 'detailed_Indices/scorecard',
        query: {
          program: data.programArea,
          indicator: data.indicator
        }
      })
    },

    getYearlydataNumber (index, year) {
      let val = 0
      this.stateYearData.forEach((data) => {
        if (data[0] === Number(year)) {
          val = data[index]
        }
      })
      return Number(val)
    },
    // --------------------------
    async getData () {
      this.loading = true
      if (this.getKHIndices.length === 0) {
        try {
          await this.SET_UP_KH()
          this.row = await this.getKHIndices
        } catch (error) {
          console.log(error)
        }
      } else {
        this.row = this.getKHIndices
      }
      this.loading = false
      this.getKHIndices?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
    }
  },
  mounted () {
    // this.getProgramAreasAndIndicatorTrendData();
    this.getData()
  }
}
</script>
