<template>
  <b-modal id="contact" centered title="Contact Us">
    <div class="text-center px-2">
      <p>
        Welcome to the Nigerian Governors Forum Dashboard (NGF Dashboard). The
        DHPRS team welcomes your feedback and suggestions.
      </p>
      <p class="alt">
        Please leave us your feedback and we will be in touch soon
      </p>
    </div>
    <form @submit="handleSubmit" ref="feedbackForm">
      <div class="form-group">
        <label for="name" class="sr-only">Name</label>
        <input
          type="text"
          v-model.trim="name"
          class="form-control"
          id="name"
          name="name"
          autocomplete="full-name"
          placeholder="Name"
        />
      </div>
      <div class="form-group">
        <label for="email" class="sr-only">Email</label>
        <input
          type="email"
          v-model.trim="email"
          class="form-control"
          id="email"
          name="email"
          autocomplete="email"
          placeholder="Email Address"
        />
      </div>
      <div class="form-group">
        <label for="profession" class="sr-only">Profession</label>
        <input
          type="text"
          v-model.trim="profession"
          class="form-control"
          id="profession"
          name="profession"
          autocomplete="profession"
          placeholder="Profession or field of work/study"
        />
      </div>
      <div class="form-group">
        <label for="organization" class="sr-only">Organization</label>
        <input
          type="text"
          v-model.trim="organization"
          class="form-control"
          id="organization"
          name="organization"
          autocomplete="organization"
          placeholder="Organization"
        />
      </div>
      <div class="form-group">
        <select
          class="form-control"
          name="category"
          id="category"
          v-model="category"
        >
          <option selected disabled value="null">-- Select an Option --</option>
          <option value="1">One</option>
          >
        </select>
      </div>
      <div class="form-group">
        <label for="feedback" class="sr-only">Feedback</label>
        <textarea
          class="form-control"
          id="feedback"
          name="feedback"
          placeholder="Enter your feedback here..."
          v-model.trim="feedback"
          rows="3"
        ></textarea>
      </div>
      <div class="text-right">
        <button type="submit" class="btn rounded-0" @click.prevent="handleSubmit">Submit</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'ContactComponent',
  data () {
    return {
      name: '',
      email: '',
      profession: '',
      organization: '',
      category: null,
      feedback: '',
      loading: false
    }
  },
  methods: {
    handleSubmit () {
      this.loading = true
      // check if all fields are filled
      const { name, email, profession, organization, category, feedback } = this
      if (name === '' || email === '' || profession === '' || organization === '' || category === null || feedback === '') {
        this.loading = false
        this.$bvToast.toast(
          'Please fill in all fields',
          {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          }
        )
      } else {
        // send feedback to server
        const data = {
          name,
          email,
          profession,
          organization,
          category,
          feedback
        }
        console.table(data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container-lg {
  color: #2e5d5c;
}
.h5 {
  font-weight: 600;
  font-size: 20px;
}
p {
  font-size: 14px;
}
p.alt {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}
.btn {
  background-color: #2e5d5c;
  color: white;
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color: #2e5d5c;
    color: white;
    padding-right: 0.5rem;
  }
}
</style>

<style lang="scss">
header.contact___BV_modal_header_ {
  padding: 0 1rem;
}
</style>
