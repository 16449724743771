import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { route as ADMIN_ROUTE } from '@/modules/admin'
import { route as HOMEPAGE_ROUTE } from '@/modules/homepage'
import { route as DASHBOARD_ROUTE } from '@/modules/dashboard'

Vue.use(VueRouter)

const routes = [
  ...ADMIN_ROUTE,
  ...HOMEPAGE_ROUTE,
  ...DASHBOARD_ROUTE,
  {
    // will match everything
    path: '*',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/modules/homepage/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isUser || store.getters.isAdmin) {
      next()
      return
    }
    if (store.getters.isUser) {
      next('/login')
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isUser) {
      next('/dashboard/state_profile')
      return
    }
    if (store.getters.isAdmin) {
      next('/admin/approval')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
