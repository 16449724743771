export const previousValue = (stateData, state, year, index) => {
  const data = stateData.filter((item) => item[0] === year && item[1] === state)
  if (data.length) {
    return data[0][index]
  }
  return 0
}

// export const formatToCurrency = (number) =>
// `N${(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
// export const formatToMoney = (number) =>
// `${(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

/**
 * REMOVE THE 2DECIMAL PLACE AFTER EACH MONEY VALUE AS REQUEST BY THE STAKEHOLDER
 */
export const formatToCurrency = (number) => `₦${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
export const formatToMoney = (number) => `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

export const getBarChartData = (data) => {
  const chartData = []

  const yearValue = data.value[0].trend.series[0].name
  const bar1 = {}
  bar1.name = 'Actual'
  bar1.color = '#6EB83D'
  bar1.data = data.value[0].trend.series[0].data
  const bar2 = {}
  bar2.name = 'Budgeted'
  bar2.color = '#104911'
  bar2.data = data.value[1].trend.series[0].data

  chartData.push({ ...bar1 })
  chartData.push({ ...bar2 })

  return [chartData, yearValue]
}
