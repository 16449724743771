<template>
  <section v-if="getNoData">
    <el-empty
      description="There is no data available for the selected criteria."
    />
  </section>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
  name: 'NoData',
  computed: {
    ...mapGetters(['getNoData'])
  }
}
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // div.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  // background-color: #00000017;
  h4 {
    text-decoration: none;
  }
  p {
    text-decoration: none;
  }
  // }
}
</style>
