export default {
  chart: {
  },
  title: {
    enabled: false,
    text: null
  },
  subtitle: {
    text: null
  },
  legend: {
    enabled: true,
    verticalAlign: 'top'
  },
  credits: {
    enabled: false
  },
  mapNavigation: {
    enabled: false,
    buttonOptions: {
      verticalAlign: 'bottom'
    }
  },
  plotOptions: {
    map: {
      joinBy: ['name', 'hc-key'],
      dataLabels: {
        enabled: true,
        formatter () {
          if (this.point.value) {
            return this.point.name
          }
          return this.point.name
        }
      },
      colorAxis: {
        min: 0
      }
    }
  },
  series: []
}
