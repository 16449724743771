<template>
  <div class="px-lg-5 px-md-3 container-fluid">
    <div class="card border-0 p-3 my-3">
      <DataEntry />
      <!-- 1st iteration of the data entry module -->
      <form @submit.prevent="handleSubmit" @reset="handleReset" class="d-none">
        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          :multiple="false"
          accept='xlsx,xls'
        ></b-form-file>
        <b-button @click="handleSubmit" class="mt-3">Submit</b-button>
      </form>
    </div>
    <NGFTable class="mt-3 d-none" />
  </div>
</template>

<script lang="js">
import { mapActions } from 'vuex'
import NGFTable from './Table.vue'
import DataEntry from '../components/DataEntry.vue'

export default {
  name: 'StateHomePage',
  components: {
    NGFTable,
    DataEntry
  },
  data () {
    return {
      data: [{
        code: 'Hello',
        name: 'Hello',
        category: 'Hello',
        quantity: 'Hello'
      }],
      file: null
    }
  },
  methods: {
    ...mapActions('ADMIN_STORE', ['UPLOAD_FILE']),
    handleSubmit () {
      const data = new FormData()
      data.append('sheet', this.file)
      this.UPLOAD_FILE(data)
    },
    handleReset () {
      console.log('reset')
    }
  }
}
</script>
