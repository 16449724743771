import index from '../views/index.vue'
import Home from '../views/Home.vue'
import UpdateLog from '../views/UpdateLog.vue'
import About from '../views/About.vue'
import Faq from '../views/Faq.vue'
import Login from '../views/Login.vue'

export default [
  {
    path: '/',
    component: index,
    children: [
      {
        path: '',
        alias: '/home',
        name: 'HomePage',
        component: Home
      },
      {
        path: '/update-log',
        name: 'UpdateLog',
        component: UpdateLog
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/faq',
        name: 'Help/Faq',
        component: Faq
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          guest: true
        }
      }
    ]
  }
]
