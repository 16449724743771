import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

const msdatInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_MSDAT_API
})

export { instance, msdatInstance }
