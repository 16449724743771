/* eslint-disable camelcase */
import VueCookies from 'vue-cookies'
import { instance } from '@/config/axios'
import store from '../store'

// const loginUrl = 'governor/login/'
const loginUrl = 'dmi/viewer/login/'
const adminLoginUrl = 'dmi/admins/login/'
const regUrl = 'dmi/viewer/'
// const regUrl = '/register/'
const passwordResetUrl = '/password/reset/'
const passwordResetconfirmUrl = '/password/reset/confirm/uuid/token/'
const userUrl = '/user/'

/**
* @function Login Function
* @author james
* @description function to login a user
* @param {String} url - login
* @return {Boolean} token user details
*/
const loginRoutine = (LoginDetails) => new Promise((resolve, reject) => {
  instance({ url: loginUrl, data: LoginDetails, method: 'POST' })
    .then((response) => {
      adminLogoutRoutine()
      const { token, ...user } = response.data
      VueCookies.set('access-token', token) // store the token in cookie
      VueCookies.set('user-details', user)
      store.commit('auth_user', user)
      store.commit('auth_token', token)
      console.log(store.getters.authStatus)
      resolve(response)
      return user
    })
    .catch((error) => {
      VueCookies.remove('access-token') // if the request fails, remove any possible user token
      reject(error)
    })
})
// admin login
const adminLoginRoutine = (AdminLoginDetails) => new Promise((resolve, reject) => {
  instance({ url: adminLoginUrl, data: AdminLoginDetails, method: 'POST' })
    .then((response) => {
      const { token } = response.data
      logoutRoutine()
      VueCookies.set('admin-access-token', token) // store the token in cookie
      VueCookies.set('admin-details', response.data)
      store.commit('admin_user', response.data)
      store.commit('admin_token', token)
      resolve(response.data)
      return response.data
    })
    .catch((error) => {
      VueCookies.remove('admin-access-token') // if the request fails, remove any possible user token
      reject(error)
    })
})

// admin logout
const adminLogoutRoutine = () => new Promise((resolve) => {
  VueCookies.remove('admin-access-token')
  VueCookies.remove('admin-details')
  store.commit('admin_user', {})
  store.commit('admin_token', '')
  resolve()
})
// registration process
const regRoutine = (user) => new Promise((resolve, reject) => {
  instance({ url: regUrl, data: user, method: 'POST' })
    .then((response) => {
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    })
})

// logout process
const logoutRoutine = () => new Promise((resolve) => {
  // VueCookies.remove('refresh-token')
  VueCookies.remove('access-token')
  VueCookies.remove('user-details')
  store.commit('auth_user', {})
  store.commit('auth_token', '')
  // ---------
  adminLogoutRoutine()
  resolve()
})

// forgot password
const passwordReset = (emailAddress) => new Promise((resolve, reject) => {
  console.log(emailAddress)
  instance({ url: passwordResetUrl, data: emailAddress, method: 'POST' })
    .then((response) => {
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    })
})

// confirm password reset
const confirmReset = (resetDetails) => new Promise((resolve, reject) => {
  console.log(resetDetails)
  console.log(passwordResetconfirmUrl)
  instance({ url: passwordResetconfirmUrl, data: resetDetails, method: 'POST' })
    .then((response) => {
      console.log(response)
      resolve(response)
    })
    .catch((error) => {
      console.log(error.response)
      reject(error)
    })
})
const getUserInfo = () => new Promise((resolve, reject) => {
  console.log('fetching user info from API')
  instance({ url: userUrl, headers: { Authorization: `Bearer ${VueCookies.get('access-token')}` }, method: 'GET' })
    .then((response) => {
      console.log(response)
      // storeUserInfo(response);
      resolve(response.data)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.request.status)
      if (error.response.request.status === 401) {
        console.log('error with token, initiating logout routine')
        logoutRoutine()
      }
      reject(error)
    })
})

export default {
  loginRoutine,
  adminLoginRoutine,
  adminLogoutRoutine,
  regRoutine,
  logoutRoutine,
  passwordReset,
  confirmReset,
  getUserInfo
}
