<template>
  <div v-if="stateValue">
    <GovernorsProfle :searchType="''" />
    <!-- <div class="container">
      <div class="text-right mb-2">
        <GenericToolTip />
      </div>
    </div> -->
    <div class="container-fluid px-lg-5 mt-4">
      <table>
        <tr>
          <th>S/N</th>
          <th>Implementing Partner</th>
          <th>Intervention</th>
          <th>Geographical Location</th>
        </tr>
        <tr v-for="(item, index) in filteredData" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.implementing_partner }}</td>
          <td>{{ item.intervention }}</td>
          <td>{{ item.geographical_location }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import GovernorsProfle from '@/components/ProfileSection.vue'
import { mapGetters, mapActions } from 'vuex'
// import { capitalize } from 'lodash'
import setup from '@/mixins/setup'

export default {
  name: 'PartnerMapping',
  mixins: [setup],
  components: {
    GovernorsProfle
  },
  data () {
    return {
      stateInfoData: 'value',
      stateValue: null,
      tableValues: {}
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getPartnerMapping']),
    filteredData () {
      return this.getPartnerMapping.filter(
        (item) => item.state.toLowerCase() === this.stateName.toLowerCase()
      )
    }
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['GET_PARTNER_MAP']),
    getIndicatorAndIndex (data) {
      const states = data[0].map((item, index) => ({
        item,
        index
      }))
      return states
    },
    getPartnerMap (data) {
      const stateName = this.stateName.toLowerCase()
      return data.filter((item) => item[1].toLowerCase() === stateName)
    },
    getValues () {
      const data = this.getPartnerMap(this.stateInfoData)[0]
      const indicatorsAndIndex = this.getIndicatorAndIndex(this.stateInfoData)
      const values = []
      indicatorsAndIndex.forEach((item) => {
        values.push({
          key: data[item.index]
        })
      })
      return values
    }
  },

  async mounted () {
    const data = {
      id: 11,
      state: 'anambra'
    }
    await this.GET_PARTNER_MAP(data)
    this.stateInfoData = this.$store.state.stateInfo
    this.stateValue = this.getValues()
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  padding: 20px;
}

th {
  color: #fff;
  background-color: #2e5d5c;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
}

td {
  padding: 20px;
  border: 1px solid #dddddd;
}
</style>
