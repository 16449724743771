import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import VueCookies from 'vue-cookies'
import HighchartsVue from 'highcharts-vue'
import PrimeVue from 'primevue/config'
import VueToastify from 'vue-toastify'
import Element from 'element-ui'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import './scss/main.scss'

Vue.use(VueToastify)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
// No message specified.
extend('email', email)
extend('required', required)

Vue.use(HighchartsVue)
Vue.use(PrimeVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
