const getters = {
  getDataSource: (state) => state.dataSource,
  getDataSourceId: (state) => state.dataSourceId,
  getIndicators: (state) => state.indicators,
  getIndicatorsId: (state) => state.indicatorsId,
  getYears: (state) => state.years,
  getLocation: (state) => state.location,
  getLocationId: (state) => state.locationId,
  getLastUpdate: (state) => state.lastUpdate,
  getDataPoint: (state) => state.data,
  getDefault: (state) => state.ngf,
  getStateProfile: (state) => state.stateProfile,
  getProgramArea: (state) => {
    return state.ngf.map(el => el.programArea)
  },
  getKHIndices: (state) => state.keyHealthIndices,
  getZonalData: (state) => state.zonalData,
  getYearDropdown: (state) => state.yearDropdown,
  getMonthOptions: (state) => state.monthOptions,
  getStateValue: (state) => state.stateData,
  getDataGroup: (state) => state.dataGroup,
  getSectionSelected: (state) => state.sectionSelected,
  getPartnerMapping: (state) => state.partnerMapping,
  getFlagshipProject: (state) => state.flagshipProject
}

export default getters
