<template>
    <div>
        <footer class="fixed-bottom">
        <div class="container-fluid">
            <div class="row bg-primary py-1">
                <div class="col-md-6 col-12 text-white pt-1">
                    Built with <span class="text-danger">♥</span> by eHealth4everyone
                </div>
                <div class="col-md-6 col-12 text-md-right" v-if="this.$route.name !== 'Home'">
                  <h6 class="text-white pr-4 pt-2">Last Updated: {{ latestDate }} </h6>
                </div>
            </div>
        </div>
    </footer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'TheFooter',
  data () {
    return {
      name: 'MyFooter',
      latestDate: 'Loading...'
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getLastUpdate'])
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['GET_LAST_UPDATE']),
    async getDate () {
      await this.GET_LAST_UPDATE()
      this.latestDate = moment(this.getLastUpdate, 'YYYY-MM-DD').format('MMMM Do YYYY')
    }
  },
  mounted () {
    this.getDate()
  }
}
</script>
