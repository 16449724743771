<template>
  <div
    class="state__box text-white shadow-sm rounded d-flex align-items-center p-2"
  >
    <div class="">
      <!-- <i class="fa fa-users" style="font-size:40px"></i> -->
      <img
        rel="preload"
        :src="imageLink"
        class="mr-1"
        style="width: 50px"
        alt=""
        srcset=""
      />
    </div>
    <div v-if="indicator === 'Created'" class="d-flex flex-column">
      <span class="text-capitalize custom-font-size">{{ indicator }}</span>
      <span class="text-uppercase indicator-value">{{ value || "-" }} </span>
    </div>
    <div v-else class="d-flex flex-column">
      <span class="text-capitalize custom-font-size">{{ indicator }}</span>
      <span class="indicator-value">{{ formatValue(value || "-") }} </span>
    </div>
  </div>
</template>

<script>
import { formatToMoney } from '@/utils/index'
export default {
  name: 'StateProfileSectionCard',
  props: {
    indicator: {
      type: String
    },
    value: {
      type: [Number, String],
      default: () => '-'
    },
    imageLink: {
      type: String
    }
  },
  methods: {
    formatValue (value) {
      if (value !== '' && value !== undefined) {
        return formatToMoney(value)
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.state__box {
  background-color: #4e7675;
  transition: all 1s ease-in-out;
  cursor: pointer;
}
div.state__box:hover {
  background-color: #2e5d5c;
  box-shadow: 0 0.3rem 1rem rgb(0 0 0 / 10%) !important;
}
.custom-font-size {
  font-size: 1rem;
  // font-weight: 900 !important;
  font-weight: bolder;
}
.indicator-value {
  font-size: 0.85rem;
}
</style>
