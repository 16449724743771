<template>
  <div>
    <!-- <div class="container-fluid px-4 mb-2">
      &nbsp;
      <GenericTooltip />
    </div> -->
    <div class="container-fluid pt-3">
      <div class="d-flex justify-content-between w-100">
        <h5 class="table_title">{{ indicator }}</h5>
        <div>
          <div class="px-lg-5 mt-1">
            <div class="d-flex justify-content-end">
              <button
                class="btn-lg mr-4 btn-primary btn_contd"
                @click="$router.go(-1)"
              >
                <span>&#8592;</span>&nbsp;Back
              </button>
            </div>
          </div>
        </div>
        <!-- <b-form-select v-model="selected" :options="yearOption" /> -->
      </div>
      <Table
        :title="indicator"
        :dataItem="tableData"
        :fields="fields"
        :loading="loading"
      />
      <div class="row d-none">
        <div
          class="col-lg-4 col-md-6 col-12 mb-4"
          v-for="(item, i) in tableData"
          :key="i"
        >
          <div class="card card-body shadow-sm score_card_view h-100">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Program Area&nbsp;:&ensp;</strong>{{ item[2] }}
              </li>
              <li class="list-group-item">
                <strong>Indicator:&ensp;</strong>{{ item[3] }}
              </li>
              <li class="list-group-item">
                <strong>Year&nbsp;:&ensp;</strong>{{ item[4] }}
              </li>
              <li class="list-group-item">
                <strong>Status&nbsp;:&ensp;</strong>
                <p
                  class="display"
                  :class="item[5] === 1 ? 'bg-success' : 'bg-danger'"
                ></p>
                <!-- </li>
              <li class="list-group-item">
                <strong>Target&nbsp;:&ensp;</strong>
                <p
                  class="display"
                  :class="item[6] === 1 ? 'bg-success' : 'bg-danger'"
                ></p>
              </li> -->
                <!-- <li class="list-group-item">
                <strong>Performance&nbsp;:&ensp;</strong>
                <p
                  class="display"
                  :class="item[7] === 1 ? 'bg-success' : 'bg-danger'"
                ></p> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid status-description">
      <div class="box bg-danger mx-1"></div>
      <div class="mr-4">Target not met</div>
      <div class="box mx-1 bg-success"></div>
      <div class="mr-4">Target met</div>
      <div class="box mx-1 bg-dark"></div>
      <div>No data</div>
    </div>
  </div>
</template>

<script type="js">
// import GenericTooltip from '@/components/generic/GenericTooltip.vue'
import { mapGetters, mapMutations } from 'vuex'
import { uniq } from 'lodash'
import Table from './Table.vue'

export default {
  name: 'Scorecard-View',
  components: { Table },
  data () {
    return {
      indicator: '',
      datas: [],
      tableData: [],
      fields: [
        {
          key: 'Indicator',
          sortable: false,
          thClass: 'bg-secondary text-white'
        },
        {
          key: 'Status',
          sortable: false,
          thClass: 'bg-secondary text-white'
        }
        // {
        //   key: 'Performance',
        //   sortable: false,
        //   thClass: 'bg-secondary text-white',
        // },
        // {
        //   key: 'Target',
        //   sortable: false,
        //   thClass: 'bg-secondary text-white',
        // },
      ],
      loading: false,
      selected: null,
      yearOption: []
    }
  },
  computed: {
    ...mapGetters({
      scoreCard: 'getStateScoreCard'
    })
  },
  watch: {
    selected: {
      handler () {
        this.showDataByYear()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapMutations(['setStateScoreCard']),
    async getData () {
      const value = this.$route.params.slug
      this.indicator = value
      // this.indicator = value.split(' ').slice(0, -1).join(' ');
      this.setStateScoreCard()
      this.datas = await this.scoreCard
    },
    async setFilterData () {
      const years = []
      this.tableData.forEach((item) => {
        years.push(item[4])
      })
      const yearData = uniq(years)
      this.yearOption = []
      this.yearOption = yearData.map((item) => ({ text: item, value: item }))
      if (this.yearOption.length !== 0) {
        this.selected = this.yearOption[this.yearOption.length - 1].value
      }
      console.log(this.tableData, 'tableData4')
    },
    // FILTER THE DATA BY THE SELECTED INDICATOR THEREBY BECOMING THE MAIN DATA SOURCE FOR DISPLAY
    async showDataByIndicators () {
      console.log('indicators', this.indicator)
      this.tableData = this.datas.filter((item) => item[2] === this.indicator)
      console.log('tableData', this.tableData)

      // filter by year (temporary)
      // this.tableData = this.datas.filter((item) => item[4] === 2022)
      // await this.setFilterData()
    },
    // SHOW DATA BY YEAR SELECTED
    async showDataByYear () {
      this.tableData = this.datas.filter((item) => item[4] === this.selected)
    }
  },
  async mounted () {
    this.loading = true
    console.log(this.tableData, 'table data')
    await this.getData()
    await this.showDataByIndicators()
    // await this.showDataByYear()

    this.loading = false
  }
}
</script>

<style scoped lang="scss">
div.score_card_view {
  height: 100%;
  width: 100%;
  background-color: #eeeeee;
  border-radius: 0.5rem;
  border: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: all 1s ease-in-out;
}
div.score_card:hover {
  background-color: #e6e6e6;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
h4 {
  text-decoration: underline;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 600;
}
ul {
  list-style: none;
  padding-left: 0;
}
ul li {
  align-items: center;
  display: flex;
  min-height: 2rem;
}
p.display {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-bottom: 0;
  margin-left: 0.4rem;
}
strong {
  margin-right: 0.4rem;
}
h5.table_title {
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.status-description {
  display: inline-flex;
  justify-content: center;
}
.box {
  min-width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-top: 6px;
}
</style>
