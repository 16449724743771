import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { store as ADMIN_STORE } from '@/modules/admin'
import { store as DASHBOARD_STORE } from '@/modules/dashboard'
import { store as HOMEPAGE_STORE } from '@/modules/homepage'

Vue.use(Vuex)
const accessToken = VueCookies.get('access-token')
const adminAccessToken = VueCookies.get('admin-access-token')
const userDetails = VueCookies.get('user-details')
const adminDetails = VueCookies.get('admin-details')
export default new Vuex.Store({
  state: {
    stateProfile: [],
    stateInfo: [],
    user: userDetails || '',
    isAdmin: false,
    admin_user: adminDetails || '',
    adminToken: adminAccessToken || '',
    token: accessToken || '',
    demography: [],
    healthFacility: [],
    scoreCard: [],
    stateScoreCard: [],
    modalState: false,
    isLoading: false,
    noData: false
  },
  mutations: {
    setStateProfle (state, payload) {
      state.stateProfile = payload
    },
    setModalState (state, payload) {
      state.modalState = payload
    },
    setYearlyData (state, payload) {
      state.yearlyData = payload
    },
    setStateInfo (state, payload) {
      state.stateInfo = payload
    },
    auth_user: (state, data) => {
      state.user = data
    },
    admin_user: (state, data) => {
      state.admin_user = data
    },
    auth_token: (state, data) => {
      state.token = data
    },
    admin_token: (state, data) => {
      state.adminToken = data
    },
    setDemography: (state, data) => {
      state.demography = data
    },
    setHealthFacility: (state, data) => {
      state.healthFacility = data
    },
    setScoreCard: (state, data) => {
      state.scoreCard = data
    },
    setStateScoreCard: (state) => {
      const stateName = state.user.state.toLowerCase()
      state.stateScoreCard = state.scoreCard.filter((item) => item[1].toLowerCase() === stateName)
    },
    setLoading: (state, payload) => {
      state.isLoading = payload
    },
    setNoData: (state, payload) => {
      state.noData = payload
    }
  },
  actions: {
    SET_STATE_PROFILE ({ commit }, payload) {
      commit('setStateProfle', payload)
      console.log(payload, 'payyload')
    },
    SET_MODAL ({ commit }) {
      commit('setModalState', true)
    },
    UNSET_MODAL ({ commit }) {
      commit('setModalState', false)
    },
    SET_YEARLY_DATA ({ commit }, payload) {
      commit('setYearlyData', payload)
    },
    SET_STATE_INFO ({ commit }, payload) {
      commit('setStateInfo', payload)
    },
    SET_DEMOGRAPHY_DATA ({ commit }, payload) {
      commit('setDemography', payload)
    },
    SET_HEALTH_FACILITY_DATA ({ commit }, payload) {
      commit('setHealthFacility', payload)
    },
    SET_SCORECARD_DATA ({ commit }, payload) {
      commit('setScoreCard', payload)
    },
    // Get data from the server via the config/dashboard.js
    async GET_DATA ({ commit }, payload) {
      const { data } = await ADMIN_STORE.dispatch('GET_DATA', payload)
      commit('SET_MONTHLY_DATA', data.monthlyData)
      commit('SET_YEARLY_DATA', data.yearlyData)
    },
    SET_LOADING ({ commit }, payload) {
      commit('setLoading', payload)
    },
    SET_NO_DATA ({ commit }, payload) {
      commit('setNoData', payload)
    }
  },
  getters: {
    isUser: (state) => state.token !== '',
    isAdmin: (state) => state.adminToken !== '',
    getStateScoreCard: (state) => state.stateScoreCard.length > 0 && state.stateScoreCard,
    modalState: (state) => state.modalState,
    // eslint-disable-next-line max-len
    getCurrentStateProfile: (state) => state.stateProfile.filter((item) => item[1].toLowerCase() === state.user.state.toLowerCase()),
    getUserDetails: (state) => state.user,
    getLoading: (state) => state.isLoading,
    getNoData: (state) => state.noData
  },
  modules: {
    ADMIN_STORE,
    DASHBOARD_STORE,
    HOMEPAGE_STORE
  }
})
