<template>
  <!-- <div class="home home_page"> -->
  <!-- <Carousel :options="options" :slides="2" :interval="4000" /> -->

  <div class="container-fluid home home_page">
    <div class="col-sm-6 py-5">
      <h1 class="font-weight-bold mb-4 pr-1 text-uppercase">
        Welcome to the governor's dashboard
      </h1>
      <p>ABOUT THIS DASHBOARD</p>
      <p class="mb-4">
        The NGF secretariat has built this resource to enhance collaborative
        performance management and monitoring by the Executive Governors for
        improving health outcomes and service delivery in the country.
      </p>
      <p>
        With this resource, Governors have access to a holistic view of key
        health performance indicators to support and improve health management
        decisions in their respective states As the resource evolves, the NGF
        hopes to foster improvements in performance management for better health
        outcomes for the citizens. The NGF secretariat welcomes feedback on this
        platform
      </p>
      <div
        class="col-md-6 col-12 pt-2 d-none d-md-block"
        :class="LoggedIn === true ? '' : 'my-3'"
      >
        <router-link v-if="LoggedIn === false" to="/login">
          <button class="btn btn-primary btn-lg">Login to your account</button>
        </router-link>
      </div>
    </div>
    <div class="col-sm-6">
      <img src="@/assets/new-gov-groups.png" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// import Carousel from '@/components/Carousel.vue'

export default {
  name: 'HomePage',
  data () {
    return {
      options: [
        ['WELCOME TO THE GOVERNORS DASHBOARD', '1'],
        ['WELCOME TO THE GOVERNORS DASHBOARD', '2']
      ]
    }
  },
  computed: {
    LoggedIn () {
      return this.$store.getters.isUser
    }
  }
  // components: {
  //   Carousel
  // }
}
</script>

<style lang="scss" scoped>
div.home_page {
  padding-bottom: 0px !important;
  display: flex;
  background-image: url("~@/assets/homeBG.svg");
  background-size: cover;
  // position: relative;
  color: #fff;
  /* background-image: url("@assets/") */
  p {
    font-size: 1.1rem;
    line-height: 1.75rem;
    text-align: justify;
  }
  img {
    max-width: 85%;
    padding: 20px 0px;
  }
}
</style>
