/* eslint-disable operator-linebreak */ /* eslint-disable operator-linebreak */
<template>
  <div>
    <!--===== User Profile =====-->
    <div class="container-fluid">
      <Profile
        @selectedYear="selectYear"
        @selectedMonth="selectMonth"
        :searchType="type"
      />
    </div>

    <div class="container-fluid px-0">
      <TopNav @switched="switchTab" />
    </div>

    <div class="container-fluid px-4 mb-2">
      <GenericTooltip />
    </div>
    <!-- <div
      class="container-fluid px-4 mb-2"
      v-if="section === 'Health Training Institutions'"
    >
      <span>Segments:</span>
      <span @click="changeSegmentHTI('Public')"
        ><b-button
          class="segment-btn"
          @click="toggle('button1')"
          :class="{ active: activeButton === 'button1' }"
          >Public</b-button
        ></span
      >
      <span @click="changeSegmentHTI('Private')"
        ><b-button
          class="segment-btn"
          @click="toggle('button2')"
          :class="{ active: activeButton === 'button2' }"
          >Private</b-button
        ></span
      >
      <span @click="changeSegmentHTI('Admission')"
        ><b-button
          class="segment-btn"
          @click="toggle('button3')"
          :class="{ active: activeButton === 'button3' }"
          >Admission Quota</b-button
        ></span
      >
    </div> -->
    <!-- <div
      class="container-fluid px-4 mb-2"
      v-if="section === 'Human Resources for Health'"
    >
      <span>Segments:</span>
      <span @click="changeSegmentHRH('Number')"
        ><b-button
          class="segment-btn"
          @click="toggle1('button4')"
          :class="{ active: activeButton1 === 'button4' }"
          >Number</b-button
        ></span
      >
      <span @click="changeSegmentHRH('Density')"
        ><b-button
          class="segment-btn"
          @click="toggle1('button5')"
          :class="{ active: activeButton1 === 'button5' }"
          >Density</b-button
        ></span
      >
      <span @click="changeSegmentHRH('TargetWho')"
        ><b-button
          class="segment-btn"
          @click="toggle1('button6')"
          :class="{ active: activeButton1 === 'button6' }"
          >Target (WHO)</b-button
        ></span
      >
    </div> -->
    <div
      class="container-fluid px-4 mb-2"
      v-if="section === 'Health Financing'"
    >
      <span>Segments:</span>
      <span @click="changeSegmentHF('Allocation')"
        ><b-button
          class="segment-btn"
          @click="toggle2('button7')"
          :class="{ active: activeButton2 === 'button7' }"
          >Allocation</b-button
        ></span
      >
      <span @click="changeSegmentHF('Performance')"
        ><b-button
          class="segment-btn"
          @click="toggle2('button8')"
          :class="{ active: activeButton2 === 'button8' }"
          >Performance</b-button
        ></span
      >
    </div>
    <div class="row container-fluid">
      <div
        class="md-3 slide"
        :class="[
          programObject.programArea === 'Outcomes' ||
          programObject.programArea === 'Service Delivery'
            ? 'col-lg-7 col-12'
            : 'col-12',
        ]"
      >
        <Table
          class="mb-2"
          :dataItem="dataObject"
          :fields="fields"
          :loading="Loading"
          @emit-row-data="onClick"
        />
      </div>

      <div
        v-if="section === 'Human Resources for Health'"
        class="data_source_box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: SMoH</p>
      </div>

      <div
        v-if="section === 'Health Training Institutions'"
        class="data_source_box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source:SMoH</p>
      </div>

      <div
        v-if="section === 'Health Financing'"
        class="data_source_box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: State Budget Document</p>
      </div>

      <div
        v-if="section === 'Access and Service Utilization'"
        class="data_source_box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: State Ministry of Health</p>
      </div>

      <div
        v-if="section === 'Health Outcomes'"
        class="data_source_box text-white shadow-sm rounded d-flex align-items-center"
      >
        <p class="font-weight-bold">Data Source: State Ministry of Health</p>
      </div>

      <div
        v-if="
          programObject.programArea === 'Outcomes' ||
          programObject.programArea === 'Service Delivery'
        "
        :class="[
          programObject.programArea === 'Outcomes' ||
          programObject.programArea === 'Service Delivery'
            ? 'col-lg-5 col-12'
            : '',
        ]"
      >
        <div class="card-header bg-secondary text-white">
          <h6 class="h6 active_map_header">{{ mapHeader }}</h6>
        </div>
        <div class="card-body p-0 shadow-sm">
          <Map :mapObject="mapObject" :level="level" :lgaState="state" />
          <!-- <pre>{{ mapObject }}</pre> -->
        </div>
      </div>
    </div>

    <!--===== Bottom Navigation =====-->
    <Loader />
    <BottomNav />
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import GenericTooltip from '@/components/generic/GenericTooltip.vue'
import { previousValue, formatToCurrency } from '@/utils/index'
import { capitalize } from 'lodash'
// import setup from '@/mixins/setup'
import Profile from '@/components/Profile.vue'
import TopNav from '@/components/TopNav.vue'
import BottomNav from '@/components/BottomNav.vue'
import Table from '@/components/TableComponent.vue'
import Map from '@/components/maps/BaseMap.vue'
import TrendLineConfig from '@/config/trendline'
import chartOptions from '@/components/charts/BarChartOption'
import Loader from '@/modules/dashboard/components/Loader.vue'

export default {
  name: 'StateScoreCard',
  // mixins: [setup],
  data () {
    return {
      activeButton: 'button1',
      activeButton1: 'button4',
      activeButton2: 'button7',
      section: '',
      segmentTypeHTI: 'Public',
      segmentTypeHRH: 'Number',
      segmentTypeHF: 'Allocation',
      tab: '',
      initialProgramArea: '',
      noMapSection: true,
      dataAvailable: true,
      dataObject: [],
      chartStore: [],
      type: 'year',
      yearOptions: [],
      monthOptions: [],
      mapHeader: '',
      fields: [
        {
          key: 'Indicator',
          label: 'Occupation',
          sortable: false,
          thClass: 'bg-secondary text-white'
        },
        {
          key: 'Status',
          placeholder: 'Status',
          sortable: false,
          thClass: 'bg-secondary text-white'
        },
        {
          key: 'Trend',
          placeholder: 'Trend',
          sortable: false,
          thClass: 'bg-secondary text-white'
        },
        {
          key: 'Target',
          placeholder: 'Target',
          sortable: false,
          thClass: 'bg-secondary text-white'
        }
        // {
        //   key: 'Density',
        //   sortable: false,
        //   thClass: 'bg-secondary text-white'
        // },
        // {
        //   key: 'Percentage',
        //   sortable: false,
        //   thClass: 'bg-secondary text-white'
        // }
      ],
      level: 3,
      state: capitalize(this.$store.state.user.state),
      onTarget: this.dataArr,
      mapObject: {
        series: [
          {
            name: 'On target',
            color: '#28a745',
            allAreas: true,
            data: []
          },
          {
            name: 'Not on target',
            color: '#dc3545',
            allAreas: true,
            data: []
          },
          {
            name: 'No target',
            color: 'grey',
            allAreas: true,
            data: []
          },
          {
            name: 'No data',
            color: '#e7e7e7',
            allAreas: true,
            data: []
          }
        ]
      },
      trendLine: TrendLineConfig,
      Loading: true,
      chartOptions,
      triggerChart: false,
      yearParam: '',
      programObject: {},
      dataGroupArray: []
    }
  },
  components: {
    GenericTooltip,
    Profile,
    TopNav,
    Table,
    BottomNav,
    Loader,
    Map
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getScoreCardData', 'getDefault', 'getMonthOptions'])
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_UP_DETAILED_INDICES', 'SET_UP_DETAILED_INDICES_MONTH', 'SET_DATA_GROUP', 'SET_SECTION_SELECTED']),
    ...mapActions(['SET_NO_DATA']),
    changeField () {
      this.SET_SECTION_SELECTED(this.section)
      if (this.section === 'Human Resources for Health') {
        this.fields = [
          {
            key: 'Indicator',
            label: 'Occupation',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Value',
            placeholder: 'Status',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // }
        ]
      }

      if (this.section === 'Health Training Institutions') {
        this.fields = [
          {
            key: 'Indicator',
            label: 'Institution',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Value',
            placeholder: 'Status',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // },
          // {
          //   key: 'Target',
          //   placeholder: 'Target',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // }
        ]
      }

      if (this.section === 'Health Financing') {
        this.fields = [
          {
            key: 'Indicator',
            label: 'Indicator',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Value',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // },
          // {
          //   key: 'Target',
          //   placeholder: 'Target',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // }
        ]
      }

      if (this.section === 'Health Financing' && this.segmentTypeHF === 'Percentage') {
        this.fields = [
          {
            key: 'Indicator',
            label: 'Indicator',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Percentage (%)',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // },
          {
            key: 'Target',
            placeholder: 'Target',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
        ]
      }

      if (this.section === 'Access and Service Utilization') {
        this.fields = [
          {
            key: 'Indicator',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Value',
            placeholder: 'Status',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // },
          // {
          //   key: 'Target',
          //   placeholder: 'Target',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // }
        ]
      }

      if (this.section === 'Health Outcomes') {
        this.fields = [
          {
            key: 'Indicator',
            sortable: false,
            thClass: 'bg-secondary text-white'
          },
          {
            key: 'Status',
            label: 'Value',
            placeholder: 'Status',
            sortable: false,
            thClass: 'bg-secondary text-white'
          }
          // {
          //   key: 'Trend',
          //   placeholder: 'Trend',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // },
          // {
          //   key: 'Target',
          //   placeholder: 'Target',
          //   sortable: false,
          //   thClass: 'bg-secondary text-white'
          // }
        ]
      }
    },
    async changeSegmentHRH (val) {
      this.segmentTypeHRH = val
      await this.switchTab(this.tab)
    },
    async changeSegmentHTI (val) {
      this.segmentTypeHTI = val
      await this.switchTab(this.tab)
    },
    async changeSegmentHF (val) {
      this.segmentTypeHF = val
      await this.switchTab(this.tab)
    },
    getData () {
      // Get the Program areas
      let programArea = this.getProgramAreas()
      if (this.programArea !== 'all') {
        programArea = [this.programArea]
      }

      // Data for the Target column
      // this.getProgramAndIndicator(programArea).forEach((target) => {
      //   this.getIndicatorTarget(target);
      // });

      this.dataObject = []
      this.chartStore = []

      programArea.forEach((program) => {
        this.getProgramAreaSource(program)
        // Get Indicators
        let indicators = this.getProgramAndIndicator(program)
        if (this.indicator !== 'all') {
          indicators = [this.indicator]
        }

        // Set the map Header to the first value in the array
        // eslint-disable-next-line prefer-destructuring
        this.mapHeader = indicators[0]

        // Available Years
        this.dataAvailable = Number.isFinite(this.year)
        if (!this.dataAvailable) {
          return
        }

        indicators.forEach((indicator) => {
          const indicatorsAndSource = `${indicator} -> ${this.dataSource}`
          const index = this.yearlyData[0].indexOf(indicatorsAndSource)
          // Get the Max year for the trendline
          const availableYearsValue = this.getMaxYearForEachIndicator(
            indicator,
            this.dataSource
          )
          /**
           * ?? this.year !== minimun year ?
           * ?? Get the current year, previous year and 2years back :
           * ?? Get the current year and assign 0 to the previous and 2years back
           */
          const minYears = Math.min(...availableYearsValue)
          let passedYear = this.getYearlydataNumber(index, this.year - 2)
          let previousYear = this.getYearlydataNumber(index, this.year - 1)
          const currentYear = this.getYearlydataNumber(index, this.year)
          if (this.year === minYears) {
            previousYear = 0
            passedYear = 0
          }

          const object = {}

          // if (!this.dataObject.some((obj) => obj.program_area === program)) {
          //   object.program_area = program;
          // }
          object.program_area = program
          object.indicator = indicator
          object.target = this.getNationalTarget(indicator)
          this.stateYearData.forEach((data) => {
            if (data[0] === Number(this.year)) {
              const displayFactor = this.getDisplayFactor(indicator)
              if (data[index] !== '') {
                const increase = previousValue(
                  this.stateYearData,
                  this.stateName,
                  Number(this.year) - 1,
                  index
                ) < data[index]

                object.status = {
                  data:
                    displayFactor.type === 'currency'
                      ? formatToCurrency(data[index])
                      : Number.parseFloat(data[index]),
                  increase: !!increase,
                  displayFactor: displayFactor.sign
                }
              } else {
                object.status = {
                  data: null,
                  increase: false,
                  displayFactor: displayFactor.sign
                }
              }
            }
          })

          const trendline = { ...this.trendLine }
          trendline.series = [
            {
              name: this.year,
              data: [passedYear, previousYear, currentYear]
            }
          ]
          object.trend = trendline
          // caculation of density
          object.density.data = object.status.data * 100

          this.dataObject.push({ ...object })
          console.log(this.dataObject, 'dataObject')
        })
      })
      this.yearOptions = this.availableYears
      this.monthOptions = this.availableMonths
    },
    /**
     * This queries data based on the yearly lga map sheet using the following parameters
     * @param {*} programArea
     * @param {*} indicator
     * @param {*} year]
     */
    async getLgaMapData (program, indicator, year) {
      // Map data Area
      const onTarget = []
      const offTarget = []
      const noTarget = []
      const emptyValue = []

      // Get the list of Indicators
      const indicators = this.getProgramAndIndicator(program)
      // ? Get the Lga data using the first Indicator and the max year by default
      const indicatorParam = indicator === undefined || indicator === null
        ? indicators[0]
        : indicator
      // ** Assign the proper year
      const yearParam = year === undefined || year === -Infinity ? this.year : year
      // Get Lga Data data via indicator and Year
      const lgaData = this.getLgaByIndicator(indicatorParam, yearParam)
      // if parsed indicator exists
      if (lgaData.length >= 1) {
        //= =========== get the target for the indicator
        const indicatorTarget = this.getIndicatorTarget(indicatorParam)
        let indTarget
        if (
          indicatorTarget === null ||
          indicatorTarget === undefined ||
          indicatorTarget === '' ||
          indicatorTarget.length < 1
        ) {
          indTarget = 'emptyTarget'
        }
        // ** get lga map data
        await lgaData.forEach((data) => {
          if (
            data[1] >= indicatorTarget &&
            data[1] !== '' &&
            indTarget !== 'emptyTarget'
          ) {
            onTarget.push(data)
          } else if (
            data[1] < indicatorTarget &&
            data[1] !== '' &&
            indTarget !== 'emptyTarget'
          ) {
            offTarget.push(data)
          } else if (indTarget === 'emptyTarget') {
            noTarget.push(data)
          } else if (data[1] === '') {
            emptyValue.push(data)
          }
        })
        this.mapObject.series[0].data = onTarget
        this.mapObject.series[1].data = offTarget
        this.mapObject.series[2].data = noTarget
      } else {
        // if parsed indicator doesnt exists
        this.mapObject.series[0].allAreas = true
        this.mapObject.series[0].color = '#28a745'
        this.mapObject.series[0].data = []
        this.mapObject.series[1].data = []
        this.mapObject.series[2].data = []
      }
    },
    /**
     * This queries data based on the monthly lga map sheet using the following parameters
     * @param {*} programArea
     * @param {*} indicator
     * @param {*} year
     * @param {*} month
     */
    async getLgaMapData2 (program, indicator, year, month) {
      // Map data Area
      const onTarget = []
      const offTarget = []
      const emptyValue = []

      // Get the list of Indicators
      const indicators = this.getProgramAndIndicator(program)
      // Get the Lga data using the first Indicator and the max year by default
      const indicatorParam = indicator === undefined || indicator === null
        ? indicators[0]
        : indicator
      // Assign the proper year
      const yearParam = year === undefined || year === -Infinity ? this.year : year
      const monthParam = month === undefined || month === -Infinity ? this.month : month
      // Get Lga Data data via indicator and Year
      const lgaData = this.getLgaByIndicatorYearMonth(
        indicatorParam,
        yearParam,
        monthParam
      )
      // const target = this.getIndicatorTarget(indicatorParam);
      // if parsed indicator exists

      if (lgaData.length >= 1) {
        lgaData.forEach((data) => {
          if (data[1] >= 10 && data[1] !== '') {
            onTarget.push(data)
          } else if (data[1] < 10 && data[1] !== '') {
            offTarget.push(data)
          } else {
            emptyValue.push(data)
          }
        })
        this.mapObject.series[0].data = onTarget
        this.mapObject.series[1].data = offTarget
        this.mapObject.series[3].data = emptyValue
      } else {
        // if parsed indicator doesnt exists
        this.mapObject.series[0].allAreas = true
        this.mapObject.series[0].color = '#28a745'
        this.mapObject.series[0].data = []
        this.mapObject.series[1].data = []
        this.mapObject.series[2].data = []
        this.mapObject.series[3].data = []
      }
    },
    /**
     * This queries data based on the emitted programArea from the topNav component
     * @param {programArea}
     */
    async Switch (value) {
      this.programArea = value
      // const indicators = await this.getProgramAndIndicator(value)
      // if (this.checkMonthlyDataIsAvailable(indicators[0], 'NHMIS')) {
      //   this.type = 'yearmonth'
      //   this.noMapSection = false
      // } else {
      //   this.type = 'year'
      //   this.noMapSection = true
      // }
      // this.getData()
      // this.getLgaMapData(value, null, this.year)
    },
    /**
     * This queries data based on the emitted event from the table component
     * @param {e} event
     */
    async onClick (e) {
      // this.getData()
      // this.getLgaMapData(e.program_area, e.indicator, this.year)
      this.mapHeader = e.indicator
    },
    /**
     * This queries data based on the selected 'available' year
     * @param {selectedYear}
     */
    SelectYear (selectedYear) {
      // this.year = selectedYear
      // this.getData()
      // this.getLgaMapData(this.programArea, null, this.year)
    },
    /**
     * This queries data based on the selected 'available' month
     * @param {selectedYear}
     */
    SelectMonth (selectedMonth) {
      // this.month = selectedMonth
      // this.getData()
      // this.getLgaMapData2(
      //   this.programArea,
      //   this.mapHeader,
      //   this.year,
      //   this.month
      // )
    },
    async switchTab (value) {
      if (!value) {
        value = this.section
      } else {
        this.changeField()
      }
      this.section = value
      this.tab = value
      this.Loading = true
      let item = null
      if (this.section === 'Human Resources for Health') {
        item = this.getDefault.find((item) => item.programArea === this.section)
      } else if (this.tab === 'Health Training Institutions') {
        item = this.getDefault.find((item) => item.programArea === value)
      } else if (this.tab === 'Health Financing') {
        item = this.getDefault.find((item) => item.programArea === value)
      } else {
        item = this.getDefault.find((item) => item.programArea === value)
      }
      // item = this.getDefault.find((item) => item.programngArea === value)
      // checking if the program Area is Health Facility
      this.section = item.programArea
      if (item.programArea === 'Health Training Institutions') {
        const indicatorType = {
          Public: 'indicatorPublic',
          Private: 'indicatorPrivate',
          Admission: 'indicatorAdmission'
        }
        this.programObject = {
          dataSource: item.dataSource,
          indicator: item[indicatorType[this.segmentTypeHTI]],
          programArea: item.programArea
        }
      } else if (item.programArea === 'Human Resources for Health') {
        const indicatorType = {
          Number: 'indicatorNumber',
          Density: 'indicatorDensity',
          TargetWho: 'indicatorTargetWho'
        }
        this.programObject = {
          dataSource: item.dataSource,
          indicator: item[indicatorType[this.segmentTypeHRH]],
          programArea: item.program
        }
        console.log('prgram object', this.programObject)
      } else if (item.programArea === 'Health Financing') {
        const indicatorType = {
          Allocation: 'indicatorAllocation',
          Performance: 'indicatorPerformance'
        }
        this.programObject = {
          dataSource: item.dataSource,
          indicator: item[indicatorType[this.segmentTypeHF]],
          programArea: item.program
        }
      } else {
        this.programObject = item
      }
      this.dataObject = await this.SET_UP_DETAILED_INDICES({ object: this.programObject, yearValue: this.yearParam })
      this.type = this.dataObject[0].datasource[0] === 25 ? 'yearmonth' : 'year'
      this.mapHeader = this.dataObject[0]?.indicator
      this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
      this.Loading = false

      // saving the data group data
      if (item.programArea === 'Health Training Institutions') {
        console.log('trying')
        const indicatorType = {
          Public: 'indicatorPublic',
          Private: 'indicatorPrivate',
          Admission: 'indicatorAdmission'
        }
        this.dataGroupArray = []
        const segments = ['Public', 'Private', 'Admission']
        segments.forEach(async (segment) => {
          const programObject = {
            dataSource: item.dataSource,
            indicator: item[indicatorType[segment]],
            programArea: item.programArea
          }
          console.log('newProgramObj', programObject)
          const newDataGroup = await this.SET_UP_DETAILED_INDICES({ object: programObject, yearValue: this.yearParam })
          newDataGroup.type = segment
          this.dataGroupArray.push(newDataGroup)
          console.log('dataMainArray', this.dataGroupArray)
          await this.SET_DATA_GROUP(this.dataGroupArray)
        })
      }

      if (item.programArea === 'Human Resources for Health') {
        const indicatorType = {
          Number: 'indicatorNumber',
          Density: 'indicatorDensity',
          TargetWho: 'indicatorTargetWho'
        }
        this.dataGroupArray = []
        const segments = ['Number', 'Density', 'TargetWho']
        segments.forEach(async (segment) => {
          const programObject = {
            dataSource: item.dataSource,
            indicator: item[indicatorType[segment]],
            programArea: item.programArea
          }
          console.log('newProgramObj', programObject)
          const newDataGroup = await this.SET_UP_DETAILED_INDICES({ object: programObject, yearValue: this.yearParam })
          newDataGroup.type = segment
          this.dataGroupArray.push(newDataGroup)
          console.log('dataMainArray', this.dataGroupArray)
          await this.SET_DATA_GROUP(this.dataGroupArray)
        })
      }
    },
    async selectYear (value) {
      this.yearParam = value
      this.switchTab()
      // this.Loading = true
      // this.dataObject = await this.SET_UP_DETAILED_INDICES({ object: this.programObject, yearValue: this.yearParam })
      // this.mapHeader = this.dataObject[0]?.indicator
      // this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
      // this.Loading = false
    },
    async selectMonth (value) {
      this.loading = true
      if (this.year !== -Infinity) {
        this.monthParam = value
        const query = `${value} ${this.yearParam}`
        console.log('checks', this.programObject)
        // this.dataObject = await this.SET_UP_DETAILED_INDICES_MONTH({ object: this.programObject, yearValue: this.yearParam, monthValue: value })
        const { table, map } = await this.SET_UP_DETAILED_INDICES_MONTH({ object: this.programObject, yearValue: query })
        console.log('checks =>', table, map)
        // this.mapHeader = this.dataObject[0]?.indicator
        // this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
      }
      this.loading = false
    },
    toggle (button) {
      this.activeButton = button
    },
    toggle1 (button) {
      this.activeButton1 = button
    },
    toggle2 (button) {
      this.activeButton2 = button
    }
  },
  watch: {
    programObject (val) {
      console.log('programObject', val)
    },
    section (val) {
      this.changeField()
    }
  },
  async mounted () {
    this.section = 'Human Resources for Health'
    this.changeField()
    console.log('this.section', this.section)
    setTimeout(async () => {
      if (this.$route.query.program !== undefined) {
        this.Switch(this.$route.query.program)
        this.loading = false
      } else {
        // const item = this.getDefault.find((item) => item.programArea === this.section)
        // console.log(item.programArea, 'item.program')
        // if (item.programArea === 'Human Resources for Health') {
        //   const indicatorType = {
        //     Number: 'indicatorNumber',
        //     Density: 'indicatorDensity',
        //     TargetWho: 'indicatorTargetWho'
        //   }
        //   this.programObject = {
        //     dataSource: item.dataSource,
        //     indicator: item[indicatorType[this.segmentTypeHRH]],
        //     programArea: item.program
        //   }
        //   console.log('prgram object', this.programObject)
        // } else {
        //   this.programObject = await this.getDefault[0]
        // }
        // console.log('item', item)
        // if (this.programObject !== undefined) {
        //   this.dataObject = await this.SET_UP_DETAILED_INDICES({ object: this.programObject, year: '' })
        //   this.type = this.programObject.dataSource[0] === 25 ? 'yearmonth' : 'year'
        //   this.mapHeader = this.dataObject[3]?.indicator
        //   this.dataObject?.length === 0 ? await this.SET_NO_DATA(true) : await this.SET_NO_DATA(false)
        //   // this.getData()
        //   // this.getLgaMapData()
        //   console.log(this.dataObject, 'dataObject')
        //   this.Loading = false
        // }
      }

      this.mapObject.series[0].allAreas = true
      this.mapObject.series[0].color = '#28a745'
      this.mapObject.series[0].data = ['Akamkpa', 30]
    // this.mapObject.series[1].data = [['Akamkpa', 40]]
    // this.mapObject.series[2].data = [['Akamkpa', 70]]
    // this.mapObject.series[3].data = [['Akamkpa', 20]]
    }, 1000)
    this.switchTab()
  }
}
</script>

<style lang="scss" scoped>
.data_source_box {
  color: #fff;
  background-color: #2e5d5c;
  width: auto;
  margin: 1px 22px 10px 19px;
  float: left;
  padding: 0.3rem;
}
.data_source_box p {
  font-size: 0.8rem;
  margin-bottom: 1px;
  margin-top: -2px;
}
.hello {
  color: #7cb5ecbf;
}
.slide {
  transition: 0.5s all ease-in-out;
}
h6.active_map_header {
  border-bottom: 1px solid white;
  text-align: center;
  width: fit-content;
  margin-top: 0.25em;
}
div.chart_body {
  min-height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.segment-btn {
  font-size: 13px;
  border: none;
  margin: 2px;
}

.active {
  background-color: #28a745 !important;
  color: white;
}

.non-active {
  background-color: #4e7675;
  color: white;
}
</style>
