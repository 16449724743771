<template>
  <div class="sticky-top">
    <!-- <header class=""> -->
    <b-navbar toggleable="lg" sticky variant="primary" type="dark">
      <!-- <div class="container-fluid"> -->
      <b-navbar-brand href="#" class="text-uppercase text-white">
        <img
          rel="preload"
          src="@/assets/img/ngf_logo.png"
          class="d-inline-block align-top mx-2 header-img"
        />

        <span class="border-left h6 text-white d-md-inline-block pl-2 d-none"
          ><router-link to="/" class="text-white header-name">
            Governors'&ensp;Dashboard
          </router-link>
        </span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav pills class="ml-auto link-size">
          <!-- For Governor's -->
          <b-navbar-nav v-if="isUser">
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/dashboard/state_profile"
              >Overview</b-nav-item
            >
            <!-- <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/dashboard/key-health-indices"
              >Key&nbsp;Health&nbsp;Indices</b-nav-item
            > -->
            <b-nav-item
              active-class="active-link"
              :class="[
                this.$route.path ===
                  '/dashboard/detailed_Indices/compare_states' ||
                this.$route.path === '/dashboard/detailed_Indices/compare_zones'
                  ? 'active-link active'
                  : '',
              ]"
              class="mx-2"
              to="/dashboard/detailed_Indices/scorecard"
              >State&nbsp;Profile</b-nav-item
            >
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/dashboard/scorecard"
              >Scorecard</b-nav-item
            >
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/dashboard/flagship-projects"
              >Flagship&nbsp;Projects</b-nav-item
            >
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/dashboard/partner-mapping"
              >Partner&nbsp;Mapping</b-nav-item
            >
          </b-navbar-nav>
          <!-- For Admin User -->
          <b-navbar-nav v-if="isAdmin">
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/admin/approval"
              >Approve Entries</b-nav-item
            >
            <b-nav-item
              active-class="active-link"
              class="mx-2"
              to="/admin/update-details"
              >Update Governor Profile</b-nav-item
            >
          </b-navbar-nav>
          <!-- ------- -->
          <b-button
            v-if="!isUser && !isAdmin"
            @click="login()"
            class="m-2"
            variant="outline-light"
            type="button"
          >
            Login
          </b-button>
          <b-button
            v-else
            class="m-2"
            @click="Logout"
            variant="outline-light"
            type="button"
          >
            Logout
            <span
              v-if="Loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
          <!-- ------- -->
          <b-dropdown
            variant="link"
            dropleft
            class="m-2 text-success"
            id="dropdown_navbar"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical" class="text-white" /><span
                class="sr-only"
                >Dropdown</span
              >
            </template>
            <b-dropdown-item
              v-if="!isAdmin && !isUser"
              active-class="active-link"
              class="mx-2 text-success"
              @click="adminLogin()"
              >{{ isAdmin ? "Logout" : "Admin Login" }}
            </b-dropdown-item>
            <!-- v-if="!isAdmin" -->
            <b-dropdown-item
              v-if="isUser"
              active-class="active-link"
              class="mx-2 text-success"
              to="/dashboard/home"
              >Data Entry
            </b-dropdown-item>
            <b-dropdown-item
              active-class="active-link"
              class="mx-2 text-success"
              to="/about"
              >About
            </b-dropdown-item>
            <b-dropdown-item
              active-class="active-link"
              class="mx-2 text-success"
              to="/faq"
              >Help&#47;FAQ</b-dropdown-item
            >
            <b-dropdown-item
              active-class="active-link"
              class="mx-2 text-success"
              to="/update-log"
              >Update&nbsp;Log</b-dropdown-item
            >
          </b-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import auth from '@/services/authentication'

export default {
  name: 'TheHeader',
  data () {
    return {
      Loading: false
    }
  },
  computed: {
    isUser () {
      return this.$store.getters.isUser
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    ...mapActions(['UNSET_MODAL']),
    async Logout () {
      this.Loading = true
      try {
        this.$router.push('/')
        await auth.logoutRoutine()
        this.UNSET_MODAL()
      } catch (error) {
        console.log(error)
      }
      this.Loading = false
    },
    login () {
      if (this.$store.getters.isAdmin) {
        this.$router.push('/login')
      }
      this.$router.push('/login')
    },
    async adminLogin () {
      try {
        if (this.$store.getters.isAdmin === false) {
          this.$router.push('/admin/admin-login')
        } else {
          this.$router.push('/')
          await auth.adminLoginRoutine
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active-link {
  color: white !important;
  border-bottom: 2px solid #ffff;
}
.header-name {
  font-size: 0.9rem;

  &:hover {
    text-decoration: none;
    color: whitesmoke !important;
  }
}
.header-img {
  width: 40%;
}

.link-size {
  font-size: 0.9rem;
}
ul.dropdown-menu.show {
  li {
    a.dropdown-item {
      color: red;
    }
  }
}
</style>
