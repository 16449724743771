<template>
  <div>
    <StateProfileSectionCard :propdata="totalPopulation" />
    <div class="card bg-white shadow-sm"></div>
    <div id="printSection" class="container-fluid px-lg-4 px-2 printSection">
      <div class="row mt-3">
        <div class="col-md-6 mb-3 d-flex flex-fill">
          <el-skeleton
            style="min-width: 45vw"
            v-if="loading"
            :loading="loading"
            animated
          >
            <template slot="template">
              <el-skeleton-item style="min-width: 45vw; min-height: 20rem" />
            </template>
          </el-skeleton>
          <StateProfileCard
            v-else
            :data="demographicData"
            :indicator="'Demography'"
          />
        </div>
        <!-- ================================ -->
        <div class="col-md-6 mb-3 d-flex flex-fill">
          <el-skeleton
            style="min-width: 45vw"
            v-if="loading"
            :loading="loading"
            animated
          >
            <template slot="template">
              <el-skeleton-item style="min-width: 45vw; min-height: 20rem" />
            </template>
          </el-skeleton>
          <StateProfileCard
            v-else
            :data="HRData"
            :indicator="'Human Resource'"
          />
        </div>
        <!-- ================================ HEALTH TRAINING INSTITUTION  -->
        <div class="col-md-6 mb-3 d-flex flex-fill">
          <el-skeleton
            style="min-width: 45vw"
            v-if="loading"
            :loading="loading"
            animated
          >
            <template slot="template">
              <el-skeleton-item style="min-width: 45vw; min-height: 20rem" />
            </template>
          </el-skeleton>
          <StateProfileCard
            v-else
            :data="InstitutionData"
            :indicator="'Institution'"
          />
        </div>
        <!-- ================================ HEALTH FACILITY SECTION  -->
        <div class="col-md-6 mb-3 d-flex flex-fill">
          <el-skeleton
            style="min-width: 45vw"
            v-if="loading"
            :loading="loading"
            animated
          >
            <template slot="template">
              <el-skeleton-item style="min-width: 45vw; min-height: 20rem" />
            </template>
          </el-skeleton>
          <StateProfileCard
            v-else
            :data="facilitiesData"
            :indicator="'Facility'"
          />
        </div>
      </div>
    </div>
    <!-- Modal Section to share page url -->
    <b-modal id="share_modal" centered title="NGF Dashboard" hide-footer>
      <form action="">
        <input
          type="email"
          id="email"
          name="email"
          class="form-control mb-3"
          v-model="email"
          placeholder="Enter recipient's email"
        />
      </form>
      <hr />
      <div>
        <h6 class="mb-3">Share via social media</h6>
        <div class="d-flex flex-wrap w-100">
          <b-icon
            style="color: #007ab9"
            class="mx-3 icon"
            title="share via linkedIn"
            font-scale="2"
            icon="linkedin"
            @click="shareViaLinkedin"
          />
          <b-icon
            class="mx-3 icon"
            title="share via facebook"
            style="color: #3b5998"
            font-scale="2"
            icon="facebook"
            @click="shareFacebook"
          />
          <b-icon
            class="mx-3 icon"
            style="color: #55acee"
            title="share via twitter"
            font-scale="2"
            icon="twitter"
            @click="shareViaTwitter"
          />
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-primary btn-sm" @click="sharePage">
          Share&nbsp;Page&ensp;<b-icon
            icon="check2-circle"
            aria-hidden="true"
          />
        </button>
      </div>
    </b-modal>
    <!-- Footer Button Section -->
    <div class="mx-auto container-fluid">
      <div class="px-lg-5 mt-1">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-sm mr-2" @click="printPage">
            Print&ensp;<b-icon icon="printer" aria-hidden="true" />
          </button>
          <b-button class="btn btn-primary btn-sm mr-2" v-b-modal.share_modal
            >Share&ensp;<b-icon icon="share" aria-hidden="true"
          /></b-button>
          <router-link to="/dashboard/key-health-indices">
            <button class="btn-lg mr-4 btn-primary btn_contd">
              Continue&nbsp;<span>&#8594;</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <InfoModal />
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex'
import StateProfileSectionCard from '@/components/StateProfileSection.vue'
import StateProfileCard from '@/components/cards/StateProfileCard.vue'
import setup from '@/mixins/setup'
import InfoModal from '@/components/modal/StateInfoModal.vue'

export default {
  name: 'StateProfile',
  mixins: [setup],
  // props: {
  //   propdata: {
  //     type: [Array, Object],
  //     required: true
  //   }
  // },
  components: {
    StateProfileSectionCard,
    InfoModal,
    StateProfileCard
  },
  data () {
    return {
      shareURL: window.location.origin,
      shareText: 'Nigerian Governors Forum',
      email: '',
      message: '',
      totalPopulation: [],
      demographicData: [],
      InstitutionData: [],
      facilitiesData: [],
      HRData: [],
      options: {
        name: 'NGF State Profile',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: "Governors' Dashboard state profile" // override the window title
      },
      dataSection: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters('DASHBOARD_STORE', ['getStateProfile'])
  },
  methods: {
    ...mapActions('DASHBOARD_STORE', ['SET_UP_STATE_PROFILE']),
    async getDemographicsData () {
      const resp = await this.SET_UP_STATE_PROFILE('Demography')
      this.totalPopulation = resp[0]
      this.demographicData = { title: 'Demography', data: resp.slice(1) }
    },

    async getHTIData () {
      const resp = await this.SET_UP_STATE_PROFILE('Health Training Institutions')
      this.InstitutionData = { title: 'Health Training Institutions', data: resp }
    },

    async getHealthFacilityData () {
      const resp = await this.SET_UP_STATE_PROFILE('Health Facilities')
      this.facilitiesData = { title: 'Health Facilities', data: resp }
    },

    async getHRData () {
      const resp = await this.SET_UP_STATE_PROFILE('Human Resources for Health')
      this.HRData = { title: 'Human Resources for Health', data: resp }
    },

    shareFacebook () {
      const facebookURL = `https://www.facebook.com/sharer.php?u=${this.shareURL}`
      window.open(
        facebookURL,
        '_blank',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=640, height=480'
      )
    },
    shareViaTwitter () {
      const url = `https://twitter.com/share?text=${this.shareText}&url=${this.shareURL}&hashtags=fmohconnect`
      window.open(
        url,
        '_blank',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=640, height=480'
      )
    },
    shareViaLinkedin () {
      const url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.shareURL}&title=${this.shareText}&summary=Health%20Dashboards`
      window.open(
        url,
        '_blank',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=640, height=480'
      )
    },
    async printPage () {
      await this.$htmlToPaper('printSection', this.options)
    },
    async sharePage () {
      window.location = `mailto:${this.email}?subject=${this.shareText}&body=${this.shareURL}`
      this.email = ''
      this.$bvModal.hide('share_modal')
    }
  },
  async mounted () {
    this.loading = true
    await this.getDemographicsData()
    await this.getHRData()
    await this.getHTIData()
    await this.getHealthFacilityData()
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
div.printSection {
  // height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
// print media query
@media print {
  div.printSection {
    // min-height: 60vh;
  }
}
.icon {
  cursor: pointer;
}
.shimmer {
  min-height: 10rem;
}
</style>
